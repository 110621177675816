const isNaNString = (s: string) => isNaN(Number(s));

const isForwardEntry = ([key]: [string, unknown]) => isNaNString(key);

export const enumEntries = <T extends object>(enumType: T): [string, T[keyof T]][] =>
    Object.entries(enumType)
        .filter(isForwardEntry);

export const enumValues = <T extends object>(enumType: T): T[keyof T][] =>
    enumEntries(enumType)
        .map(entry => entry[1]);