import React from "react";

import type { OrchestratedDeibSurveyQuestionStep } from "@/types/activities/deib-survey";

import DeibSurveyLikertQuestion from "@components/deib-survey-question/DeibSurveyLikertQuestion";
import DeibSurveyMultipleChoiceQuestion from "@components/deib-survey-question/DeibSurveyMultipleChoiceQuestion";
import DeibSurveySliderQuestion from "@components/deib-survey-question/DeibSurveySliderQuestion";

import mapDeibSurveyValueToScore from "@core/activities/deib-survey/mapDeibSurveyValueToScore";
import DisplayType from "@core/enums/DeibSurveyQuestionDisplayType";

interface Props {
    step: OrchestratedDeibSurveyQuestionStep;
    onSliderQuestionAnswered: (value: number, score: number) => void;
    onLikertQuestionAnswered: (value: number, score: number) => void;
    onMultipleChoiceQuestionAnswered: (value: number, score: number) => void;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const DeibSurveyActivityQuestionStep = ({
    step,
    onSliderQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onAnimationCompleted,
    onIntroAnimationCompleted
}: Props) => {
    const { displayType, questionId, question, answer } = step;
    const handleOnSubmit = (callback: (value: number, score: number) => void) => (value: number) => {
        callback(value, mapDeibSurveyValueToScore(displayType, value));
    };

    switch (displayType) {
        case DisplayType.Slider:
            return (
                <DeibSurveySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={step.minLabel}
                    maxLabel={step.maxLabel}
                    onChange={handleOnSubmit(onSliderQuestionAnswered)}
                    initialValue={answer}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.LikertScale:
            return (
                <DeibSurveyLikertQuestion
                    key={questionId}
                    question={question}
                    labels={step.labels}
                    onChange={handleOnSubmit(onLikertQuestionAnswered)}
                    initialValue={answer}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.MultipleChoice:
            return (
                <DeibSurveyMultipleChoiceQuestion
                    key={questionId}
                    question={question}
                    labels={step.labels}
                    imageUrls={step.imageUrls}
                    onChange={handleOnSubmit(onMultipleChoiceQuestionAnswered)}
                    initialValue={answer}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

export default DeibSurveyActivityQuestionStep;