import React from "react";

import classNames from "classnames";

import "./signup-slide-explanation.scss";

interface Props {
    className?: string;
    title: React.ReactNode;
    description: React.ReactNode;
    children?: React.ReactNode;
}

const SignupSlideExplanation = ({
    className,
    title,
    description,
    children
}: Props) => {
    const classes = classNames("signup-slide-explanation", className);

    return (
        <div className={classes}>
            <div className="signup-slide-explanation__title">{title}</div>
            <div className="signup-slide-explanation__description">
                {description}
            </div>
            {children && (
                <div className="signup-slide-explanation__footer">
                    {children}
                </div>
            )}
        </div>
    );
};

export default SignupSlideExplanation;
