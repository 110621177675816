import OnboardingSurveyQuestionDisplayType from "@core/enums/OnboardingSurveyQuestionDisplayType";

import type { OnboardingQuestionID } from "./types";

const map: Record<OnboardingQuestionID, OnboardingSurveyQuestionDisplayType> = {
    "OSQ-1": OnboardingSurveyQuestionDisplayType.Slider, // On a scale from 0-10, how would you rate the interview process?
    "OSQ-2": OnboardingSurveyQuestionDisplayType.LikertScale, // I was provided with what I needed to feel prepared for my first day.
    "OSQ-3": OnboardingSurveyQuestionDisplayType.MultipleChoice, // I’ve received helpful information regarding our products/services, our organization’s history, and our culture.
    "OSQ-4": OnboardingSurveyQuestionDisplayType.LikertScale, // I felt welcomed when I started.
    "OSQ-5": OnboardingSurveyQuestionDisplayType.Slider, // I have all the equipment I need to do my work.
    "OSQ-6": OnboardingSurveyQuestionDisplayType.MultipleChoice, // Is the onboarding process what you expected it to be so far?
    "OSQ-7": OnboardingSurveyQuestionDisplayType.LikertScale, // The information provided during my onboarding has been at the right level for me.
    "OSQ-8": OnboardingSurveyQuestionDisplayType.MultipleChoice, // How would you describe your relationship with members of your team so far?
    "OSQ-9": OnboardingSurveyQuestionDisplayType.LikertScale, // I have received adequate help and support during my onboarding so far.
    "OSQ-10": OnboardingSurveyQuestionDisplayType.Slider, // On a scale from 0-10, how do you feel about our company culture?
    "OSQ-11": OnboardingSurveyQuestionDisplayType.MultipleChoice, // Considering your expectations when you took this job, how do you feel they’ve been met so far?
    "OSQ-12": OnboardingSurveyQuestionDisplayType.LikertScale, // My role so far matches the role description provided to me.
    "OSQ-13": OnboardingSurveyQuestionDisplayType.MultipleChoice, // So far, do you feel you made the right decision joining our organization?
    "OSQ-14": OnboardingSurveyQuestionDisplayType.LikertScale, // I feel like I belong at our organization.
    "OSQ-15": OnboardingSurveyQuestionDisplayType.MultipleChoice, // Do you understand the full extent of your compensation, including benefits and perks?
    "OSQ-16": OnboardingSurveyQuestionDisplayType.LikertScale, // I still feel like this is a great role for me.
    "OSQ-17": OnboardingSurveyQuestionDisplayType.MultipleChoice, // Can you see how your strengths will be put to use in your role and team?
    "OSQ-18": OnboardingSurveyQuestionDisplayType.LikertScale, // Based on my experience so far, I would recommend our organization as a great place to work.
    "OSQ-19": OnboardingSurveyQuestionDisplayType.MultipleChoice, // Are you confident that you’ve built a network of partners within our organization?
    "OSQ-20": OnboardingSurveyQuestionDisplayType.LikertScale // My onboarding experience has set me up for success.
};

const mapQuestionIdToDisplayType = (questionId: OnboardingQuestionID): OnboardingSurveyQuestionDisplayType => {
    if (Object.prototype.hasOwnProperty.call(map, questionId)) {
        return map[questionId];
    }

    throw new Error(`Question Id (${questionId}) not found in mapping`);
};

export default mapQuestionIdToDisplayType;