import type { GuidString } from "@/types/utilityTypes";

import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";
import type RecognitionCategory from "@core/enums/RecognitionCategory";
import type RecognitionCategoryImage from "@core/enums/RecognitionCategoryImage";
import type RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

export interface OfficevibePrompt {
    id: string;
    categoryId: RecognitionCategory;
    isDisabled: boolean;
}

export interface CustomPrompt extends OfficevibePrompt {
    prompt: string;
    categoryName: string;
    categoryImage: RecognitionCategoryImage;
    categoryTheme: RecognitionCategoryTheme;
    lastEditedBy: GuidString;
}

export type ActivePrompt = Omit<CustomPrompt, "lastEditedBy">;

export interface RecognitionActivityResponse {
    correlationId: GuidString;
    isOverdue: boolean;
    prompts: CustomPrompt[];
    officevibePrompts: OfficevibePrompt[];
}

export async function getActivityAsync(): Promise<RecognitionActivityResponse> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync("/api/v1/activities/recognition");
}

export interface UserIdentity {
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    jobTitle: string;
    userId: GuidString;
    imageUrl: string;
}

export interface UserIdentities {
    page: number;
    pageSize: number;
    totalResults: number;
    identities: UserIdentity[];
}

interface RecognitionActivityControllerSearchPeersRequest {
    query: string;
    excludedIds: string[];
}

export async function searchPeersAsync(query: string, excludedIds: string[] = []): Promise<UserIdentities> {
    const request: RecognitionActivityControllerSearchPeersRequest = {
        query,
        excludedIds
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync("/api/v1/activities/recognition/peers/search", request);
}

interface RecognitionActivityControllerSaveAnswerFromOfficevibeCategoryRequest {
    recipientId: GuidString;
    message: string | undefined | null;
}

export async function saveOfficevibePromptAnswerAsync(correlationId: GuidString, recipientId: GuidString, categoryId: RecognitionCategory, promptId: string, message: string | undefined | null): Promise<void> {
    const request: RecognitionActivityControllerSaveAnswerFromOfficevibeCategoryRequest = {
        recipientId,
        message
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/recognition/${correlationId}/officevibe-categories/${categoryId}/${promptId}`, request);
}

interface RecognitionActivityControllerSaveAnswerFromOfficevibeCategoryMultiRecipientRequest {
    recipientIds: GuidString[];
    message: string | undefined | null;
    isPublic?: boolean;
}

export async function saveMultipleRecipientsOfficevibePromptAnswerAsync(correlationId: GuidString, recipientIds: GuidString[], categoryId: RecognitionCategory, promptId: string, message: string | undefined | null, isPublic?: boolean): Promise<void> {
    const request: RecognitionActivityControllerSaveAnswerFromOfficevibeCategoryMultiRecipientRequest = {
        recipientIds,
        message,
        isPublic
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v2/activities/recognition/${correlationId}/officevibe-categories/${categoryId}/${promptId}`, request);
}

interface RecognitionActivityControllerSaveAnswerFromCustomCategoryRequest {
    recipientId: GuidString;
    message: string | undefined | null;
}

export async function saveCustomPromptAnswerAsync(correlationId: GuidString, recipientId: GuidString, categoryId: string, promptId: string, message: string | undefined | null): Promise<void> {
    const request: RecognitionActivityControllerSaveAnswerFromCustomCategoryRequest = {
        recipientId,
        message
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/recognition/${correlationId}/categories/${categoryId}/${promptId}`, request);
}

interface RecognitionActivityControllerSaveAnswerFromCustomCategoryMultiRecipientRequest {
    recipientIds: GuidString[];
    message: string | undefined | null;
    isPublic?: boolean;
}

export async function saveMultipleRecipientsCustomPromptAnswerAsync(correlationId: GuidString, recipientIds: GuidString[], categoryId: string, promptId: string, message: string | undefined | null, isPublic?: boolean): Promise<void> {
    const request: RecognitionActivityControllerSaveAnswerFromCustomCategoryMultiRecipientRequest = {
        recipientIds,
        message,
        isPublic
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v2/activities/recognition/${correlationId}/categories/${categoryId}/${promptId}`, request);
}

export async function skipAnswerAsync(correlationId: GuidString): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/recognition/${correlationId}/skip`);
}

export async function activityEndedAsync(correlationId: GuidString): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/recognition/${correlationId}/ended`);
}