import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";

import buildSmartQuestionActivity from "./buildSmartQuestionActivity";

const buildTestSmartQuestionActivity = (metricId: string, subMetricId: string, t: TFunction<"activities">) => {
    if (!subMetricId) {
        return null;
    }

    // This assertion is safe because we have already checked that subMetricId is not null
    const activity = buildSmartQuestionActivity(metricId, subMetricId, t)!;

    return {
        ...activity,
        activityType: ActivityType.TestSmartQuestion as const
    };
};

export default buildTestSmartQuestionActivity;