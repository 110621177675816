import React from "react";

import classNames from "classnames";

import PulseSurveyQuestionTitle from "./PulseSurveyQuestionTitle";

import "./base-pulse-survey-question.scss";

interface Props {
    className?: string;
    question: string;
    children: React.ReactNode;
}

const BasePulseSurveyQuestion = ({ className, question, children }: Props

) => {
    const classes = classNames(
        "base-pulse-survey-question",
        className
    );

    return (
        <div className={classes}>
            <PulseSurveyQuestionTitle className="base-pulse-survey-question__header">
                {question}
            </PulseSurveyQuestionTitle>
            <div className="base-pulse-survey-question__input">
                {children}
            </div>
        </div>
    );
};

export default BasePulseSurveyQuestion;