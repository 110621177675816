enum QuestionDisplayType {
    MultipleChoice = "MultipleChoice",
    Slider = "Slider",
    LikertScale = "LikertScale",
    TwoWaySlider = "TwoWaySlider",
    Stars = "Stars",
    OpinionScale = "OpinionScale",
    Text = "Text",
    Custom = "Custom"
}

export default QuestionDisplayType;