import React, { useImperativeHandle, useRef } from "react";

import classNames from "classnames";

import KeyCodes from "@core/enums/KeyCodes";

import "./slider-handle-wrapper.scss";

interface Props {
    className?: string;
    left: string;
    children: React.ReactNode;
    disabled?: boolean;
    onDragStart: (e: React.MouseEvent | React.TouchEvent) => void;
    onEnter: () => void;
    onIncrement: () => void;
    onDecrement: () => void;
}

const SliderHandleWrapper = React.forwardRef<Partial<HTMLDivElement>, Props>(
    (
        {
            className,
            left,
            children,
            disabled,
            onDragStart,
            onEnter,
            onIncrement,
            onDecrement
        },
        ref
    ) => {
        const handleRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => ({
            focus: () => handleRef.current?.focus(),
            blur: () => handleRef.current?.blur(),
            // rc-slider expected this method to be defined on the ref
            clickFocus: () => handleRef.current?.focus() // eslint-disable-line no-empty-function
        }));

        const handleOnDragEnd = () => {
            window.removeEventListener("mouseup", handleOnDragEnd);
        };

        const handleOnStartDrag = (e: React.MouseEvent | React.TouchEvent) => {
            window.addEventListener("mouseup", handleOnDragEnd);
            onDragStart(e);
        };

        const handleOnKeyDown: React.KeyboardEventHandler = e => {
            switch (e.keyCode) {
                case KeyCodes.Enter:
                    onEnter();
                    break;

                case KeyCodes.ArrowRight:
                case KeyCodes.ArrowUp:
                    onIncrement();
                    break;

                case KeyCodes.ArrowLeft:
                case KeyCodes.ArrowDown:
                    onDecrement();
                    break;
            }
        };

        const classes = classNames("slider-handle-wrapper", className);

        const styles = {
            left
        };

        return (
            // There is not a semantic element that fits the use case of this component
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                ref={handleRef}
                className={classes}
                onMouseDown={handleOnStartDrag}
                onMouseUp={handleOnDragEnd}
                onTouchStart={handleOnStartDrag}
                onTouchEnd={handleOnDragEnd}
                onKeyDown={handleOnKeyDown}
                style={styles}
                tabIndex={disabled ? undefined : 0}
            >
                <div className="slider-handle-wrapper__content">{children}</div>
            </div>
        );
    }
);

export default SliderHandleWrapper;
