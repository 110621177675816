import React from "react";

import classNames from "classnames";

import "./popover.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const Popover = React.forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => {
    const classes = classNames(
        "popover",
        className
    );

    return (
        <div ref={ref} className={classes}>
            {children}
        </div>
    );
});

export default Popover;