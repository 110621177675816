import type { AxiosResponse } from "axios";

export default class ApiException {
    errorCode: string | number;
    response: AxiosResponse<unknown>;

    constructor(errorCode: string | number, response: AxiosResponse<unknown>) {
        this.errorCode = errorCode;
        this.response = response;
    }
}