import React, { useRef, useState } from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import ThirdPartySlider from "rc-slider";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import sleep from "@core/utils/sleep";

import ClickToConfirmSliderHandle from "./components/ClickToConfirmSliderHandle";
import SliderHandle from "./components/SliderHandle";
import SliderHandleWrapper from "./components/SliderHandleWrapper";

import { ReactComponent as IconArrowLeft } from "@svg/icons/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "@svg/icons/icon-arrow-right.svg";

import "./two-way-slider-question.scss";

interface Props {
    className?: string;
    initialValue?: number | null;
    imageUrls: string[];
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onChange: (value: number) => void;
    onAnimationCompleted: () => void;
}

const TwoWaySliderQuestion = ({ className, initialValue, minLabel, maxLabel, imageUrls, onChange, onAnimationCompleted }: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    const [confirmed, setConfirmed] = useState(false);
    const [value, setValue] = useState(initialValue ?? 5);
    const [hasUserInteracted, setHasUserInteracted] = useState(initialValue !== null);
    const [isDragging, setIsDragging] = useState(false);
    const hasValueChangedSinceStart = useRef<boolean>();
    const isUserSelectingValue = !hasUserInteracted || (isDragging && hasValueChangedSinceStart.current);

    const handleOnConfirm = async(newValue: number) => {
        if (confirmed) {
            return;
        }

        setValue(newValue);
        setHasUserInteracted(true);
        setConfirmed(true);

        onChange(newValue);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleOnDragStart = () => {
        setIsDragging(true);
        hasValueChangedSinceStart.current = false;
    };

    const handleOnChange = (newValue: number) => {
        setValue(newValue);
        setHasUserInteracted(true);
        hasValueChangedSinceStart.current = true;
    };

    const handleOnIncrement = () => {
        handleOnChange(Math.min(value + 1, 10));
    };

    const handleOnDecrement = () => {
        handleOnChange(Math.max(value - 1, 0));
    };

    const handleOnDragEnd = () => {
        setIsDragging(false);
        setHasUserInteracted(true);
        setConfirmed(true);

        handleOnConfirm(value);
    };

    const getImageUrl = () => {
        if (value === null) {
            return imageUrls[2];
        }

        if (value < 2) {
            return imageUrls[0];
        }

        if (value < 4) {
            return imageUrls[1];
        }

        if (value < 6) {
            return imageUrls[2];
        }

        if (value < 8) {
            return imageUrls[3];
        }

        return imageUrls[4];
    };

    const classes = classNames(
        "two-way-slider-question",
        className, {
            "two-way-slider-question--interacted": hasUserInteracted,
            "two-way-slider-question--confirmed": confirmed
        }
    );

    return (
        <div className={classes}>
            <div className="two-way-slider-question__drag-label">
                {isWorkleapBrandEnabled
                    ? <ArrowLeftIcon className="two-way-slider-question__drag-arrow-left" size="md" />
                    : <IconArrowLeft className="two-way-slider-question__drag-arrow-left" />}
                {t("pulseSurveyFrame.drag-instruction")}
                {isWorkleapBrandEnabled
                    ? <ArrowRightIcon className="two-way-slider-question__drag-arrow-right" size="md" />
                    : <IconArrowRight className="two-way-slider-question__drag-arrow-right" />}
            </div>
            <div className="two-way-slider-question__slider">
                <button type="submit" className="two-way-slider-question__left-cap" onClick={() => handleOnConfirm(0)} />
                <div className="two-way-slider-question__track">
                    <ThirdPartySlider className="two-way-slider-question__input"
                        min={0}
                        max={10}
                        marks={{
                            0: {},
                            10: {}
                        }}
                        value={value}
                        handle={({ ref, ...props }) => (
                            <SliderHandleWrapper {...props}
                                className={classNames(
                                    props.className,
                                    "two-way-slider-question__handle-wrapper",
                                    {
                                        "two-way-slider-question__handle-wrapper--slider": isUserSelectingValue,
                                        "two-way-slider-question__handle-wrapper--slider-dragging": isDragging
                                    }
                                )}
                                ref={ref}
                                left={`${value * 10}%`}
                                onDragStart={handleOnDragStart}
                                onEnter={() => handleOnConfirm(value)}
                                onIncrement={handleOnIncrement}
                                onDecrement={handleOnDecrement}
                                disabled={confirmed}
                            >
                                {isUserSelectingValue
                                    ? <SliderHandle className="two-way-slider-question__handle" />
                                    : <ClickToConfirmSliderHandle className="two-way-slider-question__handle" confirmed={confirmed} />
                                }
                            </SliderHandleWrapper>
                        )}
                        startPoint={5}
                        onBeforeChange={handleOnDragStart}
                        onChange={handleOnChange}
                        onAfterChange={handleOnDragEnd}
                        disabled={confirmed}
                    />
                </div>
                <button type="submit" className="two-way-slider-question__right-cap" onClick={() => handleOnConfirm(10)} />
            </div>
            <div className="two-way-slider-question__labels">
                <div className="two-way-slider-question__min-label">
                    {minLabel}
                </div>
                <div className="two-way-slider-question__max-label">
                    {maxLabel}
                </div>
            </div>
            <img className="two-way-slider-question__image" src={getImageUrl()} aria-hidden="true" alt="" />
        </div>
    );
};

export default TwoWaySliderQuestion;