import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useQueryParams from "@hooks/useQueryParams";

import { useAuthenticationContext } from "@contexts/authentication/AuthenticationContext";
import { useFeaturesContext } from "@contexts/features/FeaturesContext";
import { useUserContext } from "@contexts/user/UserContext";

import LoadingPage from "@pages/loading/LoadingPage";

interface Props {
    children: React.ReactNode;
    withSignupRedirect?: boolean;
}

const AuthenticatedRoute = ({ children, withSignupRedirect }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useQueryParams();
    const authenticationContext = useAuthenticationContext();
    const { isLoading: isFeaturesLoading, initializeFeaturesAsync } = useFeaturesContext();
    const { isLoading: isUserContextLoading, context: userContext, initializeUserContextAsync } = useUserContext();

    if (!authenticationContext) {
        throw new Error("AuthenticatedRoute used outside of AuthenticationContextProvider");
    }
    const { isAuthenticating, isAuthenticated, initializeAsync } = authenticationContext;

    const shouldRedirectToSignup = withSignupRedirect && !isUserContextLoading && !userContext?.isSignupComplete;

    useEffect(() => {
        if (token) {
            initializeAsync(token);
        } else {
            window.location.href = `${window.env.MONOLITH_API_URL}/survey`;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isAuthenticated) {
            initializeFeaturesAsync();
            initializeUserContextAsync();
        }
    }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (shouldRedirectToSignup) {
            navigate({
                pathname: "/onboarding",
                search: location.search
            });
        }
    }, [shouldRedirectToSignup, navigate, location.search]);

    if (isAuthenticating || isFeaturesLoading || isUserContextLoading || shouldRedirectToSignup) {
        return <LoadingPage />;
    }

    return children;
};

export default AuthenticatedRoute;