import React from "react";

import LoadingContext from "./LoadingContext";

interface Props {
    children: React.ReactNode;
}

// Using a context allows use to "resume" the loading state
const LoadingContextProvider = ({ children }: Props) => {
    const [timer, setTimer] = React.useState(0);

    const onTick = () => {
        setTimer(t => t + 1);
    };

    return (
        <LoadingContext.Provider value={{ timer, onTick }}>
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingContextProvider;