import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useKeyboard, { Handled } from "@hooks/useKeyboard";

import LabeledFormField from "@components/form/LabeledFormField";
import ValidatedFormField from "@components/form/ValidatedFormField";
import Input from "@components/input/Input";
import PhoneInput from "@components/phone-input/PhoneInput";

import type { OnboardingControllerSaveMemberOnboardingRequest } from "@api/SignupApiClient";
import DeliveryChannel from "@core/enums/DeliveryChannel";
import KeyCode from "@core/enums/KeyCodes";
import type SupportedSurveyLanguage from "@core/enums/SupportedSurveyLanguage";
import * as ensure from "@core/utils/validation";

import PrimaryButton from "../../../components/button/PrimaryButton";
import DeliveryChannelSelect from "./DeliveryChannelSelect";
import SignupSlide from "./SignupSlide";
import SurveyLanguageSelect from "./SurveyLanguageSelect";

import "./signup-survey-settings-slide.scss";

interface Props {
    className?: string;
    deliveryChannel: DeliveryChannel;
    defaultDeliveryChannel: DeliveryChannel;
    workEmail: string;
    personalEmail: string | null;
    mobilePhone: string | null;
    surveyLanguage: SupportedSurveyLanguage;
    onSurveyLanguageChange: (surveyLanguage: SupportedSurveyLanguage) => void;
    slideIndex: number;
    totalSlides: number;
    onSubmit: (
        data: OnboardingControllerSaveMemberOnboardingRequest
    ) => Promise<void>;
    onPreviousSlideClick: () => void;
}

const SignupSurveySettingsSlide = ({
    className,
    deliveryChannel,
    defaultDeliveryChannel,
    surveyLanguage,
    onSurveyLanguageChange,
    workEmail,
    personalEmail,
    mobilePhone,
    onSubmit,
    slideIndex,
    totalSlides,
    onPreviousSlideClick
}: Props) => {
    const { t } = useTranslation("activities");
    const [internalDeliveryChannel, setInternalDeliveryChannel] = useState(deliveryChannel);
    const [internalPersonalEmail, setInternalPersonalEmail] = useState(
        personalEmail ?? ""
    );
    const [internalMobilePhone, setInternalMobilePhone] = useState(
        mobilePhone ?? ""
    );
    const [internalSurveyLanguage, setInternalSurveyLanguage] = useState(surveyLanguage);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const isDesklessUser = deliveryChannel === DeliveryChannel.Sms;

    const validations = {
        personalEmail:
            internalDeliveryChannel === DeliveryChannel.PersonalEmail
                ? ensure.all(
                    ensure.isNotNullOrEmpty(
                        internalPersonalEmail,
                        t("onboarding.personalEmailFieldRequiredError")
                    ),
                    ensure.isValidEmailFormat(
                        internalPersonalEmail,
                        t("onboarding.personalEmailFieldInvalidFormatError")
                    )
                )
                : ensure.success(),
        mobilePhone:
            internalDeliveryChannel === DeliveryChannel.Sms
                ? ensure.all(
                    ensure.isNotNullOrEmpty(
                        internalMobilePhone,
                        t("onboarding.mobilePhoneFieldRequiredError")
                    ),
                    ensure.isValidPhoneNumberFormat(
                        internalMobilePhone,
                        t("onboarding.mobilePhoneFieldInvalidFormatError")
                    )
                )
                : ensure.success()
    };

    const isValid = validations.personalEmail.isValid && validations.mobilePhone.isValid;

    const handleOnSubmit = async() => {
        if (isValid) {
            try {
                setShowValidationErrors(false);
                setIsSubmitting(true);
                await onSubmit({
                    surveyLanguage: internalSurveyLanguage,
                    ...(internalDeliveryChannel === DeliveryChannel.PersonalEmail
                        ? {
                            deliveryChannel: DeliveryChannel.PersonalEmail,
                            personalEmail: internalPersonalEmail,
                            mobilePhone
                        }
                        : internalDeliveryChannel === DeliveryChannel.Sms
                            ? {
                                deliveryChannel: DeliveryChannel.Sms,
                                mobilePhone: internalMobilePhone,
                                personalEmail
                            }
                            : {
                                deliveryChannel: internalDeliveryChannel,
                                personalEmail,
                                mobilePhone
                            })
                });
            } catch (ex) {
                setIsSubmitting(false);

                throw ex;
            }
        } else {
            setShowValidationErrors(true);
        }
    };

    useKeyboard({
        [KeyCode.Enter]: () => {
            handleOnSubmit();

            return Handled;
        }
    });

    const handleOnSurveyLanguageChange = (
        newSurveyLanguage: SupportedSurveyLanguage
    ) => {
        setInternalSurveyLanguage(newSurveyLanguage);
        onSurveyLanguageChange(newSurveyLanguage);
    };

    const renderWorkEmailField = () => {
        return (
            <div className="signup-survey-settings-slide__form-row">
                <LabeledFormField label={t("onboarding.workEmailFieldLabel")}>
                    <Input value={workEmail} disabled />
                </LabeledFormField>
            </div>
        );
    };

    const renderPersonalEmailField = () => {
        return (
            <div className="signup-survey-settings-slide__form-row">
                <LabeledFormField
                    label={t("onboarding.personalEmailFieldLabel")}
                >
                    <ValidatedFormField
                        message={validations.personalEmail.message}
                        isValid={validations.personalEmail.isValid}
                        enabled={showValidationErrors}
                    >
                        <Input
                            placeholder={t(
                                "onboarding.personalEmailFieldPlaceholder"
                            )}
                            value={internalPersonalEmail || ""}
                            onChange={e =>
                                setInternalPersonalEmail(e.target.value)
                            }
                            error={
                                showValidationErrors &&
                                !validations.personalEmail.isValid
                            }
                            autoFocus={!internalPersonalEmail}
                        />
                    </ValidatedFormField>
                </LabeledFormField>
            </div>
        );
    };

    const renderSmsField = () => {
        return (
            <div className="signup-survey-settings-slide__form-row">
                <LabeledFormField label={t("onboarding.mobilePhoneFieldLabel")}>
                    <ValidatedFormField
                        message={validations.mobilePhone.message}
                        isValid={validations.mobilePhone.isValid}
                        enabled={showValidationErrors}
                    >
                        <PhoneInput
                            value={internalMobilePhone}
                            onChange={mp => setInternalMobilePhone(mp)}
                            error={
                                showValidationErrors &&
                                !validations.mobilePhone.isValid
                            }
                            autoFocus={!internalMobilePhone}
                        />
                    </ValidatedFormField>
                </LabeledFormField>
            </div>
        );
    };

    const renderDeliveryChannelDetailsField = () => {
        switch (internalDeliveryChannel) {
            case DeliveryChannel.WorkEmail:
                return renderWorkEmailField();

            case DeliveryChannel.PersonalEmail:
                return renderPersonalEmailField();

            case DeliveryChannel.Sms:
                return isDesklessUser ? undefined : renderSmsField();

            case DeliveryChannel.Slack:
            case DeliveryChannel.MsTeams:
                return null;

            default:
                throw new Error(
                    `Delivery Channel (${internalDeliveryChannel}) not supported`
                );
        }
    };

    const classes = classNames("signup-survey-settings-slide", className);

    return (
        <SignupSlide className={classes}>
            <SignupSlide.TitleHeader
                className="signup-survey-settings-slide__header"
                title={t("onboarding.surveySettingsTitle")}
            />
            <SignupSlide.Form className="signup-survey-settings-slide__form">
                <div className="signup-survey-settings-slide__form-row">
                    <LabeledFormField
                        label={t("onboarding.surveyLanguageFieldLabel")}
                    >
                        <SurveyLanguageSelect
                            value={internalSurveyLanguage}
                            onChange={handleOnSurveyLanguageChange}
                        />
                    </LabeledFormField>
                </div>
                <div className="signup-survey-settings-slide__form-row">
                    <LabeledFormField
                        label={t("onboarding.deliveryChannelFieldLabel")}
                    >
                        <DeliveryChannelSelect
                            value={internalDeliveryChannel}
                            defaultDeliveryChannel={defaultDeliveryChannel}
                            onChange={dc => setInternalDeliveryChannel(dc)}
                        />
                    </LabeledFormField>
                </div>
                {renderDeliveryChannelDetailsField()}
            </SignupSlide.Form>
            <PrimaryButton
                className="signup-survey-settings-slide__cta"
                onClick={handleOnSubmit}
                loading={isSubmitting}
            >
                {t("onboarding.surveySettingsCta")}
            </PrimaryButton>
            <SignupSlide.Footer
                className="signup-survey-settings-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showPreviousButton
                onPreviousClick={onPreviousSlideClick}
            />
        </SignupSlide>
    );
};

export default SignupSurveySettingsSlide;
