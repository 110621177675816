import React, { Fragment } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "./recognition-banner.scss";

interface Props {
    className?: string;
}

const RecognitionBanner = ({ className }: Props) => {
    const { t } = useTranslation("activities");

    const mobileClasses = classNames(
        "recognition-banner",
        "recognition-banner--mobile",
        className
    );

    const desktopClasses = classNames(
        "recognition-banner",
        "recognition-banner--desktop",
        className
    );

    return (
        <Fragment>
            <div className={mobileClasses}>
                {t("banners.recognition-mobile")}
            </div>
            <div className={desktopClasses}>
                {t("banners.recognition")}
            </div>
        </Fragment>
    );
};

export default RecognitionBanner;