let loop: ReturnType<typeof setInterval> | null = null;
let isWorking = false;
const queue: (() => Promise<unknown>)[] = [];

async function doWork() {
    if (!isWorking && queue.length > 0) {
        isWorking = true;
        const task = queue.shift();

        try {
            await task?.();

            isWorking = false;
        } catch (ex) {
            isWorking = false;

            throw ex;
        }
    }
}

export function initialize() {
    loop = setInterval(doWork, 50);
}

export function terminate() {
    if (loop) {
        clearTimeout(loop);
    }
    loop = null;
    isWorking = false;
}

export default function queueTask<Result>(task: () => Promise<Result>) {
    queue.push(task);
}