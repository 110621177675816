import React from "react";

import classNames from "classnames";

import "./secondary-button.scss";

interface IconComponentProps {
    className?: string;
}

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    loading?: boolean;
    showOnlyIconOnMobile?: boolean;
    iconLeading?: React.DetailedReactHTMLElement<IconComponentProps, HTMLElement>;
    iconTrailing?: React.DetailedReactHTMLElement<IconComponentProps, HTMLElement>;
    tabIndex: number;
}

const SecondaryButton = ({
    className,
    children,
    iconLeading,
    iconTrailing,
    disabled = false,
    loading = false,
    showOnlyIconOnMobile = false,
    onClick,
    tabIndex,
    ...props
}: Props) => {
    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        if (onClick && !loading && !disabled) {
            onClick(e);
        }
    };

    const classes = classNames("secondary-button", className, {
        "secondary-button--loading": loading,
        "secondary-button--disabled": disabled,
        "secondary-button--mobile-icon-only": showOnlyIconOnMobile
    });

    return (
        <button
            type="button"
            className={classes}
            disabled={disabled}
            onClick={handleOnClick}
            tabIndex={tabIndex}
            {...props}
        >
            {iconLeading &&
                React.cloneElement(iconLeading, {
                    className: classNames(
                        "secondary-button__icon",
                        "secondary-button__icon--leading",
                        iconLeading.props.className
                    )
                })}
            <div className="secondary-button__text">{children}</div>
            {iconTrailing &&
                React.cloneElement(iconTrailing, {
                    className: classNames(
                        "secondary-button__icon",
                        "secondary-button__icon--trailing",
                        iconTrailing.props.className
                    )
                })}
            {loading && <div className="secondary-button__loader" />}
        </button>
    );
};

export default SecondaryButton;
