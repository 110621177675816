import type { TFunction } from "react-i18next";

import type { TryPulseSurveyActivityResponse } from "@api/TryPulseSurveyActivityApiClient";
import ActivityType from "@core/enums/ActivityType";

import buildPulseSurveyActivity from "./buildPulseSurveyActivity";
import type { PulseSurveyActivityCustomQuestionOptions } from "./types";

const buildTryPulseSurveyActivity = (
    activity: TryPulseSurveyActivityResponse,
    t: TFunction<"activities">
) => {
    if (activity.questionIds.length === 0) {
        return null;
    }

    const pulseSurveyActivityCustomQuestionOptions: PulseSurveyActivityCustomQuestionOptions =
        {
            isPulseSurveyCustomTextualQuestionsEnabled: false,
            insertCustomQuestionAfterFirst: false,
            customQuestion: undefined
        };

    const builtActivity = buildPulseSurveyActivity(
        activity,
        pulseSurveyActivityCustomQuestionOptions,
        t
    )!;

    return {
        ...builtActivity,
        activityType: ActivityType.TryPulseSurvey as const
    };
};

export default buildTryPulseSurveyActivity;
