import React from "react";

import classNames from "classnames";

import "./peer-select-placeholder.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const PeerSelectPlaceholder = ({ className, children }: Props) => {
    const classes = classNames(
        "peer-select-placeholder",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default PeerSelectPlaceholder;