import StepType from "./StepType";

type DeibSurveyStepType = StepType.Intro | StepType.AskQuestion;

const DeibSurveyStepType = {
    Intro: StepType.Intro,
    AskQuestion: StepType.AskQuestion
} as const;

export default DeibSurveyStepType;
