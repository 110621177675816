import React from "react";

import classNames from "classnames";

import useDelayedEffect from "@hooks/useDelayedEffect";

import TwoWaySliderQuestion from "@components/slider-question/TwoWaySliderQuestion";

import BasePulseSurveyQuestion from "./BasePulseSurveyQuestion";

import "./pulse-survey-two-way-slider-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    imageUrls: string[];
    onChange: (value: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyTwoWaySliderQuestion = ({
    className,
    question,
    initialValue,
    minLabel,
    maxLabel,
    imageUrls,
    onChange,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onIntroAnimationCompleted();
            }
        },
        [],
        1300
    );

    const classes = classNames(
        "pulse-survey-two-way-slider-question",
        className,
        {
            "pulse-survey-two-way-slider-question--animated-intro":
                isIntroAnimated
        }
    );

    return (
        <BasePulseSurveyQuestion className={classes} question={question}>
            <TwoWaySliderQuestion
                className="pulse-survey-two-way-slider-question__slider"
                initialValue={initialValue}
                imageUrls={imageUrls}
                minLabel={minLabel}
                maxLabel={maxLabel}
                onChange={onChange}
                onAnimationCompleted={onAnimationCompleted}
            />
        </BasePulseSurveyQuestion>
    );
};

export default PulseSurveyTwoWaySliderQuestion;
