import DisplayType from "@core/enums/PulseSurveyQuestionDisplayType";

const mapMultipleChoiceValueToScore = (value: number) => {
    switch (value) {
        case 0:
            return 0;
        case 1:
            return 3.3;
        case 2:
            return 6.7;
        case 3:
            return 10;
        default:
            throw new Error(`Invalid value (${value}) for multiple-choice question`);
    }
};

const mapTwoWaySliderValueToScore = (value: number) => {
    switch (value) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            return value;
        default:
            throw new Error(`Invalid value (${value}) for two way slider question`);
    }
};

const mapSliderValueToScore = (value: number) => {
    switch (value) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            return value;
        default:
            throw new Error(`Invalid value (${value}) for slider question`);
    }
};

const mapLikertValueToScore = (value: number) => {
    switch (value) {
        case 0:
            return 0;
        case 1:
            return 2.5;
        case 2:
            return 5;
        case 3:
            return 7.5;
        case 4:
            return 10;
        default:
            throw new Error(`Invalid value (${value}) for likert question`);
    }
};

const mapStarsValueToScore = (value: number) => {
    switch (value) {
        case 0:
            return 0;
        case 1:
            return 2.5;
        case 2:
            return 5;
        case 3:
            return 7.5;
        case 4:
            return 10;
        default:
            throw new Error(`Invalid value (${value}) for stars question`);
    }
};

const mapPulseSurveyValueToScore = (displayType: DisplayType, value: number) => {
    switch (displayType) {
        case DisplayType.Slider:
            return mapSliderValueToScore(value);
        case DisplayType.TwoWaySlider:
            return mapTwoWaySliderValueToScore(value);
        case DisplayType.LikertScale:
            return mapLikertValueToScore(value);
        case DisplayType.Stars:
            return mapStarsValueToScore(value);
        case DisplayType.MultipleChoice:
            return mapMultipleChoiceValueToScore(value);
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

export default mapPulseSurveyValueToScore;