import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

export interface SmartQuestionActivityResponse {
    subMetricId: string;
    metricId?: string;
}

export async function getActivityAsync(): Promise<SmartQuestionActivityResponse> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync("/api/v1/activities/smart-question");
}

interface SmartQuestionActivityControllerSaveFeedbackRequest {
    feedback: string;
    isAnonymous: boolean;
}

export interface SaveSmartQuestionFeedbackResult {
    id: string;
}

export async function saveFeedbackAsync(subMetricId: string, feedback: string, isAnonymous: boolean): Promise<SaveSmartQuestionFeedbackResult> {
    const request: SmartQuestionActivityControllerSaveFeedbackRequest = {
        feedback,
        isAnonymous
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/smart-question/${subMetricId}/feedback`, request);
}

export async function skipFeedbackAsync(subMetricId: string): Promise<SaveSmartQuestionFeedbackResult> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/smart-question/${subMetricId}/feedback/skip`);
}

export async function activityEndedAsync(subMetricId: string): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/smart-question/${subMetricId}/ended`);
}