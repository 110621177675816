import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import InlineButton from "@components/button/InlineButton";
import InteractionBox from "@components/interaction-box/InteractionBox";

import CustomPollQuestionAskedBy from "./CustomPollQuestionAskedBy";

import "./custom-poll-follow-up-question.scss";

interface Props {
    className?: string;
    followUpQuestion: string;
    isSentByCompany: boolean;
    sentByImageUrl?: string;
    isAnswered?: boolean;
    initialFeedback?: string | null;
    initialIsAnonymous?: boolean;
    onChangeAnonymity: (isAnonymous: boolean) => void;
    onSubmit: (feedback: string, isAnonymous: boolean) => void;
    onGoBack: () => void;
    onAnimationCompleted: () => void;
    onTrackFeedbackShown: () => void;
}

const CustomPollFollowUpQuestion = ({
    className,
    followUpQuestion,
    isSentByCompany,
    sentByImageUrl,
    isAnswered,
    initialFeedback,
    initialIsAnonymous,
    onChangeAnonymity,
    onSubmit,
    onGoBack,
    onAnimationCompleted,
    onTrackFeedbackShown
}: Props) => {
    const { t } = useTranslation("activities");
    const [confirmed, setConfirmed] = useState(false);
    const { imageUrl, fullName } = useAuthenticatedUser();
    const [isAnsweredSnapshot] = useState(isAnswered);

    useEffect(() => {
        onTrackFeedbackShown();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChangeAnonymity = (isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        onChangeAnonymity(isAnonymous);
    };

    const handleOnSubmit = (feedback: string, isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        setConfirmed(true);
        onSubmit(feedback, isAnonymous);
        onAnimationCompleted();
    };

    const classes = classNames("custom-poll-follow-up-question", className);

    return (
        <div className={classes}>
            <div className="custom-poll-follow-up-question__previous-step">
                <span className="custom-poll-follow-up-question__previous-step-text">
                    {t("pulseSurveyFollowUpQuestion.title")}
                </span>
                <InlineButton
                    className="custom-poll-follow-up-question__previous-step-button"
                    onClick={onGoBack}
                >
                    {t("pulseSurveyFollowUpQuestion.edit-answer-button-label")}
                </InlineButton>
            </div>
            <CustomPollQuestionAskedBy
                className="custom-poll-follow-up-question__question-asked-by"
                question={followUpQuestion}
                isSentByCompany={isSentByCompany}
                sentByImageUrl={sentByImageUrl}
            />
            <div className="custom-poll-follow-up-question__input">
                <InteractionBox
                    initialFeedback={initialFeedback}
                    initialIsAnonymous={initialIsAnonymous}
                    authorImageUrl={imageUrl}
                    authorFullName={fullName}
                    placeholder={t(
                        "customPollFrame.feedback-input-placeholder"
                    )}
                    submitLabel={
                        isAnsweredSnapshot
                            ? t("customPollFrame.update-feedback-button-label")
                            : t("customPollFrame.send-feedback-button-label")
                    }
                    onSubmit={handleOnSubmit}
                    onChangeIsAnonymous={handleOnChangeAnonymity}
                    disabled={confirmed}
                    allowEmptyFeedback={isAnsweredSnapshot}
                />
            </div>
        </div>
    );
};

export default CustomPollFollowUpQuestion;
