import React from "react";

import Toggle from "@igloo-ui/toggle";
import Tooltip from "@igloo-ui/tooltip";
import { useTranslation } from "react-i18next";

interface Props extends Omit<React.ComponentProps<typeof Toggle>, "htmlFor"> {
    isAnonymous: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    disabled: boolean;
    tooltip?: React.ReactNode;
}

const AnonymityToggle = ({ isAnonymous, onChange, disabled, tooltip, ...toggleProps }: Props) => {
    const { t } = useTranslation("activities");

    return (
        <Tooltip disabled={!tooltip} content={tooltip} position="top" className="anonymity-tooltip">
            <Toggle
                {...toggleProps}
                htmlFor="is-anonymous"
                checked={isAnonymous}
                disabled={disabled}
                onChange={onChange}
            >
                {t("pulseSurveyFollowUpQuestion.anonymous-toggle-label")}
            </Toggle>
        </Tooltip>
    );
};

export default AnonymityToggle;