import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

import tokenRemovalTelemetryInitializer from "./tokenRemovalTelemetryInitializer";

const browserHistory = createBrowserHistory();
const appInsightsReactPlugin = new ReactPlugin();

let appInsights: ApplicationInsights | null = null;

if (window.env.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: window.env.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
            extensions: [appInsightsReactPlugin],
            extensionConfig: {
                [appInsightsReactPlugin.identifier]: { history: browserHistory }
            }
        }
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(tokenRemovalTelemetryInitializer);
}

export { appInsights, appInsightsReactPlugin };

