import React from "react";

import classNames from "classnames";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import IllustratedBackground from "@components/activities/backgrounds/IllustratedBackground";

import ActivityBackgroundType from "@core/enums/ActivityBackgroundType";

import "./activities-background.scss";

interface Props {
    className?: string;
}

const ActivitiesBackground = ({ className }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );
    const { getBackgroundState } = useActivitiesContext();

    const backgroundState = getBackgroundState(isWorkleapBrandEnabled);

    const classes = classNames(
        "activities-background",
        `activities-background--${backgroundState.theme}`,
        className
    );

    return (
        <div className={classes}>
            {(() => {
                switch (backgroundState.type) {
                    case ActivityBackgroundType.None:
                        return null;
                    case ActivityBackgroundType.Illustrated:
                        return (
                            <IllustratedBackground
                                key={backgroundState.type}
                                className={backgroundState.className}
                            />
                        );
                    default:
                        throw new Error(
                            `Activity Background Type (${backgroundState.type}) not supported`
                        );
                }
            })()}
        </div>
    );
};

export default ActivitiesBackground;
