import React from "react";

import { EyeVisibleIcon } from "@hopper-ui/icons";
import classNames from "classnames";

import "./visibility-disclaimer.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const VisibilityDisclaimer = ({ className, children }: Props) => {
    const classes = classNames(
        "visibility-disclaimer",
        className
    );

    return (
        <div className={classes}>
            <EyeVisibleIcon className="visibility-disclaimer__icon" size="md" />
            <div className="visibility-disclaimer__label">
                {children}
            </div>
        </div>
    );
};

export default VisibilityDisclaimer;