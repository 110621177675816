// Order is important (first match will be returned)
// Start with most specific rules
const PAGE_PATHS: Record<string, (pathname: string) => boolean> = ({
    // /onboarding
    "Onboarding": pathname => /^\/onboarding$/i.test(pathname),

    // /survey/try
    "Try Survey Activity": pathname => /^\/survey\/try$/i.test(pathname),

    // /survey/continue
    "Continue Survey Flow": pathname => /^\/survey\/continue$/i.test(pathname),

    // /survey
    "Complete Survey Flow": pathname => /^\/survey$/i.test(pathname),

    // /polls/GUID/preview
    "Preview Custom Poll Activity": pathname => /^\/polls\/.*?\/preview$/i.test(pathname),

    // /polls/GUID
    "Custom Poll Activity": pathname => /^\/polls\/.*$/i.test(pathname),

    // /recognition
    "Recognition Activity": pathname => /^\/recognition$/i.test(pathname),

    // /test/survey/question/QUESTION-ID
    "Test Pulse Survey Question": pathname => /^\/test\/survey\/question\/.*$/i.test(pathname),

    // /test/smart-question/METRIC-ID/SUBMETRIC-ID
    "Test Smart Question": pathname => /^\/test\/smart-question\/.*$/i.test(pathname),

    // /test/recognition
    "Test Recognition": pathname => /^\/test\/recognition\/.*$/i.test(pathname)
}) as const;

export default PAGE_PATHS;