import type { GuidString } from "@/types/utilityTypes";

import type { OnboardingQuestionID } from "@core/activities/onboarding-survey/types";
import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

const getClient = () => buildApiClient(window.env.ACTIVITIES_API_URL);

export interface OnboardingSurveyActivityResponse {
    correlationId: GuidString;
    questionIds: OnboardingQuestionID[];
    questionIdsWithFollowUp: OnboardingQuestionID[];
}

export function getActivityAsync(): Promise<OnboardingSurveyActivityResponse> {
    return getClient().getAsync("/api/v1/activities/onboarding-survey");
}

interface OnboardingSurveyActivityControllerSaveAnswerRequest {
    score: number;
}

export function saveAnswerAsync(correlationId: GuidString, questionId: OnboardingQuestionID, score: number): Promise<void> {
    const request: OnboardingSurveyActivityControllerSaveAnswerRequest = {
        score
    };

    return getClient().postAsync(`/api/v1/activities/onboarding-survey/${correlationId}/${questionId}`, request);
}

export function skipAnswerAsync(correlationId: GuidString, questionId: OnboardingQuestionID): Promise<void> {
    return getClient().postAsync(`/api/v1/activities/onboarding-survey/${correlationId}/${questionId}/skip`);
}

interface OnboardingSurveyActivityControllerSaveFollowUpRequest {
    feedback: string;
}

export function saveFollowUpAnswerAsync(correlationId: GuidString, questionId: OnboardingQuestionID, feedback: string): Promise<void> {
    const request: OnboardingSurveyActivityControllerSaveFollowUpRequest = {
        feedback
    };

    return getClient().postAsync(`/api/v1/activities/onboarding-survey/${correlationId}/${questionId}/follow-up`, request);
}

export function deleteFollowUpAnswerAsync(correlationId: GuidString, questionId: OnboardingQuestionID): Promise<void> {
    return getClient().deleteAsync(`/api/v1/activities/onboarding-survey/${correlationId}/${questionId}/follow-up`);
}

export function skipFollowUpAnswerAsync(correlationId: GuidString, questionId: OnboardingQuestionID): Promise<void> {
    return getClient().postAsync(`/api/v1/activities/onboarding-survey/${correlationId}/${questionId}/follow-up/skip`);
}

export function activityEndedAsync(correlationId: GuidString): Promise<void> {
    return getClient().postAsync(`/api/v1/activities/onboarding-survey/${correlationId}/ended`);
}

export function getContinueActivityAndSaveAnswerAsync(questionId: OnboardingQuestionID, score: number): Promise<OnboardingSurveyActivityResponse> {
    const request: OnboardingSurveyActivityControllerSaveAnswerRequest = {
        score
    };

    return getClient().postAsync(`/api/v1/activities/onboarding-survey/continue/${questionId}`, request);
}