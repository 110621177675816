import React from "react";

import { AngleLeftIcon, AngleRightIcon } from "@hopper-ui/icons";
import classNames from "classnames";

import PrimaryIconButton from "@components/button/PrimaryIconButton";
import Stepper from "@components/stepper/Stepper";

import "./signup-slide-footer.scss";

interface Props {
    className?: string;
    showPreviousButton?: boolean;
    showNextButton?: boolean;
    currentStepIndex: number;
    totalSteps: number;
    onPreviousClick?: () => void;
    onNextClick?: () => void;
    isSubmitting?: boolean;
    nextButtonId?: string;
}

const SignupSlideFooter = ({
    className,
    currentStepIndex,
    showPreviousButton,
    showNextButton,
    totalSteps,
    onPreviousClick,
    onNextClick,
    isSubmitting,
    nextButtonId
}: Props) => {
    const classes = classNames("signup-slide-footer", className, {
        "signup-slide-footer--with-previous-button": showPreviousButton,
        "signup-slide-footer--with-next-button": showNextButton
    });

    return (
        <div className={classes}>
            <div className="signup-slide-footer__right-section">
                <PrimaryIconButton
                    className="signup-slide-footer__next-button"
                    icon={<AngleRightIcon size="md" />}
                    size={PrimaryIconButton.Size.Small}
                    onClick={onNextClick}
                    loading={isSubmitting}
                    id={nextButtonId}
                />
            </div>
            <div className="signup-slide-footer__middle-section">
                <Stepper
                    currentStepIndex={currentStepIndex}
                    totalSteps={totalSteps}
                />
            </div>
            <div className="signup-slide-footer__left-section">
                <PrimaryIconButton
                    className="signup-slide-footer__previous-button"
                    icon={<AngleLeftIcon size="md" />}
                    size={PrimaryIconButton.Size.Small}
                    onClick={onPreviousClick}
                    disabled={isSubmitting}
                />
            </div>
        </div>
    );
};

export default SignupSlideFooter;
