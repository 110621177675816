import { useState } from "react";

import useDelayedEffect from "@hooks/useDelayedEffect";

import { useUserContext } from "@contexts/user/UserContext";

import { startIntercom } from "./intercom.js";

const IntercomBootstrapper = () => {
    const { isLoading: isUserContextLoading, context: userContext } = useUserContext();
    const [isIntercomBootstrapped, setIsIntercomBootstrapped] = useState(false);

    useDelayedEffect(() => {
        if (!isIntercomBootstrapped && !isUserContextLoading && userContext) {
            setIsIntercomBootstrapped(true);

            const {
                userId,
                fullName,
                email,
                intercomIdentityToken,
                isNetworkCreator,
                isCompanyManager,
                isTeamManager,
                isNetworkAdmin,
                language
            } = userContext;

            const isMember = !isNetworkCreator && !isCompanyManager && !isTeamManager && !isNetworkAdmin;
            const otherProperties = { isNetworkCreator, isNetworkAdmin, isCompanyManager, isTeamManager, isMember, language };

            startIntercom(fullName, email, userId, intercomIdentityToken, otherProperties);
        }
    }, [isIntercomBootstrapped, isUserContextLoading, userContext], 1000);

    return null;
};

export default IntercomBootstrapper;