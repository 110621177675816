import React from "react";

export interface AuthenticationContextState {
    _initialized: boolean;
    isAuthenticating: boolean;
    isAuthenticated: boolean;
    initializeAsync: (surveyToken: string) => Promise<void>;
}

const AuthenticationContext = React.createContext<AuthenticationContextState | null>(null);

export default AuthenticationContext;

export const useAuthenticationContext = () => {
    const context = React.useContext(AuthenticationContext);

    if (!context) {
        throw new Error("AuthenticationContext not found; are you missing AuthenticationContextProvider?");
    }

    return context;
};