import React from "react";

import classNames from "classnames";

import "./validated-form-field.scss";

interface Props {
    className?: string;
    message: React.ReactNode;
    isValid: boolean;
    enabled?: boolean;
    children: React.ReactNode;
}

const ValidatedFormField = ({ className, children, message, isValid, enabled }: Props) => {
    const classes = classNames(
        "validated-form-field",
        className
    );

    return (
        <div className={classes}>
            {children}
            {(enabled && !isValid) && (
                <div className="validated-form-field__error-message">
                    {message}
                </div>
            )}
        </div>
    );
};

export default ValidatedFormField;