import type {
    BuiltCustomPollActivity,
    CustomPollFollowUpQuestionStep,
    CustomPollIntroStep,
    CustomPollQuestionStep
} from "@/types/activities/custom-polls";

import type { QuestionDetails } from "@api/CustomPollsActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import CustomPollQuestionDisplayType from "@core/enums/CustomPollQuestionDisplayType";
import CustomPollStepType from "@core/enums/CustomPollStepType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

import type { TestCustomPollDetails } from "./buildTestCustomPollActivity";
import EmptyCustomPollException from "./EmptyCustomPollException";

const buildCustomPollQuestion = <
    TCustomPollDetails extends TestCustomPollDetails = TestCustomPollDetails
>(
    customPoll: TCustomPollDetails,
    question: QuestionDetails
): CustomPollQuestionStep => {
    const commonData = {
        ...question,
        displayType: question.type,
        stepType: PulseSurveyStepType.AskQuestion,
        askFollowUpQuestion: !!question.followUpQuestion,
        isSentByCompany: customPoll.isSentByCompany,
        sentByName: customPoll.sentByName,
        sentByImageUrl: customPoll.sentByImageUrl
    };

    const { type } = question;

    switch (type) {
        case CustomPollQuestionDisplayType.OpinionScale:
            return {
                ...commonData,
                displayType: CustomPollQuestionDisplayType.OpinionScale,
                minLabel: question.minLabel,
                maxLabel: question.maxLabel,
                value: null
            };
        case CustomPollQuestionDisplayType.Text:
            return {
                ...commonData,
                displayType: CustomPollQuestionDisplayType.Text,
                feedback: null,
                isAnonymous: true
            };
        case CustomPollQuestionDisplayType.LikertScale:
            return {
                ...commonData,
                displayType: CustomPollQuestionDisplayType.LikertScale,
                labels: question.choices
            };
        case CustomPollQuestionDisplayType.MultipleChoice:
            return {
                ...commonData,
                displayType: CustomPollQuestionDisplayType.MultipleChoice,
                labels: question.choices,
                value: null,
                customAnswer: null,
                selectedChoices: null
            };
        default:
            throw new Error(`Display Type (${type}) not supported`);
    }
};

const buildCustomPollActivity = <
    TCustomPollDetails extends TestCustomPollDetails = TestCustomPollDetails
>(
    customPoll: TCustomPollDetails
): BuiltCustomPollActivity => {
    if (!customPoll) {
        throw new EmptyCustomPollException();
    }

    const questionSteps = customPoll.questions.map(q => {
        const questionStep: CustomPollQuestionStep = buildCustomPollQuestion(customPoll, q);

        if (questionStep.askFollowUpQuestion) {
            const askFollowUpQuestionStep: CustomPollFollowUpQuestionStep = {
                questionId: q.questionId,
                followUpQuestion: q.followUpQuestion!,
                stepType: CustomPollStepType.AskFollowUpQuestion,
                isSentByCompany: customPoll.isSentByCompany,
                sentByName: customPoll.sentByName,
                sentByImageUrl: customPoll.sentByImageUrl,
                feedback: null,
                isAnonymous: true
            };

            return [questionStep, askFollowUpQuestionStep];
        }

        return questionStep;
    });

    return {
        customPoll,
        activityType: ActivityType.CustomPoll as const,
        customPollId: customPoll.customPollId,
        steps: [
            {
                stepType: CustomPollStepType.Intro,
                title: customPoll.title,
                description: customPoll.description,
                isSentByCompany: customPoll.isSentByCompany,
                sentByName: customPoll.sentByName,
                sentByImageUrl: customPoll.sentByImageUrl,
                isSentByAuthorDeleted: !!customPoll.isSentByAuthorDeleted,
                totalQuestions: customPoll.totalQuestions
            } satisfies CustomPollIntroStep,
            ...questionSteps
        ].flat()
    };
};

export default buildCustomPollActivity;
