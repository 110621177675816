import type { StringCustomizationToken } from "@/types/StringCustomizationToken";
import type { GuidString } from "@/types/utilityTypes";

import type { CustomQuestionResult, PulseSurveyQuestionId } from "@core/activities/pulse-survey/types";
import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

export interface PulseSurveyActivityResponse {
    correlationId: GuidString;
    isPostSignupSurveyComplete: boolean;
    questionIds: PulseSurveyQuestionId[];
    microCustomizations: StringCustomizationToken[];
    askFollowUpForQuestionId?: PulseSurveyQuestionId;
    customQuestion?: CustomQuestionResult;
}

export async function getActivityAsync(): Promise<PulseSurveyActivityResponse> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync("/api/v1/activities/pulse-survey");
}

interface PulseSurveyActivityControllerSaveAnswerRequest {
    score: number;
}

export async function getContinueActivityAndSaveAnswerAsync(questionId: PulseSurveyQuestionId, score: number): Promise<PulseSurveyActivityResponse> {
    const request: PulseSurveyActivityControllerSaveAnswerRequest = {
        score
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/pulse-survey/continue/${questionId}`, request);
}

export async function saveAnswerAsync(correlationId: GuidString, questionId: PulseSurveyQuestionId, score: number): Promise<void> {
    const request: PulseSurveyActivityControllerSaveAnswerRequest = {
        score
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/pulse-survey/${correlationId}/${questionId}`, request);
}

export async function skipAnswerAsync(correlationId: GuidString, questionId: PulseSurveyQuestionId): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/pulse-survey/${correlationId}/${questionId}/skip`);
}

interface PulseSurveyActivityControllerSaveFollowUpRequest {
    isConstructive: boolean;
    feedback: string;
    isAnonymous: boolean;
}

export async function saveFollowUpAnswerAsync(correlationId: GuidString, questionId: PulseSurveyQuestionId, isConstructive: boolean, feedback: string, isAnonymous: boolean): Promise<void> {
    const request: PulseSurveyActivityControllerSaveFollowUpRequest = {
        isConstructive,
        feedback,
        isAnonymous
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/pulse-survey/${correlationId}/${questionId}/follow-up`, request);
}

export async function deleteFollowUpAnswerAsync(correlationId: GuidString, questionId: PulseSurveyQuestionId): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).deleteAsync(`/api/v1/activities/pulse-survey/${correlationId}/${questionId}/follow-up`);
}

export async function skipFollowUpAnswerAsync(correlationId: GuidString, questionId: PulseSurveyQuestionId): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/pulse-survey/${correlationId}/${questionId}/follow-up/skip`);
}

export async function activityEndedAsync(correlationId: GuidString): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/pulse-survey/${correlationId}/ended`);
}