import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as Check } from "@svg/icons/icon-check.svg";

import "./click-to-confirm-slider-handle.scss";

interface Props {
    className?: string;
    confirmed: boolean;
}

const ClickToConfirmSliderHandle = ({ className, confirmed }: Props) => {
    const { t } = useTranslation("activities");

    const classes = classNames(
        "click-to-confirm-slider-handle",
        className
    );

    return (
        <div className={classes}>
            <Check className="click-to-confirm-slider-handle__icon" />
            <div className="click-to-confirm-slider-handle__labels">
                {confirmed && (
                    <div className="click-to-confirm-slider-handle__confirmed-label">
                        {t("pulseSurveyFrame.question-confirmed-label")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClickToConfirmSliderHandle;