import React, { useEffect } from "react";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import ActivitiesPage from "./components/ActivitiesPage";

const TryPulseSurveyPage = () => {
    const { initializeTryPulseSurveyAsync } = useActivitiesContext();

    useEffect(() => {
        initializeTryPulseSurveyAsync();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default TryPulseSurveyPage;