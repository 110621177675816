import StepType from "./StepType";

type PulseSurveyStepType =
    | StepType.Intro
    | StepType.AskQuestion
    | StepType.AskFollowUpQuestion
    | StepType.AskCustomQuestion;

const PulseSurveyStepType = {
    Intro: StepType.Intro,
    AskQuestion: StepType.AskQuestion,
    AskFollowUpQuestion: StepType.AskFollowUpQuestion,
    AskCustomQuestion: StepType.AskCustomQuestion
} as const;

export default PulseSurveyStepType;
