
import ActivityType from "@core/enums/ActivityType";

import buildCustomPollActivity from "./buildCustomPollActivity";
import type { TestCustomPollDetails } from "./buildTestCustomPollActivity";

const buildPreviewCustomPollActivity = (customPoll: TestCustomPollDetails) => {
    const activity = buildCustomPollActivity(customPoll);

    return {
        ...activity,
        activityType: ActivityType.PreviewCustomPoll as const
    };
};

export default buildPreviewCustomPollActivity;