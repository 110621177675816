import React, { useRef, useState } from "react";

import { CheckmarkIcon, DismissIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Input from "@components/input/Input";

import KeyCodes from "@core/enums/KeyCodes";

import "./custom-choice-input.scss";

interface Props {
    answer?: string | null;
    onClearInput: () => void;
    onConfirm: (answer: string) => void;
}

const CustomChoiceInput = ({ answer, onClearInput, onConfirm }: Props) => {
    const { t } = useTranslation("activities");

    const textInputRef = useRef<HTMLInputElement>(null);
    const [customAnswer, setCustomAnswer] = useState(answer ?? "");

    const handleOnClearInput: React.MouseEventHandler = e => {
        e.preventDefault();

        setCustomAnswer("");
        onClearInput();
    };

    const handleSubmitAnswer: React.MouseEventHandler = e => {
        e.preventDefault();

        if (customAnswer) {
            onConfirm(customAnswer);
        }
    };

    const handleKeyDown: React.KeyboardEventHandler = e => {
        if (e.keyCode === KeyCodes.Enter && !!customAnswer) {
            onConfirm(customAnswer);
        } else if (e.keyCode === KeyCodes.Escape) {
            onClearInput();
        }
    };

    return (
        <div className="custom-choice-input__text-input-container">
            <Input
                ref={textInputRef}
                className="custom-choice-input__text-input"
                autoFocus
                maxLength={70}
                placeholder={t(
                    "customPollFrame.custom-poll-custom-answer-input-placeholder"
                )}
                value={customAnswer}
                onChange={e => setCustomAnswer(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <button
                type="reset"
                className="custom-choice-input__cancel-button"
                onClick={handleOnClearInput}
            >
                <DismissIcon className="custom-choice-input__cancel-icon" />
            </button>
            <button
                type="button"
                className={classNames("custom-choice-input__submit-button", {
                    "custom-choice-input__submit-button--active": !!customAnswer
                })}
                onClick={handleSubmitAnswer}
            >
                <CheckmarkIcon className={classNames("custom-choice-input__submit-icon", {
                    "custom-choice-input__submit-icon--active": !!customAnswer
                })}
                />
            </button>
        </div>
    );
};

export default CustomChoiceInput;
