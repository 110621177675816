import type { OnboardingSurveyQuestionStep } from "@/types/activities/onboarding-survey";
import type { TFunction } from "react-i18next";

import DisplayType from "@core/enums/OnboardingSurveyQuestionDisplayType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";

import mapQuestionIdToDisplayType from "./mapQuestionIdToDisplayType";
import type { OnboardingQuestionID } from "./types";

const buildOnboardingSurveyQuestionStep = (questionId: OnboardingQuestionID, t: TFunction<"activities">): OnboardingSurveyQuestionStep => {
    const utils = {
        getQuestion: (id: OnboardingQuestionID) => t(`onboardingQuestions.${id.toLowerCase()}`),
        getMinLabel: (id: OnboardingQuestionID) => t(`onboardingQuestions.${id.toLowerCase()}-min-label`),
        getMaxLabel: (id: OnboardingQuestionID) => t(`onboardingQuestions.${id.toLowerCase()}-max-label`),
        getFollowUpQuestion: (id: OnboardingQuestionID) => t(`onboardingQuestions.${id.toLowerCase()}-follow-up`),
        getImageUrls: (id: OnboardingQuestionID, indexes: number[]) => indexes.map(index => `${window.env.CDN_URL}/activities/onboarding-survey/images/${id}-${index}.webp`),
        getLabels: (id: OnboardingQuestionID, indexes: number[]) => indexes.map(index => t(`onboardingQuestions.${id.toLowerCase()}-choice-label-${index}`)),
        getLikertLabels: () => [
            t("onboardingQuestions.likert-scale-strongly-disagree"),
            t("onboardingQuestions.likert-scale-disagree"),
            t("onboardingQuestions.likert-scale-neutral"),
            t("onboardingQuestions.likert-scale-agree"),
            t("onboardingQuestions.likert-scale-strongly-agree")
        ]
    };

    const displayType = mapQuestionIdToDisplayType(questionId);

    const commonData = {
        questionId,
        question: utils.getQuestion(questionId),
        followUpQuestion: utils.getFollowUpQuestion(questionId),
        displayType,
        stepType: OnboardingSurveyStepType.AskQuestion
    };

    switch (displayType) {
        case DisplayType.Slider:
            return {
                ...commonData,
                displayType: DisplayType.Slider,
                minLabel: utils.getMinLabel(questionId),
                maxLabel: utils.getMaxLabel(questionId)
            } ;
        case DisplayType.LikertScale:
            return {
                ...commonData,
                displayType: DisplayType.LikertScale,
                labels: utils.getLikertLabels()
            };
        case DisplayType.MultipleChoice:
            return {
                ...commonData,
                displayType: DisplayType.MultipleChoice,
                labels: utils.getLabels(questionId, [1, 2, 3, 4]),
                imageUrls: utils.getImageUrls(questionId, [1, 2, 3, 4]),
                customAnswer: null
            };
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

export default buildOnboardingSurveyQuestionStep;