import React, { useEffect } from "react";

import type { DeibSurveyActivityType, OrchestratedDeibSurveyQuestionStep, OrchestratedDeibSurveyStep } from "@/types/activities/deib-survey";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import type { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import DeibSurveyStepType from "@core/enums/DeibSurveyStepType";
import { trackActivityCompleted, trackActivityStarted, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

import DeibIntro from "../deib-survey-question/DeibIntro";
import DeibSurveyActivityQuestionStep from "./DeibSurveyActivityQuestionStep";

interface Props {
    className?: string;
    activityType: DeibSurveyActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    correlationId: string;
    steps: OrchestratedDeibSurveyStep[];
    currentStepIndex: number;
    onSliderQuestionAnswered: ReturnType<typeof useActivitiesContext>["onDeibSurveyQuestionAnswered"];
    onLikertQuestionAnswered: ReturnType<typeof useActivitiesContext>["onDeibSurveyQuestionAnswered"];
    onMultipleChoiceQuestionAnswered: ReturnType<typeof useActivitiesContext>["onDeibSurveyQuestionAnswered"];
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (correlationId: string) => void;
    onIntroAnimationCompleted: () => void;
}

const DeibSurveyActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    correlationId,
    steps,
    currentStepIndex,
    onSliderQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();
    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isQuestionStep = (step: OrchestratedDeibSurveyStep): step is OrchestratedDeibSurveyQuestionStep => "questionId" in step;

    const handleOnQuestionAnswered = (callback: (correlationId: string, questionId: string, answer: number, score: number) => void) => (answer: number, score: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, currentStep.questionId);

        callback(correlationId, currentStep.questionId, answer, score);
    };

    const handleOnAnimationCompleted = () => {
        if (currentStep.isLastStep) {
            trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
            onActivityEnded(correlationId);

            if (isLastActivity) {
                onGoToOutro();
            } else {
                onGoToNextActivity();
            }
        } else {
            onGoToNextStep();
        }
    };

    const renderIntroStep = () => {
        return (
            <DeibIntro onGoToNextStep={onGoToNextStep} />
        );
    };

    const renderAskQuestionStep = (step: OrchestratedDeibSurveyQuestionStep) =>
        (
            <DeibSurveyActivityQuestionStep
                step={step}
                onSliderQuestionAnswered={handleOnQuestionAnswered(onSliderQuestionAnswered)}
                onLikertQuestionAnswered={handleOnQuestionAnswered(onLikertQuestionAnswered)}
                onMultipleChoiceQuestionAnswered={handleOnQuestionAnswered(onMultipleChoiceQuestionAnswered)}
                onAnimationCompleted={handleOnAnimationCompleted}
                onIntroAnimationCompleted={onIntroAnimationCompleted}
            />
        );

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            case DeibSurveyStepType.Intro:
                return renderIntroStep();
            case DeibSurveyStepType.AskQuestion:
                return renderAskQuestionStep(currentStep);
            default:
                throw new Error(`Deib Survey Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

export default DeibSurveyActivity;