import React, { useRef } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useDelayedEffect from "@hooks/useDelayedEffect";

import "./continue-pulse-survey-intro.scss";

const differentIntroTextsCount = 6;

interface Props {
    className?: string;
    onDisplayTimeoutEnd: () => void;
    isIntroAnimated: boolean;
}

const ContinuePulseSurveyIntro = ({ className, onDisplayTimeoutEnd, isIntroAnimated }: Props) => {
    const { t } = useTranslation("activities");
    const randomIndexForIntroTextRef = useRef(Math.floor(Math.random() * differentIntroTextsCount) + 1);
    const randomIndexForIntroText = randomIndexForIntroTextRef.current;

    useDelayedEffect(() => {
        if (isIntroAnimated) {
            onDisplayTimeoutEnd();
        }
    }, [], 1800);

    const classes = classNames(
        "continue-pulse-survey-intro",
        className, {
            "continue-pulse-survey-intro--animated-intro": isIntroAnimated
        }
    );

    return (
        <div className={classes}>
            <div className="continue-pulse-survey-intro__title">
                {t(`pulseSurveyFrame.continue-pulse-survey-intro-title_${randomIndexForIntroText}`)}
            </div>
            <div className="continue-pulse-survey-intro__subtitle">
                {t(`pulseSurveyFrame.continue-pulse-survey-intro-subtitle_${randomIndexForIntroText}`)}
            </div>
        </div>
    );
};

export default ContinuePulseSurveyIntro;