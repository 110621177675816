import IGNORED_PAGE_PATHS from "./IgnoredPagePaths";
import PAGE_PATHS from "./PagePaths";

function shouldIgnorePageView(pathname: string) {
    for (const path of IGNORED_PAGE_PATHS) {
        if (path(pathname)) {
            return true;
        }
    }

    return false;
}

function outputWarningOnUnknownPageName(pathname: string) {
    if (window.env.SHOW_DETAILED_ERRORS && !shouldIgnorePageView(pathname)) {
        console.error(`The current path '${pathname}' has an invalid pageName. Please add a page name in the appropriate PagePaths. If this path comes from a redirect, add it to IgnoredPagePaths`);
    }
}

function resolve(pathname: string) {
    const getPageName = () => {
        for (const pageName in PAGE_PATHS) {
            if (PAGE_PATHS[pageName](pathname)) {
                return pageName;
            }
        }

        return "Unknown";
    };

    const pageName = getPageName();

    if (pageName === "Unknown") {
        outputWarningOnUnknownPageName(pathname);
    }

    return pageName;
}

export default {
    resolve,
    shouldIgnorePageView
};