import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";
import useKeyboard, { Handled } from "@hooks/useKeyboard";

import KeyCode from "@core/enums/KeyCodes";

import SignupSlide from "./SignupSlide";

import { ReactComponent as IllustrationWorkleapBrand } from "@svg/illustrations/illustration-survey-answers-explanation.svg";
import { ReactComponent as IllustrationOVBrand } from "@svg/illustrations/legacy/illustration-survey-answers-explanation.svg";

import "./signup-pulse-survey-answers-explanation-slide.scss";

interface Props {
    className?: string;
    slideIndex: number;
    totalSlides: number;
    onPreviousSlideClick: () => void;
    onNextSlideClick: () => void;
}

const SignupPulseSurveyAnswersExplanationSlide = ({
    className,
    slideIndex,
    totalSlides,
    onPreviousSlideClick,
    onNextSlideClick
}: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );

    useKeyboard({
        [KeyCode.Enter]: () => {
            onNextSlideClick();

            return Handled;
        }
    });

    const classes = classNames(
        "signup-pulse-survey-answers-explanation-slide",
        className
    );

    return (
        <SignupSlide className={classes}>
            <SignupSlide.ImageHeader
                className="signup-pulse-survey-answers-explanation-slide__header"
                image={
                    isWorkleapBrandEnabled ? (
                        <IllustrationWorkleapBrand />
                    ) : (
                        <IllustrationOVBrand />
                    )
                }
                theme={SignupSlide.ImageHeader.Theme.ElectricBlue}
            />
            <SignupSlide.Explanation
                className="signup-pulse-survey-answers-explanation-slide__body"
                title={t("onboarding.pulseSurveyAnswersSlideTitle")}
                description={t("onboarding.pulseSurveyAnswersSlideDescription")}
            />
            <SignupSlide.Footer
                className="signup-pulse-survey-answers-explanation-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showPreviousButton
                showNextButton
                onPreviousClick={onPreviousSlideClick}
                onNextClick={onNextSlideClick}
            />
        </SignupSlide>
    );
};

export default SignupPulseSurveyAnswersExplanationSlide;
