import SupportedLanguage from "./SupportedLanguage";

type SupportedSurveyLanguage = SupportedLanguage.English | SupportedLanguage.French | SupportedLanguage.Spanish | SupportedLanguage.German | SupportedLanguage.Portuguese;

const SupportedSurveyLanguage = {
    English: SupportedLanguage.English,
    French: SupportedLanguage.French,
    Spanish: SupportedLanguage.Spanish,
    German: SupportedLanguage.German,
    Portuguese: SupportedLanguage.Portuguese
} as const;

export default SupportedSurveyLanguage;
