import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useDelayedEffect from "@hooks/useDelayedEffect";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import KeyCodes from "@core/enums/KeyCodes";
import sleep from "@core/utils/sleep";

import { ReactComponent as StarLegacy } from "./assets/legacy/star.svg";
import { ReactComponent as Star } from "./assets/star.svg";
import BasePulseSurveyQuestion from "./BasePulseSurveyQuestion";

import "./pulse-survey-star-rating-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    onChange: (value: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyStarRatingQuestion = ({
    className,
    question,
    initialValue,
    onChange,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );
    const { t } = useTranslation("activities");

    const [confirmed, setConfirmed] = useState(false);
    const [value, setValue] = useState(initialValue ?? null);
    const [focusedValue, setFocusedValue] = useState<number | null>(null);
    const [hoveredValue, setHoveredValue] = useState<number | null>(null);

    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onIntroAnimationCompleted();
            }
        },
        [],
        900
    );

    const handleOnConfirm = async(newValue: number) => {
        if (confirmed) {
            return;
        }

        setValue(newValue);
        setConfirmed(true);

        onChange(newValue);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleConfirmOnClick = (newValue: number, e: React.MouseEvent) => {
        e.preventDefault();
        handleOnConfirm(newValue);
    };

    const handleConfirmOnEnter = (newValue: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === KeyCodes.Enter) {
            e.currentTarget.blur();

            handleOnConfirm(newValue);
        }
    };

    const handleOnInputBlur = () => {
        setFocusedValue(null);
        setValue(null);
    };

    const renderStar = (i: number) => {
        const getIsSelected = () => {
            if (confirmed && value !== null) {
                return i <= value;
            }

            return (
                focusedValue === null &&
                hoveredValue === null &&
                value !== null &&
                i <= value
            );
        };

        const getIsHovered = () => {
            if (hoveredValue !== null) {
                return i === hoveredValue;
            }

            return false;
        };

        const getIsHighlighted = () => {
            if (confirmed) {
                return false;
            }

            if (hoveredValue !== null) {
                return i <= hoveredValue;
            }

            if (focusedValue !== null) {
                return i <= focusedValue;
            }

            return false;
        };

        const starClasses = classNames(
            "pulse-survey-star-rating-question__star-label",
            {
                "pulse-survey-star-rating-question__star-label--selected":
                    getIsSelected(),
                "pulse-survey-star-rating-question__star-label--highlighted":
                    getIsHighlighted(),
                "pulse-survey-star-rating-question__star-label--hovered":
                    getIsHovered()
            }
        );

        return (
            <div
                key={`star-rating-${i}`}
                className="pulse-survey-star-rating-question__choice"
                role="radio"
                aria-checked={value === i}
            >
                <input
                    id={`star-rating-${i}`}
                    className="pulse-survey-star-rating-question__radio-input"
                    type="radio"
                    name="star-rating"
                    value={i}
                    checked={
                        focusedValue !== null ? i === focusedValue : i === value
                    }
                    onFocus={() => setFocusedValue(i)}
                    onBlur={handleOnInputBlur}
                    onChange={() => setValue(i)}
                    disabled={confirmed}
                    onKeyDown={e => handleConfirmOnEnter(i, e)}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                <label
                    className={starClasses}
                    htmlFor={`star-rating-${i}`}
                    aria-label={t("pulseSurveyQuestions.star", {
                        count: i + 1
                    })}
                    onMouseEnter={() => setHoveredValue(i)}
                    onMouseLeave={() => setHoveredValue(null)}
                    onClick={e => handleConfirmOnClick(i, e)}
                >
                    <div className="pulse-survey-star-rating-question__star-icon-container">
                        {isWorkleapBrandEnabled ? (
                            <Star
                                className="pulse-survey-star-rating-question__star-icon"
                                aria-hidden="true"
                            />
                        ) : (
                            <StarLegacy
                                className="pulse-survey-star-rating-question__star-icon"
                                aria-hidden="true"
                            />
                        )}
                    </div>
                </label>
            </div>
        );
    };

    const classes = classNames("pulse-survey-star-rating-question", className, {
        "pulse-survey-star-rating-question--animated-intro": isIntroAnimated,
        "pulse-survey-star-rating-question--confirmed": confirmed
    });

    return (
        <BasePulseSurveyQuestion className={classes} question={question}>
            <div
                className="pulse-survey-star-rating-question__group"
                role="radiogroup"
            >
                {Array.from({ length: 5 }, (_, i) => renderStar(i))}
            </div>
        </BasePulseSurveyQuestion>
    );
};

export default PulseSurveyStarRatingQuestion;
