import React from "react";

import Alert from "@igloo-ui/alert";
import { useTranslation } from "react-i18next";

import CustomPollPermissionType from "@core/enums/CustomPollPermissionType";

interface Props {
    className?: string;
    permissionType: CustomPollPermissionType;
}

const CustomPollPermissionsAlert = ({ className, permissionType }: Props) => {
    const { t } = useTranslation("activities");

    if (permissionType === CustomPollPermissionType.None) {
        return null;
    }

    const alertProps =
        permissionType === CustomPollPermissionType.Restricting
            ? {
                title: t("customPollIntro.restrictingSurveyAlertTitle"),
                message: t("customPollIntro.restrictingSurveyAlertMessage")
            }
            : {
                title: t("customPollIntro.includingSurveyAlertTitle"),
                message: t("customPollIntro.includingSurveyAlertMessage")
            };

    return <Alert className={className} type="info" closable {...alertProps} />;
};

export default CustomPollPermissionsAlert;
