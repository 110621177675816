import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import { useSignupContext } from "@contexts/signup/SignupContext";
import { useUserContext } from "@contexts/user/UserContext";

import ApiException from "@core/api-client/ApiException";

import EmptyLayout from "@layouts/empty/EmptyLayout";
import LoadingPage from "@pages/loading/LoadingPage";

import SignupFlow from "./SignupFlow";

import { ReactComponent as WaveBackground } from "@svg/backgrounds/wave-background.svg";
import { ReactComponent as WavedLinesBackground } from "@svg/backgrounds/waved-lines-background.svg";
import { ReactComponent as Logo } from "@svg/logo.svg";

import "./signup-page.scss";

const SignupPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading: isSignupContextLoading, initializeSignupAsync } = useSignupContext();
    const { isLoading: isUserContextLoading } = useUserContext();
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    useEffect(() => {
        const callInitializeSignup = async() => {
            try {
                await initializeSignupAsync();
            } catch (ex) {
                if (ex instanceof ApiException && ex.errorCode === 403) {
                    navigate({
                        pathname: "/survey",
                        search: location.search
                    });
                } else {
                    throw ex;
                }
            }
        };

        callInitializeSignup();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isSignupContextLoading || isUserContextLoading) {
        return <LoadingPage />;
    }

    const handleNavigateToPulseSurvey = () => {
        navigate({
            pathname: "/survey",
            search: location.search
        });
    };

    return (
        <EmptyLayout className="signup-page" withFixedHeight>
            {isWorkleapBrandEnabled ? <WavedLinesBackground className="signup-page__background" /> : <WaveBackground className="signup-page__background" />}
            <div className="signup-page__header">
                <Logo className="signup-page__logo" />
            </div>
            <div className="signup-page__body">
                <SignupFlow className="signup-page__flow" onNavigateToPulseSurvey={handleNavigateToPulseSurvey} />
            </div>
        </EmptyLayout>
    );
};

export default SignupPage;