import React from "react";

import classNames from "classnames";

import SignupSlideImageHeaderTheme from "./SignupSlideImageHeaderTheme";

import "./signup-slide-image-header.scss";

interface Props {
    className?: string;
    image: Omit<React.ReactSVGElement, "ref">;
    theme?: SignupSlideImageHeaderTheme;
}

const SignupSlideImageHeader = ({ className, image, theme = SignupSlideImageHeaderTheme.Coral }: Props) => {
    const classes = classNames(
        "signup-slide-image-header",
        `signup-slide-image-header--${theme}`,
        className
    );

    return (
        <div className={classes}>
            {React.cloneElement(image, { className: classNames("signup-slide-image-header__image", image.props.className) })}
        </div>
    );
};

SignupSlideImageHeader.Theme = SignupSlideImageHeaderTheme;

export default SignupSlideImageHeader;