import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import CompletePulseSurveyPage from "@pages/activities/CompletePulseSurveyPage";
import ContinuePulseSurveyPage from "@pages/activities/ContinuePulseSurveyPage";
import CustomPollPage from "@pages/activities/CustomPollPage";
import PreviewCustomPollPage from "@pages/activities/PreviewCustomPollPage";
import RecognitionPage from "@pages/activities/RecognitionPage";
import TestCustomPollPage from "@pages/activities/TestCustomPollPage";
import TestDeibSurveyPage from "@pages/activities/TestDeibSurveyPage";
import TestOnboardingSurveyPage from "@pages/activities/TestOnboardingSurveyPage";
import TestPulseSurveyQuestionPage from "@pages/activities/TestPulseSurveyQuestionPage";
import TestRecognitionPage from "@pages/activities/TestRecognitionPage";
import TestSmartQuestionPage from "@pages/activities/TestSmartQuestionPage";
import TryPulseSurveyPage from "@pages/activities/TryPulseSurveyPage";
import NotFoundPage from "@pages/not-found/NotFoundPage";
import SignupPage from "@pages/signup/SignupPage";
import UrlShortenerRedirectPage from "@pages/url-shortener/UrlShortenerRedirectPage";

import AuthenticatedRoute from "./AuthenticatedRoute";
import NotAuthenticatedRoute from "./NotAuthenticatedRoute";

const ActivitiesRouting = () => {
    const renderActivitiesTestingRoutes = () => {
        if (window.env.ALLOW_TESTING_ACTIVITIES) {
            return [
                <Route key="test-survey" path="/test/survey/question/:questionId" element={<NotAuthenticatedRoute><TestPulseSurveyQuestionPage /></NotAuthenticatedRoute>} />,
                <Route key="test-custom-poll" path="/test/custom-poll" element={<NotAuthenticatedRoute><TestCustomPollPage /></NotAuthenticatedRoute>} />,
                <Route key="test-smart-question" path="/test/smart-question/:metricId/:subMetricId" element={<NotAuthenticatedRoute><TestSmartQuestionPage /></NotAuthenticatedRoute>} />,
                <Route key="test-recognition" path="/test/recognition" element={<NotAuthenticatedRoute><TestRecognitionPage /></NotAuthenticatedRoute>} />,
                <Route key="test-onboarding-survey" path="/test/onboarding/:questionId" element={<NotAuthenticatedRoute><TestOnboardingSurveyPage /></NotAuthenticatedRoute>} />,
                <Route key="test-deib-survey" path="/test/deib/:questionId" element={<NotAuthenticatedRoute><TestDeibSurveyPage /></NotAuthenticatedRoute>} />
            ];
        }

        return null;
    };

    return (
        <Routes>
            {renderActivitiesTestingRoutes()}
            <Route path="/" element={<Navigate replace to="/survey" />} />
            <Route path="/r/:urlShortenerId" element={<UrlShortenerRedirectPage />} />
            <Route path="/onboarding"
                element={(
                    <AuthenticatedRoute>
                        <SignupPage />
                    </AuthenticatedRoute>
                )}
            />
            <Route path="/survey/try"
                element={(
                    <NotAuthenticatedRoute>
                        <TryPulseSurveyPage />
                    </NotAuthenticatedRoute>
                )}
            />
            <Route path="/survey/continue"
                element={(
                    <AuthenticatedRoute withSignupRedirect>
                        <ContinuePulseSurveyPage />
                    </AuthenticatedRoute>
                )}
            />
            <Route path="/survey"
                element={(
                    <AuthenticatedRoute withSignupRedirect>
                        <CompletePulseSurveyPage />
                    </AuthenticatedRoute>
                )}
            />
            <Route path="/polls/:customPollId/preview"
                element={(
                    <AuthenticatedRoute>
                        <PreviewCustomPollPage />
                    </AuthenticatedRoute>
                )}
            />
            <Route path="/polls/:customPollId"
                element={(
                    <AuthenticatedRoute withSignupRedirect>
                        <CustomPollPage />
                    </AuthenticatedRoute>
                )}
            />
            <Route path="/recognition"
                element={(
                    <AuthenticatedRoute withSignupRedirect>
                        <RecognitionPage />
                    </AuthenticatedRoute>
                )}
            />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default ActivitiesRouting;