import type { TFunction } from "react-i18next";

import type { OfficevibePrompt } from "@api/RecognitionActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import RecognitionStepType from "@core/enums/RecognitionStepType";
import getCategoryNameFromCategoryId from "@core/utils/getCategoryNameFromCategoryId";
import getImageFromCategoryId from "@core/utils/getImageFromCategoryId";
import getPromptNameFromPromptId from "@core/utils/getPromptNameFromPromptId";
import getThemeFromCategoryId from "@core/utils/getThemeFromCategoryId";

const buildTestRecognitionActivity = (prompts: OfficevibePrompt[], t: TFunction<"activities">) => {
    const shuffledActivePrompts = prompts.map(p => ({
        ...p,
        categoryImage: getImageFromCategoryId(p.categoryId),
        categoryName: getCategoryNameFromCategoryId(t, p.categoryId),
        categoryTheme: getThemeFromCategoryId(p.categoryId),
        prompt: getPromptNameFromPromptId(t, p.id)
    }));

    return {
        activityType: ActivityType.TestRecognition as const,
        correlationId: "test-correlation-id",
        isOverdue: false,
        prompts: [],
        officevibePrompts: [],
        shuffledActivePrompts,
        steps: [
            {
                stepType: RecognitionStepType.SelectPrompt
            },
            {
                stepType: RecognitionStepType.CustomizeMessage
            }
        ]
    };
};

export default buildTestRecognitionActivity;