import type { AxiosRequestConfig } from "axios";

import { getContextAsync } from "@api/UserAuthenticationContextApiClient";
import sleep from "@core/utils/sleep";

class BearerFactory {
    private _isRefreshing: boolean;
    private _bearerToken: string | null;
    private _bearerExpiry: string | null | undefined;
    private _refreshToken: string | undefined;

    constructor() {
        this._isRefreshing = false;

        this._bearerToken = null;
        this._bearerExpiry = null;
    }

    hasToken() {
        return !!this._bearerToken;
    }

    hasExpiry() {
        return !!this._bearerExpiry;
    }

    setToken(token: string, expiry: string | undefined, refreshToken: string | undefined) {
        this._bearerToken = token;
        this._bearerExpiry = expiry;
        this._refreshToken = refreshToken;
    }

    injectBearerToken(configuration: AxiosRequestConfig<unknown>) {
        if (this.hasToken()) {
            return {
                ...configuration,
                headers: {
                    ...configuration.headers,
                    Authorization: `Bearer ${this._bearerToken}`
                }
            };
        }

        return configuration;
    }

    async refreshBearerTokenAsync() {
        if (this._isRefreshing) {
            // We are already refreshing the token, wait for response
            do {
                await sleep(20);
            } while (this._isRefreshing);
        } else {
            try {
                if (!this._refreshToken) {
                    return;
                }

                this._isRefreshing = true;
                const { jwtToken, jwtExpiry } = await getContextAsync(this._refreshToken);
        
                this.setToken(jwtToken, jwtExpiry, this._refreshToken);
                this._isRefreshing = false;
            } catch (e) {
                this._isRefreshing = false;

                throw e;
            }
        }
    }
}

const factory = new BearerFactory();

export default factory;