import React, { useContext } from "react";

import type { OrchestratedActivity, OrchestratedStep } from "@/types/activities/common";
import type { CustomPollMultipleChoiceAnswer } from "@/types/activities/custom-polls";
import type { GuidString } from "@/types/utilityTypes";

import type { UserIdentity } from "@api/RecognitionActivityApiClient";
import type { DeibQuestionID } from "@core/activities/deib-survey/types";
import type { OnboardingQuestionID } from "@core/activities/onboarding-survey/types";
import type { PulseSurveyQuestionId } from "@core/activities/pulse-survey/types";
import type OutroType from "@core/enums/OutroType";
import type RecognitionCategory from "@core/enums/RecognitionCategory";

import type getBackgroundState from "./backgroundExpert";
import type getBannerState from "./bannerExpert";
import type getControlBarState from "./controlBarExpert";
import type getCurrentTip from "./tipExpert";

interface Context {
    isInitialized: boolean;
    isLoading: boolean;
    activities: OrchestratedActivity[] | null;
    orchestrator: { currentActivityIndex: number } | null;
    outro: OutroType | null;
    isCompleted: boolean;
    disableNextButton: boolean;
    initializeCompleteSurveyAsync: (isPulseSurveyCustomTextualQuestionsEnabled: boolean) => void;
    initializeContinueSurveyAsync: (questionId: string, answerScore: number, answerValue: number, isPulseSurveyCustomTextualQuestionsEnabled: boolean) => void;
    initializeTestDeibSurveyAsync: (questionId: DeibQuestionID) => void;
    initializeTryPulseSurveyAsync: () => void;
    initializeCustomPollAsync: (customPollId: string) => void;
    initializePreviewCustomPollAsync: (customPollId: string) => void;
    initializeRecognitionAsync: () => void;
    initializeOnboardingSurveyAsync: () => void;
    initializeTestPulseSurveyQuestionAsync: (questionId: PulseSurveyQuestionId) => void;
    initializeTestCustomPollAsync: () => void;
    initializeTestSmartQuestionAsync: (metricId: string, subMetricId: string) => void;
    initializeTestRecognitionAsync: () => void;
    initializeTestOnboardingSurveyAsync: (questionId: OnboardingQuestionID) => void;
    getCurrentActivity: () => OrchestratedActivity | null;
    setDisableNextButton: (disableNextButton: boolean) => void;
    setIsAnswered: (isAnswered: boolean) => void;
    shouldSaveAnswers: () => boolean;
    getPreviousStep: (delta?: number) => OrchestratedStep | null;
    getCurrentStep: () => OrchestratedStep | null;
    getNextStep: () => OrchestratedStep | null;
    onGoToNextActivity: () => void;
    onGoToPreviousStep: () => void;
    onGoToNextStep: (isSkip?: boolean) => void;
    onGoToOutro: () => void;
    onPulseSurveyQuestionAnswered: (correlationId: GuidString, questionId: PulseSurveyQuestionId, answer: number, score: number) => void;
    onPulseSurveyQuestionSkipped: (correlationId: GuidString, questionId: PulseSurveyQuestionId, isFollowUpQuestion: boolean) => void;
    onPulseSurveyFeedbackAnonymityChanged: (isAnonymous: boolean) => void;
    onPulseSurveyFollowUpAnswerLeft: (correlationId: GuidString, questionId: PulseSurveyQuestionId, isConstructive: boolean, feedback: string, isAnonymous: boolean) => void;
    onPulseSurveyCustomTextQuestionAnswered:(correlationId: GuidString, customQuestionId: GuidString, feedback: string, isAnonymous: boolean) => void;
    onPulseSurveyCustomQuestionSkipped: (correlationId: string, customQuestionId: string) => void;
    onPulseSurveyActivityEnded: (correlationId: GuidString) => void;
    onCustomPollTextQuestionAnswered: (customPollId: string, questionId: string, feedback: string, isAnonymous: boolean) => void;
    onCustomPollOpinionScaleQuestionAnswered: (customPollId: string, questionId: string, score: number) => void;
    onCustomPollMultipleChoiceQuestionAnswered: (customPollId: string, questionId: string, choice: CustomPollMultipleChoiceAnswer, customAnswer: string | null) => void;
    onCustomPollMultipleChoiceQuestionWithMultiSelectionAnswered: (customPollId: string, questionId: string, choices: CustomPollMultipleChoiceAnswer[], customAnswer: string | null) => void;
    onCustomPollLikertScaleQuestionAnswered: (customPollId: string, questionId: string, choice: number) => void;
    onCustomPollQuestionSkipped: (customPollId: string, questionId: string, isFollowUpQuestion: boolean) => void;
    onCustomPollFeedbackAnonymityChanged: (isAnonymous: boolean) => void;
    onCustomPollFollowUpAnswerLeft: (customPollId: string, questionId: string, feedback: string, isAnonymous: boolean) => void;
    onCustomPollActivityEnded: (customPollId: string) => void;
    onSmartQuestionSkipped: (subMetricId: string) => void;
    onSmartQuestionFeedbackAnonymityChanged: (isAnonymous: boolean) => void;
    onSmartQuestionFeedbackLeft: (subMetricId: string, feedback: string, isAnonymous: boolean) => void;
    onSmartQuestionActivityEnded: (subMetricId: string) => void;
    onRecognitionPromptSelected: (correlationId: GuidString, categoryId: RecognitionCategory, promptId: string, promptIndex: number) => void;
    searchPeersAsync: (query: string, excludedIds?: string[]) => Promise<UserIdentity[]>;
    onRecognitionLeft: (correlationId: GuidString, recipientId: string, categoryId: RecognitionCategory, promptId: string, message: string | null | undefined) => void;
    onMultipleRecipientsRecognitionLeft: (correlationId: GuidString, recipientIds: string[], categoryId: RecognitionCategory, promptId: string, message: string | null | undefined, isPublic?: boolean) => void;
    onRecognitionSkipped: (correlationId: GuidString) => void;
    onRecognitionActivityEnded: (correlationId: GuidString) => void;
    onOnboardingSurveyQuestionAnswered: (correlationId: GuidString, questionId: OnboardingQuestionID, answer: number, score: number) => void;
    onOnboardingSurveyQuestionSkipped: (correlationId: GuidString, questionId: OnboardingQuestionID, isFollowUpQuestion: boolean) => void;
    onOnboardingSurveyFollowUpAnswerLeft: (correlationId: GuidString, questionId: OnboardingQuestionID, feedback: string) => void;
    onOnboardingSurveyActivityEnded: (correlationId: GuidString) => void;
    onDeibSurveyQuestionAnswered: (correlationId: GuidString, questionId: DeibQuestionID, answer: number, score: number) => void;
    onDeibSurveyQuestionSkipped: (correlationId: GuidString, questionId: DeibQuestionID) => void;
    onDeibSurveyActivityEnded: (correlationId: GuidString) => void;
    getControlBarState: () => ReturnType<typeof getControlBarState>;
    getBannerState: () => ReturnType<typeof getBannerState>;
    getBackgroundState: (isWorkleapBrandEnabled: boolean) => ReturnType<typeof getBackgroundState>;
    getCurrentTip: () => ReturnType<typeof getCurrentTip>;
}

const ActivitiesContext = React.createContext<Context | null>(null);

export default ActivitiesContext;

export const useActivitiesContext = () => {
    const context = useContext(ActivitiesContext);

    if (!context) {
        throw new Error("ActivitiesContext not found; are you missing ActivitiesContextProvider?");
    }

    return context;
};