import DisplayType from "@core/enums/PulseSurveyQuestionDisplayType";

import type { PulseSurveyQuestionId } from "./types";

const map: Record<PulseSurveyQuestionId, DisplayType> = {
    "Q-1": DisplayType.Slider, // On a scale from 0-10, how likely are you to recommend your organization as a good place to work?
    "Q-2": DisplayType.Slider, // On a scale from 0-10, how likely are you to recommend the products/services your organization makes?
    "Q-500": DisplayType.LikertScale, // I am appropriately involved in decisions that affect my work.
    "Q-501": DisplayType.TwoWaySlider, // Do you feel you have enough freedom to decide how you do your work?
    "Q-502": DisplayType.Slider, // On a scale from 0-10, how satisfied are you with the level of autonomy you have at work?
    "Q-504": DisplayType.TwoWaySlider, // I feel that I am in control when it comes to the work I need to accomplish.
    "Q-505": DisplayType.LikertScale, // I consider myself an ambassador for my organization.
    "Q-508": DisplayType.TwoWaySlider, // If you were in charge of the "best places to work" awards, would you nominate your organization?
    "Q-512": DisplayType.TwoWaySlider, // I feel like I am part of a team.
    "Q-513": DisplayType.MultipleChoice, // Do you and your peers collaborate well together?
    "Q-515": DisplayType.LikertScale, // My direct manager cares about my opinion.
    "Q-516": DisplayType.TwoWaySlider, // What is it like working with your direct manager?
    "Q-518": DisplayType.Slider, // On a scale from 0-10, how satisfied are you with the frequency at which you communicate with your peers at work?
    "Q-522": DisplayType.Slider, // On a scale from 0-10, how satisfied are you with how frequently you communicate with your direct manager?
    "Q-523": DisplayType.MultipleChoice, // Do you believe that the way pay is decided in your organization is fair?
    "Q-524": DisplayType.LikertScale, // When I take into account my responsibilities, skills and experience, I believe I am paid fairly.
    "Q-525": DisplayType.MultipleChoice, // Do you trust that you are paid fairly compared to similar roles in OTHER organizations?
    "Q-526": DisplayType.MultipleChoice, // Do you trust that you are paid fairly compared to similar roles WITHIN your organization?
    "Q-529": DisplayType.LikertScale, // People are respected for who they are in my organization.
    "Q-532": DisplayType.TwoWaySlider, // People from all backgrounds are treated fairly in my organization.
    "Q-533": DisplayType.LikertScale, // I am satisfied with the frequency of feedback coming from my peers.
    "Q-534": DisplayType.TwoWaySlider, // Are you satisfied with the frequency of feedback coming from your direct manager?
    "Q-535": DisplayType.MultipleChoice, // Which of the following best describes the amount of feedback you typically get regarding your work?
    "Q-536": DisplayType.TwoWaySlider, // The feedback I receive is frequent enough to help me understand how I can improve.
    "Q-537": DisplayType.LikertScale, // The feedback I receive helps me grow and develop.
    "Q-538": DisplayType.LikertScale, // When someone gives me feedback at work, it's done in a respectful way.
    "Q-539": DisplayType.Slider, // On a scale from 0-10, how valuable is the feedback you receive?
    "Q-540": DisplayType.MultipleChoice, // Is the feedback you get specific?
    "Q-541": DisplayType.TwoWaySlider, // Do you enjoy the work you do?
    "Q-542": DisplayType.LikertScale, // Most days I feel a sense of accomplishment from what I do.
    "Q-543": DisplayType.LikertScale, // My work is fulfilling.
    "Q-544": DisplayType.Stars, // Generally speaking, how would you rate your level of happiness at work?
    "Q-548": DisplayType.Stars, // How would you rate the way your organization makes use of your strengths?
    "Q-549": DisplayType.Slider, // On a scale from 0-10, how would you rate your level of access to relevant training?
    "Q-550": DisplayType.MultipleChoice, // How would you describe the level of challenge you have at work?
    "Q-551": DisplayType.TwoWaySlider, // Do you have the opportunity to grow within your organization?
    "Q-554": DisplayType.LikertScale, // I feel physically safe where I am working.
    "Q-555": DisplayType.Slider, // On a scale from 0-10, how would you rate your organization's actions to promote wellness (support, resources for remote work or mental health, programs, etc.)?
    "Q-557": DisplayType.LikertScale, // I am proud of what my organization stands for.
    "Q-559": DisplayType.TwoWaySlider, // When you say what organization you work for, how do you feel?
    "Q-562": DisplayType.LikertScale, // I feel like my organization trusts me to contribute to our mission.
    "Q-563": DisplayType.MultipleChoice, // Do you feel that your work has an impact on your organization's purpose?
    "Q-565": DisplayType.LikertScale, // I am happy with how frequently I am recognized.
    "Q-566": DisplayType.LikertScale, // Recognition is given in a timely fashion.
    "Q-567": DisplayType.Stars, // How would you rate the frequency at which you receive recognition?
    "Q-569": DisplayType.MultipleChoice, // My organization encourages team members to give recognition to one another.
    "Q-574": DisplayType.Slider, // On a scale from 0-10, how reasonable is your workload?
    "Q-575": DisplayType.MultipleChoice, // Are your responsibilities clear?
    "Q-577": DisplayType.LikertScale, // My organization offers the support I need to deal with work-related stress.
    "Q-578": DisplayType.MultipleChoice, // Someone would say or do something helpful if I show signs of stress when working.
    "Q-579": DisplayType.TwoWaySlider, // Overall, how do you feel about your level of work-related stress?
    "Q-580": DisplayType.LikertScale, // I feel like I can voice my opinion regarding my organization.
    "Q-581": DisplayType.LikertScale, // Team members are encouraged to be innovative even though some initiatives may not succeed.
    "Q-582": DisplayType.Stars, // How would you rate the importance that your organization gives to your opinions and suggestions?
    "Q-583": DisplayType.Slider, // On a scale from 0-10, if you have new ideas for your organization, what are the chances that you will share them?
    "Q-586": DisplayType.LikertScale, // My peers are committed to doing quality work.
    "Q-587": DisplayType.Slider, // On a scale from 0-10, how much do you trust your peers?
    "Q-590": DisplayType.TwoWaySlider, // Do you respect the people you work with?
    "Q-591": DisplayType.LikertScale, // My direct manager is someone I can trust.
    "Q-592": DisplayType.LikertScale, // My direct manager cares about my well-being.
    "Q-593": DisplayType.LikertScale, // My direct manager treats me with respect.
    "Q-595": DisplayType.LikertScale, // My organization's values are aligned with values I consider important in life.
    "Q-597": DisplayType.LikertScale, // The way our organization makes decisions reflects our organization's values.
    "Q-600": DisplayType.LikertScale, // I am inspired by the purpose and mission of my organization.
    "Q-601": DisplayType.LikertScale, // The leaders of my organization have communicated a vision that motivates me.
    "Q-602": DisplayType.TwoWaySlider, // Does your organization invest an amount of resources, people and efforts that measures up to its ambitions?
    "Q-603": DisplayType.LikertScale, // The goals and strategies of my organization are taking us in the right direction.
    "Q-604": DisplayType.MultipleChoice, // Do you believe that your organization is able to reach its objectives?
    "Q-605": DisplayType.TwoWaySlider, // Is your organization's long term vision clear to you?
    "Q-606": DisplayType.TwoWaySlider, // My organization communicates in a clear and effective way with team members.
    "Q-607": DisplayType.LikertScale, // I feel that I can maintain a healthy balance between work and my personal life.
    "Q-608": DisplayType.LikertScale, // I am supported by my organization if I need to make use of flexible working arrangements.
    "Q-609": DisplayType.TwoWaySlider, // Do you have the flexibility to take time off when you need to?
    "Q-610": DisplayType.TwoWaySlider, // I feel supported in my workplace when I am dealing with personal or family issues.
    "Q-611": DisplayType.MultipleChoice, // Have you noticed your work taking a toll on your personal life?
    "Q-612": DisplayType.TwoWaySlider, // Does your work environment allow you to work distraction-free when you need to?
    "Q-613": DisplayType.TwoWaySlider, // Are you comfortable in your work environment?
    "Q-614": DisplayType.TwoWaySlider, // How enjoyable is your work environment?
    "Q-800": DisplayType.TwoWaySlider, // I see concrete actions that bring my organization's commitment to social responsibility to life.
    "Q-801": DisplayType.LikertScale, // My organization values the uniqueness of its team members.
    "Q-802": DisplayType.Slider, // On a scale from 0-10, how well are your organization's values reflected in how people behave at work?
    "Q-803": DisplayType.LikertScale, // I am proud of the value we create as an organization.
    "Q-804": DisplayType.MultipleChoice, // Can you try new tools or new ideas that will help you do your work better?
    "Q-805": DisplayType.TwoWaySlider, // I am supported towards my growth and development.
    "Q-806": DisplayType.TwoWaySlider, // Can you see how your work contributes to your organization's objectives?
    "Q-807": DisplayType.MultipleChoice, // I receive meaningful recognition.
    "Q-808": DisplayType.MultipleChoice, // My direct manager keeps me informed about what is happening in our organization.
    "Q-809": DisplayType.LikertScale, // I feel that my direct manager does a good job managing people.
    "Q-810": DisplayType.LikertScale, // I feel that my direct manager understands my day-to-day reality.
    "Q-811": DisplayType.LikertScale, // My peers and I have open discussions in which everyone's opinion counts.
    "Q-812": DisplayType.TwoWaySlider, // Do communications among peers feel honest?
    "Q-813": DisplayType.LikertScale, // I feel safe to be myself with my peers.
    "Q-814": DisplayType.LikertScale, // My peers demonstrate competency in their roles.
    "Q-815": DisplayType.TwoWaySlider, // At work, I know what I’m expected to deliver.
    "Q-816": DisplayType.LikertScale, // My organization really cares about my mental well-being.
    "Q-817": DisplayType.LikertScale, // I have a manageable stress level at work.
    "Q-818": DisplayType.MultipleChoice, // Does your team work in alignment with your organization's values?
    "Q-819": DisplayType.Slider, // On a scale from 0-10, how likely is it that you would to stay with [network.displayname] if you were offered a similar job elsewhere?
    "Q-820": DisplayType.TwoWaySlider, // My organization takes suggestions from team members into consideration.
    "Q-821": DisplayType.LikertScale, // I have the opportunity to do what I do best in my role.
    "Q-822": DisplayType.TwoWaySlider, // I feel a strong sense of purpose related to my role here.
    "Q-823": DisplayType.LikertScale, // If I do great work, I know that it will be recognized.
    "Q-824": DisplayType.TwoWaySlider, // My direct manager takes accountability for our team's results.
    "Q-825": DisplayType.TwoWaySlider, // I feel that my direct manager communicates honestly with me.
    "Q-826": DisplayType.LikertScale, // Collaboration with peers from other teams is positive.
    "Q-827": DisplayType.LikertScale, // I feel that every member of our team contributes to our team goals.
    "Q-828": DisplayType.TwoWaySlider, // In our team, we hold each other accountable for results.
    "Q-829": DisplayType.LikertScale, // I feel that my individual goals are strongly aligned with our team goals.
    "Q-830": DisplayType.TwoWaySlider, // I revisit and discuss my goals at a frequency that makes sense for me.
    "Q-831": DisplayType.MultipleChoice, // Do you have all you need to do your work properly?
    "Q-832": DisplayType.MultipleChoice, // Our organization enables people to collaborate easily, even if remote.
    "Q-833": DisplayType.LikertScale, // I am able to maintain a good level of energy at work.
    "Q-834": DisplayType.MultipleChoice, // Do you feel that working for your organization allows you to have a healthy lifestyle?
    "Q-835": DisplayType.LikertScale, // My organization works to attract, develop, and retain people with diverse backgrounds.
    "Q-836": DisplayType.Slider, // On a scale from 0-10, how welcome and included do you feel in your organization?
    "Q-837": DisplayType.TwoWaySlider, // Continuous recognition is provided in my organization.
    "Q-838": DisplayType.TwoWaySlider, // My direct manager gives me the support I need in my work.
    "Q-839": DisplayType.TwoWaySlider, // My direct manager creates opportunities for open discussion within our team.
    "Q-840": DisplayType.TwoWaySlider, // My job performance is evaluated fairly.
    "Q-841": DisplayType.LikertScale, // Poor performance is effectively addressed in our organization.
    "Q-842": DisplayType.TwoWaySlider, // I feel secure in my role and position here.
    "Q-843": DisplayType.TwoWaySlider, // My responsibilities are in line with my role.
    "Q-CUSTOM": DisplayType.Custom
};

const mapQuestionIdToDisplayType = (questionId: PulseSurveyQuestionId): DisplayType => {
    if (Object.prototype.hasOwnProperty.call(map, questionId)) {
        return map[questionId];
    }

    throw new Error(`Question Id (${questionId}) not found in mapping`);
};

export default mapQuestionIdToDisplayType;