enum RecognitionCategoryImage {
    TeamPlayer = "TeamPlayerIllustration",
    Excellence = "ExcellenceIllustration",
    Positivity = "PositivityIllustration",
    Leadership = "LeadershipIllustration",
    ShowingUp = "ShowingUpIllustration",
    Illustration1 = "Illustration1",
    Illustration2 = "Illustration2",
    Illustration3 = "Illustration3",
    Illustration4 = "Illustration4",
    Illustration5 = "Illustration5",
    Illustration6 = "Illustration6",
    Illustration7 = "Illustration7",
    Illustration8 = "Illustration8"
}

export default RecognitionCategoryImage;