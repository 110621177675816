import React, { useEffect } from "react";

import type { OnboardingSurveyActivityType, OrchestratedOnboardingSurveyFollowUpQuestionStep, OrchestratedOnboardingSurveyQuestionStep, OrchestratedOnboardingSurveyStep } from "@/types/activities/onboarding-survey";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import type { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import ContinueOnboardingSurveyIntro from "@components/onboarding-survey-question/ContinueOnboardingSurveyIntro";

import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";
import { trackActivityCompleted, trackActivityStarted, trackFeedbackGiven, trackFeedbackShown, trackLinkClicked, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

import OnboardingSurveyActivityFollowUpQuestionStep from "./OnboardingSurveyActivityFollowUpQuestionStep";
import OnboardingSurveyActivityQuestionStep from "./OnboardingSurveyActivityQuestionStep";

interface Props {
    className?: string;
    activityType: OnboardingSurveyActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    isIntroAnimated: boolean;
    correlationId: string;
    steps: OrchestratedOnboardingSurveyStep[];
    currentStepIndex: number;
    onSliderQuestionAnswered: ReturnType<typeof useActivitiesContext>["onOnboardingSurveyQuestionAnswered"];
    onLikertQuestionAnswered: ReturnType<typeof useActivitiesContext>["onOnboardingSurveyQuestionAnswered"];
    onMultipleChoiceQuestionAnswered: ReturnType<typeof useActivitiesContext>["onOnboardingSurveyQuestionAnswered"];
    onFollowUpFeedbackLeft: ReturnType<typeof useActivitiesContext>["onOnboardingSurveyFollowUpAnswerLeft"];
    onGoToPreviousStep: () => void;
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (correlationId: string) => void;
    onIntroAnimationCompleted: () => void;
}

const OnboardingSurveyActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    correlationId,
    steps,
    currentStepIndex,
    onSliderQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onFollowUpFeedbackLeft,
    onGoToPreviousStep,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    isIntroAnimated,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();
    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isQuestionStep = (step: OrchestratedOnboardingSurveyStep): step is OrchestratedOnboardingSurveyQuestionStep => "questionId" in step;

    const handleOnQuestionAnswered = (callback: ReturnType<typeof useActivitiesContext>["onOnboardingSurveyQuestionAnswered"]) => (answer: number, score: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, currentStep.questionId);

        callback(correlationId, currentStep.questionId, answer, score);
    };

    const handleOnFollowUpQuestionAnswered = (feedback: string) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, false, currentStep.questionId);

        onFollowUpFeedbackLeft(correlationId, currentStep.questionId, feedback);
    };

    const handleOnTrackFeedbackShown = () => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackShown(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, currentStep.questionId);
    };

    const handleOnGoBack = () => {
        trackLinkClicked(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, "Edit Answer");

        onGoToPreviousStep();
    };

    const handleOnAnimationCompleted = () => {
        if (currentStep.isLastStep) {
            trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
            onActivityEnded(correlationId);

            if (isLastActivity) {
                onGoToOutro();
            } else {
                onGoToNextActivity();
            }
        } else {
            onGoToNextStep();
        }
    };

    const renderIntroStep = () => {
        const handleOnDisplayTimeoutEnded = () => {
            onIntroAnimationCompleted();
            handleOnAnimationCompleted();
        };

        return (
            <ContinueOnboardingSurveyIntro
                isIntroAnimated={isIntroAnimated}
                onDisplayTimeoutEnd={handleOnDisplayTimeoutEnded}
            />
        );
    };

    const renderQuestionStep = (step: OrchestratedOnboardingSurveyQuestionStep) =>
        <OnboardingSurveyActivityQuestionStep
            step={step}
            onSliderQuestionAnswered={handleOnQuestionAnswered(onSliderQuestionAnswered)}
            onLikertQuestionAnswered={handleOnQuestionAnswered(onLikertQuestionAnswered)}
            onMultipleChoiceQuestionAnswered={handleOnQuestionAnswered(onMultipleChoiceQuestionAnswered)}
            isIntroAnimated={isIntroAnimated}
            onIntroAnimationCompleted={onIntroAnimationCompleted}
            onAnimationCompleted={handleOnAnimationCompleted}
        />;

    const renderFollowUpQuestionStep = (step: OrchestratedOnboardingSurveyFollowUpQuestionStep) =>
        <OnboardingSurveyActivityFollowUpQuestionStep
            {...step}
            followUpQuestion={step.followUpQuestion}
            onSubmit={handleOnFollowUpQuestionAnswered}
            onAnimationCompleted={handleOnAnimationCompleted}
            onGoBack={handleOnGoBack}
            onTrackFeedbackShown={handleOnTrackFeedbackShown}
        />;

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            case OnboardingSurveyStepType.Intro:
                return renderIntroStep();
            case OnboardingSurveyStepType.AskQuestion:
                return renderQuestionStep(currentStep);
            case OnboardingSurveyStepType.AskFollowUpQuestion:
                return renderFollowUpQuestionStep(currentStep);
            default:
                throw new Error(`Onboarding Survey Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

export default OnboardingSurveyActivity;