import RecognitionCategory from "@core/enums/RecognitionCategory";
import RecognitionCategoryImage from "@core/enums/RecognitionCategoryImage";

export default function getImageFromCategoryId(categoryId: RecognitionCategory): RecognitionCategoryImage {
    switch (categoryId) {
        case RecognitionCategory.Leadership:
            return RecognitionCategoryImage.Leadership;
        case RecognitionCategory.TeamPlayer:
            return RecognitionCategoryImage.TeamPlayer;
        case RecognitionCategory.Positivity:
            return RecognitionCategoryImage.Positivity;
        case RecognitionCategory.Excellence:
            return RecognitionCategoryImage.Excellence;
        case RecognitionCategory.ShowingUp:
            return RecognitionCategoryImage.ShowingUp;
        default:
            throw new Error(`Recognition Category (${categoryId}) not supported`);
    }
}