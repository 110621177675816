import React from "react";

import classNames from "classnames";

import GhostButtonTheme from "./GhostButtonTheme";

import "./ghost-button.scss";

interface IconComponentProps {
    className?: string;
}

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    theme?: GhostButtonTheme;
    loading?: boolean;
    disabled?: boolean;
    showOnlyIconOnMobile?: boolean;
    iconLeading?: React.ReactElement<IconComponentProps>;
    iconTrailing?: React.ReactElement<IconComponentProps>;
}

const GhostButton = ({
    className,
    theme = GhostButtonTheme.Dark,
    children,
    iconLeading,
    iconTrailing,
    disabled = false,
    loading = false,
    showOnlyIconOnMobile = false,
    onClick,
    ...props
}: Props) => {
    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        if (onClick && !loading && !disabled) {
            onClick(e);
        }
    };

    const classes = classNames(
        "ghost-button",
        `ghost-button--${theme}`,
        className,
        {
            "ghost-button--loading": loading,
            "ghost-button--disabled": disabled,
            "ghost-button--mobile-icon-only": showOnlyIconOnMobile
        }
    );

    return (
        <button
            type="button"
            className={classes}
            disabled={disabled}
            onClick={handleOnClick}
            {...props}
        >
            {iconLeading &&
                React.cloneElement(iconLeading, {
                    className: classNames(
                        "ghost-button__icon",
                        "ghost-button__icon--leading",
                        iconLeading.props.className
                    )
                })}
            <div className="ghost-button__text">{children}</div>
            {iconTrailing &&
                React.cloneElement(iconTrailing, {
                    className: classNames(
                        "ghost-button__icon",
                        "ghost-button__icon--trailing",
                        iconTrailing.props.className
                    )
                })}
            {loading && <div className="ghost-button__loader" />}
        </button>
    );
};

GhostButton.Theme = GhostButtonTheme;

export default GhostButton;
