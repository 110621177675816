import React, { type ReactNode } from "react";

import i18next from "i18next";

import { getAsync as getUserContextAsync } from "@api/UserContextApiClient";

import UserContext, { type UserContextState } from "./UserContext";

interface Props {
    children: ReactNode;
}

class UserContextProvider extends React.Component<Props, UserContextState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isInitialized: false,
            isLoading: true,
            context: null,
            initializeUserContextAsync: async() => {
                const { isInitialized } = this.state;
                if (!isInitialized) {
                    this.setState({ isInitialized: true, isLoading: true });
                    const context = await getUserContextAsync();

                    await i18next.changeLanguage(context.language);

                    this.setState({ context, isLoading: false });
                }
            },
            markSignupAsCompleted: () => {
                this.setState(s => ({
                    ...s,
                    context: s.context ?
                        {
                            ...s.context,
                            isSignupComplete: true
                        } :
                        null
                }));
            }
        };
    }

    render() {
        const { children } = this.props;

        return (
            <UserContext.Provider value={this.state}>
                {children}
            </UserContext.Provider>
        );
    }
}

export default UserContextProvider;