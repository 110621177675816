import React from "react";

import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import EmptyLayout from "@layouts/empty/EmptyLayout";

import { ReactComponent as Illustration } from "@svg/illustrations/legacy/illustration-not-found.svg";

import "./error-page.scss";

interface Props {
    className?: string;
}

const ErrorPage = ({ className }: Props) => {
    const { t } = useTranslation("activities");

    const handleOnReload = () => {
        location.reload();
    };

    const classes = classNames(
        "error-page",
        className
    );

    return (
        <EmptyLayout className={classes} withFixedHeight>
            <div className="error-page__image-container">
                <Illustration className="error-page__image" />
            </div>
            <div className="error-page__title">
                {t("errorPage.title")}
            </div>
            <div className="error-page__subtitle">
                <Trans ns="activities"
                    i18nKey="errorPage.subtitle"
                    components={[<button type="button" key={0} className="error-page__reload-link" onClick={handleOnReload}>REPLACED BY I18N</button>]}
                />
            </div>
        </EmptyLayout>
    );
};

export default ErrorPage;