import i18n from "i18next";
import Backend from "i18next-chained-backend";
import HttpBackend, { type HttpBackendOptions } from "i18next-http-backend";
import LocalStorageBackend, { type LocalStorageBackendOptions } from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

const productionConfig = () => {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: "en-US",
            fallbackLng: false,
            keySeparator: ".",
            supportedLngs: ["en-US", "fr-CA", "de", "pt-BR", "es-ES"],
            ns: [],
            backend: {
                backends: [
                    LocalStorageBackend,
                    HttpBackend
                ],
                backendOptions: [{
                    prefix: "game_res_",
                    expirationTime: window.env.TRANSLATION.EXPIRATION
                } satisfies LocalStorageBackendOptions, {
                    loadPath: `${window.env.CDN_URL}/game/localization/{{lng}}/{{ns}}.json`
                } satisfies HttpBackendOptions]
            },
            react: {
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ["br", "strong", "i"]
            },
            interpolation: { escapeValue: false }
        });
};

const debugConfig = () => {
    i18n
        .use(initReactI18next)
        .init({ 
            lng: "cimode",
            debug: true
        });

    // make sure the language stays in cimode
    i18n.on("languageChanged", lng => {
        if (lng !== "cimode") {
            i18n.changeLanguage("cimode");
        }
    });
};

if (window.env.TRANSLATION.SHOW_TRANSLATION_KEYS_SHORTCUT) {
    if (sessionStorage.getItem("showTranslationKeys") === "true") {
        debugConfig();
    } else {
        productionConfig();
    }
} else {
    productionConfig();
}

export default i18n;