import React from "react";

import classNames from "classnames";

import "./step.scss";

interface Props {
    className?: string;
    isCurrentStep: boolean;
}

const Step = ({ className, isCurrentStep }: Props) => {
    const classes = classNames(
        "step",
        className, {
            "step--current": isCurrentStep
        }
    );

    return (
        <div className={classes}>
            <div className="step__background-dot" />
            <div className="step__dot" />
        </div>
    );
};

export default Step;