import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";
import { useTipsContext } from "@contexts/tips/TipsContext";

import ApiException from "@core/api-client/ApiException";

import EmptyCustomPollException from "../../core/activities/custom-poll/EmptyCustomPollException";
import CustomPollForbiddenPage from "../forbidden/CustomPollForbiddenPage";
import ActivitiesPage from "./components/ActivitiesPage";

const CustomPollPage = () => {
    const { customPollId } = useParams();
    const { initializeCustomPollAsync } = useActivitiesContext();
    const { initializeTipsContextAsync } = useTipsContext();

    const [isCustomPollForbidden, setIsCustomPollForbidden] = useState(false);
    const [isCustomSurveyCompleted, setIsCustomSurveyCompleted] = useState(false);

    useEffect(() => {
        const initializeCustomPoll = async() => {
            try {
                if (!customPollId) {
                    throw new Error("Custom poll ID is missing from parameters");
                }

                await initializeCustomPollAsync(customPollId);
            } catch (ex) {
                if (ex instanceof ApiException && ex.errorCode === 403) {
                    setIsCustomPollForbidden(true);
                } else if (ex instanceof EmptyCustomPollException) {
                    setIsCustomSurveyCompleted(true);
                } else {
                    throw ex;
                }
            }
        };

        initializeCustomPoll();
        initializeTipsContextAsync();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isCustomPollForbidden || isCustomSurveyCompleted) {
        return <CustomPollForbiddenPage isCustomSurveyCompleted={isCustomSurveyCompleted} />;
    }

    return (
        <ActivitiesPage />
    );
};

export default CustomPollPage;