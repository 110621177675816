import type { ITelemetryItem } from "@microsoft/applicationinsights-web";

const removeTokenFromStringIfPresent = (str: string) => {
    if (!str) {
        return str;
    }

    const tokenRegex = /(?<beforeToken>.*)token=(.*)(?<afterToken>&{0,}.*)/i;
    const match = tokenRegex.exec(str);
    
    if (match && match.groups) {
        return `${match.groups.beforeToken}token=<REDACTED>${match.groups.afterToken}`;
    }

    return str;
};

/* eslint-disable no-param-reassign */
export default (envelope: ITelemetryItem) => {
    if (envelope.baseData?.name) {
        envelope.baseData.name = removeTokenFromStringIfPresent(envelope.baseData.name);
    }

    if (envelope.baseData?.target) {
        envelope.baseData.target = removeTokenFromStringIfPresent(envelope.baseData.target);
    }

    if (envelope.baseData?.refUri) {
        envelope.baseData.refUri = removeTokenFromStringIfPresent(envelope.baseData.refUri);
    }
};