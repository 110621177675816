import React from "react";

import classNames from "classnames";
import { use100vh } from "react-div-100vh";

import "./empty-layout.scss";

interface Props {
    className?: string;
    children?: React.ReactNode;
    withFixedHeight?: boolean;
}

const EmptyLayout = ({ className, children, withFixedHeight = false }: Props) => {
    const browserHeight = use100vh() || "100vh"; // This is a workaround to avoid the layout not fully covering the page on mobile

    const classes = classNames(
        "empty-layout",
        className
    );

    const styles = {
        minHeight: browserHeight,
        ...(withFixedHeight ? { height: browserHeight } : {})
    };

    return (
        <div className={classes} style={styles}>
            {children}
        </div>
    );
};

export default EmptyLayout;