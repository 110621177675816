import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useKeyboard, { Handled } from "@hooks/useKeyboard";

import LabeledFormField from "@components/form/LabeledFormField";
import ValidatedFormField from "@components/form/ValidatedFormField";
import Input from "@components/input/Input";
import PhoneInput from "@components/phone-input/PhoneInput";

import type { OnboardingControllerSaveUserCommunicationPreferencesRequest } from "@api/SignupApiClient";
import DeliveryChannel from "@core/enums/DeliveryChannel";
import KeyCode from "@core/enums/KeyCodes";
import * as ensure from "@core/utils/validation";

import DeliveryChannelSelect from "./DeliveryChannelSelect";
import SignupSlide from "./SignupSlide";

import "./signup-communication-preferences-slide.scss";

interface Props {
    className?: string;
    deliveryChannel: DeliveryChannel;
    defaultDeliveryChannel: DeliveryChannel;
    workEmail: string;
    personalEmail: string | null;
    mobilePhone: string | null;
    slideIndex: number;
    totalSlides: number;
    isDesklessUser: boolean;
    onSubmit: (data: OnboardingControllerSaveUserCommunicationPreferencesRequest) => void;
    onPreviousSlideClick: () => void;
    onNextSlideClick: () => void;
}

const SignupCommunicationPreferencesSlide = ({
    className,
    deliveryChannel,
    defaultDeliveryChannel,
    workEmail,
    personalEmail,
    mobilePhone,
    slideIndex,
    totalSlides,
    isDesklessUser,
    onSubmit,
    onPreviousSlideClick,
    onNextSlideClick
}: Props) => {
    const { t } = useTranslation("activities");
    const [internalDeliveryChannel, setInternalDeliveryChannel] = useState(deliveryChannel);
    const [internalPersonalEmail, setInternalPersonalEmail] = useState(personalEmail ?? "");
    const [internalMobilePhone, setInternalMobilePhone] = useState(mobilePhone ?? "");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    const validations = {
        personalEmail:
            internalDeliveryChannel === DeliveryChannel.PersonalEmail
                ? ensure.all(
                    ensure.isNotNullOrEmpty(
                        internalPersonalEmail,
                        t("onboarding.personalEmailFieldRequiredError")
                    ),
                    ensure.isValidEmailFormat(
                        internalPersonalEmail,
                        t("onboarding.personalEmailFieldInvalidFormatError")
                    )
                )
                : ensure.success(),
        mobilePhone:
            internalDeliveryChannel === DeliveryChannel.Sms
                ? ensure.all(
                    ensure.isNotNullOrEmpty(
                        internalMobilePhone,
                        t("onboarding.mobilePhoneFieldRequiredError")
                    ),
                    ensure.isValidPhoneNumberFormat(
                        internalMobilePhone,
                        t("onboarding.mobilePhoneFieldInvalidFormatError")
                    )
                )
                : ensure.success()
    };
    const isValid =
        validations.personalEmail.isValid && validations.mobilePhone.isValid;

    const handleOnSubmit = async() => {
        if (isValid) {
            try {
                setShowValidationErrors(false);
                setIsSubmitting(true);
                await onSubmit(
                    internalDeliveryChannel === DeliveryChannel.PersonalEmail ? {
                        deliveryChannel: DeliveryChannel.PersonalEmail,
                        personalEmail: internalPersonalEmail,
                        mobilePhone
                    } : internalDeliveryChannel === DeliveryChannel.Sms ? {
                        deliveryChannel: DeliveryChannel.Sms,
                        mobilePhone: internalMobilePhone,
                        personalEmail
                    } : {
                        deliveryChannel: internalDeliveryChannel,
                        personalEmail,
                        mobilePhone
                    }

                );
                onNextSlideClick();
            } catch (ex) {
                setIsSubmitting(false);

                throw ex;
            }
        } else {
            setShowValidationErrors(true);
        }
    };

    useKeyboard({
        [KeyCode.Enter]: () => {
            handleOnSubmit();

            return Handled;
        }
    });

    const renderWorkEmailField = () => {
        return (
            <div className="signup-communication-preferences-slide__form-row">
                <LabeledFormField label={t("onboarding.workEmailFieldLabel")}>
                    <Input value={workEmail} disabled />
                </LabeledFormField>
            </div>
        );
    };

    const renderPersonalEmailField = () => {
        return (
            <div className="signup-communication-preferences-slide__form-row">
                <LabeledFormField
                    label={t("onboarding.personalEmailFieldLabel")}
                >
                    <ValidatedFormField
                        message={validations.personalEmail.message}
                        isValid={validations.personalEmail.isValid}
                        enabled={showValidationErrors}
                    >
                        <Input
                            placeholder={t(
                                "onboarding.personalEmailFieldPlaceholder"
                            )}
                            value={internalPersonalEmail || ""}
                            onChange={e =>
                                setInternalPersonalEmail(e.target.value)
                            }
                            error={
                                showValidationErrors &&
                                !validations.personalEmail.isValid
                            }
                            autoFocus={!internalPersonalEmail}
                        />
                    </ValidatedFormField>
                </LabeledFormField>
            </div>
        );
    };

    const renderSmsField = () => {
        return (
            <div className="signup-communication-preferences-slide__form-row">
                <LabeledFormField label={t("onboarding.mobilePhoneFieldLabel")}>
                    <ValidatedFormField
                        message={validations.mobilePhone.message}
                        isValid={validations.mobilePhone.isValid}
                        enabled={showValidationErrors}
                    >
                        <PhoneInput
                            value={internalMobilePhone}
                            onChange={mp => setInternalMobilePhone(mp)}
                            error={
                                showValidationErrors &&
                                !validations.mobilePhone.isValid
                            }
                            autoFocus={!internalMobilePhone}
                        />
                    </ValidatedFormField>
                </LabeledFormField>
            </div>
        );
    };

    const renderDeliveryChannelDetailsField = () => {
        switch (internalDeliveryChannel) {
            case DeliveryChannel.WorkEmail:
                return renderWorkEmailField();

            case DeliveryChannel.PersonalEmail:
                return renderPersonalEmailField();

            case DeliveryChannel.Sms:
                return isDesklessUser ? undefined : renderSmsField();

            case DeliveryChannel.Slack:
                return null;

            case DeliveryChannel.MsTeams:
                return null;

            default:
                throw new Error(
                    `Delivery Channel (${internalDeliveryChannel}) not supported`
                );
        }
    };

    const classes = classNames(
        "signup-communication-preferences-slide",
        className
    );

    return (
        <SignupSlide className={classes}>
            <SignupSlide.TitleHeader
                className="signup-communication-preferences-slide__header"
                title={t("onboarding.communicationPreferencesSlideTitle")}
                subtitle={t("onboarding.communicationPreferencesSlideSubtitle")}
            />
            <SignupSlide.Form className="signup-communication-preferences-slide__form">
                <div className="signup-communication-preferences-slide__form-row">
                    <LabeledFormField
                        label={t("onboarding.deliveryChannelFieldLabel")}
                    >
                        <DeliveryChannelSelect
                            value={internalDeliveryChannel}
                            defaultDeliveryChannel={defaultDeliveryChannel}
                            onChange={dc => setInternalDeliveryChannel(dc)}
                        />
                    </LabeledFormField>
                </div>
                {renderDeliveryChannelDetailsField()}
            </SignupSlide.Form>
            <SignupSlide.Footer
                className="signup-communication-preferences-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showPreviousButton
                showNextButton
                onPreviousClick={onPreviousSlideClick}
                onNextClick={handleOnSubmit}
                isSubmitting={isSubmitting}
                nextButtonId="st-communication-slide-next-button"
            />
        </SignupSlide>
    );
};

export default SignupCommunicationPreferencesSlide;
