enum TrackingEventName {
    ActivityCompleted = "Activity Completed",
    ActivityStarted = "Activity Started",
    ApplicationStarted = "Application Started",
    ButtonClicked = "Button Clicked",
    FeedbackGiven = "Feedback Given",
    FeedbackShown = "Feedback Shown",
    LinkClicked = "Link Clicked",
    QuestionAnswered = "Question Answered",
    PageViewed = "Page Viewed",
    Redirected = "Redirected",
    SurveyTipDismissed = "Survey Tip Dismissed",
    SurveyTipShown = "Survey Tip Shown"
}

export default TrackingEventName;
