import QuestionDisplayType from "./QuestionDisplayType";

type PulseSurveyQuestionDisplayType = QuestionDisplayType.MultipleChoice | QuestionDisplayType.Slider | QuestionDisplayType.TwoWaySlider | QuestionDisplayType.Stars | QuestionDisplayType.LikertScale | QuestionDisplayType.Custom;

const PulseSurveyQuestionDisplayType = {
    [QuestionDisplayType.MultipleChoice]: QuestionDisplayType.MultipleChoice,
    [QuestionDisplayType.Slider]: QuestionDisplayType.Slider,
    [QuestionDisplayType.TwoWaySlider]: QuestionDisplayType.TwoWaySlider,
    [QuestionDisplayType.Stars]: QuestionDisplayType.Stars,
    [QuestionDisplayType.LikertScale]: QuestionDisplayType.LikertScale,
    [QuestionDisplayType.Custom]: QuestionDisplayType.Custom
} as const;

export default PulseSurveyQuestionDisplayType;