import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { getSourceUrlAsync } from "@api/ShortenedUrlRedirectApiClient";

import LoadingPage from "@pages/loading/LoadingPage";
import NotFoundPage from "@pages/not-found/NotFoundPage";

const UrlShortenerRedirectPage = () => {
    const [error, setError] = useState(false);
    const { urlShortenerId } = useParams();

    useEffect(() => {
        const fetchAsync = async() => {
            try {
                if (!urlShortenerId) {
                    throw new Error("Missing URL shortener ID");
                }

                const sourceUrl = await getSourceUrlAsync(urlShortenerId);

                if (sourceUrl) {
                    window.location.replace(sourceUrl);
                } else {
                    setError(true);
                }
            } catch (_) {
                setError(true);
            }
        };

        fetchAsync();
    }, [urlShortenerId]);

    if (error) {
        return <NotFoundPage />;
    }

    return (
        <LoadingPage />
    );
};

export default UrlShortenerRedirectPage;