import type { OrchestratedActivity, Step } from "@/types/activities/common";

import ActivityBackgroundTheme from "@core/enums/ActivityBackgroundTheme";
import ActivityBackgroundType from "@core/enums/ActivityBackgroundType";
import ActivityType from "@core/enums/ActivityType";
import OutroType from "@core/enums/OutroType";
import RecognitionStepType from "@core/enums/RecognitionStepType";

function getRecognitionBackground(
    step: Step,
    isCompleted: boolean,
    outroType: OutroType | null,
    isWorkleapBrandEnabled: boolean
) {
    switch (step.stepType) {
        case RecognitionStepType.Intro:
            return {
                type: ActivityBackgroundType.None,
                theme: ActivityBackgroundTheme.Light
            };

        case RecognitionStepType.SelectPrompt:
        case RecognitionStepType.CustomizeMessage: {
            const shouldHideBackground =
                isWorkleapBrandEnabled &&
                isCompleted &&
                (outroType === OutroType.RecognitionSentRedirect ||
                    outroType === OutroType.RecognitionSkippedRedirect);

            return {
                type: ActivityBackgroundType.Illustrated,
                theme: ActivityBackgroundTheme.Dark,
                className: shouldHideBackground
                    ? "illustrated-background__hidden"
                    : ""
            };
        }

        default:
            throw new Error(`Step Type (${step.stepType}) not supported`);
    }
}

export default function getBackgroundState(
    activity: OrchestratedActivity | null,
    isCompleted: boolean,
    outroType: OutroType | null,
    isWorkleapBrandEnabled: boolean
): { type: ActivityBackgroundType; theme: ActivityBackgroundTheme; className?: string } {
    if (!activity) {
        return {
            type: ActivityBackgroundType.None,
            theme: ActivityBackgroundTheme.Light
        };
    }

    const { activityType } = activity;

    switch (activityType) {
        case ActivityType.PulseSurvey:
        case ActivityType.ContinuePulseSurvey:
        case ActivityType.CustomPoll:
        case ActivityType.SmartQuestion:
        case ActivityType.PreviewCustomPoll:
        case ActivityType.TestCustomPoll:
        case ActivityType.TryPulseSurvey:
        case ActivityType.TestPulseSurveyQuestion:
        case ActivityType.TestSmartQuestion:
        case ActivityType.OnboardingSurvey:
        case ActivityType.TestOnboardingSurvey:
        case ActivityType.ContinueOnboardingSurvey:
        case ActivityType.DeibSurvey:
        case ActivityType.TestDeibSurvey:
            return {
                type: ActivityBackgroundType.None,
                theme: ActivityBackgroundTheme.Light
            };

        case ActivityType.Recognition:
        case ActivityType.TestRecognition:
            return getRecognitionBackground(
                activity.steps[activity.currentStepIndex],
                isCompleted,
                outroType,
                isWorkleapBrandEnabled
            );

        default:
            throw new Error(
                `Activity Type (${activityType}) not supported`
            );
    }
}
