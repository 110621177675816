import StepType from "./StepType";

type RecognitionStepType =
    | StepType.Intro
    | StepType.SelectPrompt
    | StepType.CustomizeMessage;

const RecognitionStepType = {
    Intro: StepType.Intro,
    SelectPrompt: StepType.SelectPrompt,
    CustomizeMessage: StepType.CustomizeMessage
} as const;

export default RecognitionStepType;
