import React, { useEffect, useRef, useState } from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import ThirdPartySlider from "rc-slider";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import KeyCodes from "@core/enums/KeyCodes";
import sleep from "@core/utils/sleep";

import ClickToConfirmSliderHandle from "./components/ClickToConfirmSliderHandle";
import SliderHandle from "./components/SliderHandle";
import SliderHandleWrapper from "./components/SliderHandleWrapper";

import { ReactComponent as IconArrowLeft } from "@svg/icons/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "@svg/icons/icon-arrow-right.svg";

import "./slider-question.scss";

interface Props {
    className?: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onChange: (value: number) => void;
    onAnimationCompleted: () => void;
}

const SliderQuestion = ({ className, initialValue, minLabel, maxLabel, onChange, onAnimationCompleted }: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    const [confirmed, setConfirmed] = useState(false);
    const [value, setValue] = useState(initialValue ?? 0);
    const [hasUserInteracted, setHasUserInteracted] = useState(initialValue !== null);
    const [isDragging, setIsDragging] = useState(false);
    const hasValueChangedSinceStart = useRef<boolean>();
    const mobileSliderRef = useRef<HTMLDivElement>(null);
    const mobileInputRefs = Array.from({ length: 11 }, () => useRef<HTMLInputElement>(null)); // eslint-disable-line react-hooks/rules-of-hooks
    const isUserSelectingValue = !hasUserInteracted || (isDragging && hasValueChangedSinceStart.current);

    const mobileSliderScrollTo = (index: number) => {
        const item = mobileInputRefs[index].current;

        if (item && mobileSliderRef.current) {
            const scrollX = item.offsetWidth * index;
            mobileSliderRef.current.scrollTo(scrollX, 0);
        }
    };

    useEffect(() => {
        mobileSliderScrollTo(initialValue ?? 5);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnConfirm = async(newValue: number) => {
        if (confirmed) {
            return;
        }

        setValue(newValue);
        setHasUserInteracted(true);
        setConfirmed(true);

        onChange(newValue);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleOnDragStart = () => {
        setIsDragging(true);
        hasValueChangedSinceStart.current = false;
    };

    const handleOnChange = (newValue: number) => {
        setValue(newValue);
        setHasUserInteracted(true);
        hasValueChangedSinceStart.current = true;
    };

    const handleOnIncrement = () => {
        handleOnChange(Math.min(value + 1, 10));
    };

    const handleOnDecrement = () => {
        handleOnChange(Math.max(value - 1, 0));
    };

    const handleOnDragEnd = () => {
        setIsDragging(false);
        setHasUserInteracted(true);
        setConfirmed(true);

        handleOnConfirm(value);
    };

    const handleOnChangeMobile = (newValue: number) => {
        if (confirmed) {
            return;
        }

        if (value === newValue && hasUserInteracted) {
            handleOnConfirm(newValue);
        } else {
            setValue(newValue);
            setHasUserInteracted(true);
        }
    };

    const handleOnFocusMobile = (currentValue: number) => {
        setHasUserInteracted(true);
        mobileSliderScrollTo(currentValue);
    };

    const handleMobileConfirmOnEnter = (newValue: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === KeyCodes.Enter) {
            e.currentTarget.blur();

            handleOnConfirm(newValue);
        }
    };

    const renderMobileChoice = (i: number) => {
        const currentValue = i;

        return (
            <div key={`slider-choice-${currentValue}`} ref={mobileInputRefs[i]} className="slider-question__mobile-choice" role="radio" aria-checked={value === currentValue}>
                <input id={`slider-choice-${currentValue}`}
                    className="slider-question__mobile-radio-input"
                    type="radio"
                    name="slider"
                    value={currentValue}
                    checked={currentValue === value}
                    onChange={() => handleOnChangeMobile(currentValue)}
                    onFocus={() => handleOnFocusMobile(currentValue)}
                    onKeyDown={e => handleMobileConfirmOnEnter(currentValue, e)}
                    disabled={confirmed}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                <label className="slider-question__mobile-number" htmlFor={`slider-choice-${currentValue}`} onClick={() => handleOnChangeMobile(currentValue)}>{i}</label>
                {!isWorkleapBrandEnabled && currentValue === value && !confirmed && hasUserInteracted && <div className="slider-question__mobile-click-to-confirm-label">{t("pulseSurveyFrame.question-click-to-confirm-label")}</div>}
                {currentValue === value && confirmed && <div className="slider-question__mobile-confirmed-label">{t("pulseSurveyFrame.question-confirmed-label")}</div>}
                {i === 0 && <div className="slider-question__mobile-min-label">{minLabel}</div>}
                {i === 10 && <div className="slider-question__mobile-max-label">{maxLabel}</div>}
            </div>
        );
    };

    const classes = classNames(
        "slider-question",
        className, {
            "slider-question--interacted": hasUserInteracted,
            "slider-question--confirmed": confirmed,
            [`slider-question--${value}`]: hasUserInteracted
        }
    );

    return (
        <div className={classes}>
            {!isWorkleapBrandEnabled &&
                <div className="slider-question__mobile-scroll-label">
                    <IconArrowLeft className="slider-question__drag-arrow-left" />
                    {t("pulseSurveyFrame.scroll-instruction")}
                    <IconArrowRight className="slider-question__drag-arrow-right" />
                </div>
            }
            <div className="slider-question__drag-label">
                {t("pulseSurveyFrame.drag-instruction")}
                {isWorkleapBrandEnabled
                    ? <ArrowRightIcon className="slider-question__drag-arrow-right" size="md" />
                    : <IconArrowRight className="slider-question__drag-arrow-right" />}
            </div>
            <div ref={mobileSliderRef} className="slider-question__mobile-slider">
                <div className="slider-question__mobile-choices-container">
                    {Array.from({ length: 11 }, (_, i) => renderMobileChoice(i))}
                </div>
            </div>
            {isWorkleapBrandEnabled &&
                <div className="slider-question__mobile-scroll-instructions-section">
                    <div className="slider-question__mobile-scroll-instructions">
                        <ArrowLeftIcon className="slider-question__drag-arrow-left" size="md" />
                        {t("pulseSurveyFrame.scroll-instruction")}
                        <ArrowRightIcon className="slider-question__drag-arrow-right" size="md" />
                    </div>
                    <div className="slider-question__mobile-click-to-confirm-instructions">{t("pulseSurveyFrame.question-click-to-confirm-label")}</div>
                </div>
            }
            <div className="slider-question__slider">
                <button type="submit" className="slider-question__left-cap" onClick={() => handleOnConfirm(0)} />
                <div className="slider-question__track">
                    <ThirdPartySlider className="slider-question__input"
                        min={0}
                        max={10}
                        marks={{
                            0: { label: "0" },
                            1: { label: "1" },
                            2: { label: "2" },
                            3: { label: "3" },
                            4: { label: "4" },
                            5: { label: "5" },
                            6: { label: "6" },
                            7: { label: "7" },
                            8: { label: "8" },
                            9: { label: "9" },
                            10: { label: "10" }
                        }}
                        value={value}
                        handle={({ ref, ...props }) => (
                            <SliderHandleWrapper {...props}
                                className={classNames(
                                    props.className,
                                    "slider-question__handle-wrapper",
                                    {
                                        "slider-question__handle-wrapper--slider": isUserSelectingValue,
                                        "slider-question__handle-wrapper--slider-dragging": isUserSelectingValue && isDragging
                                    }
                                )}
                                ref={ref}
                                left={`${value * 10}%`}
                                onDragStart={handleOnDragStart}
                                onEnter={() => handleOnConfirm(value)}
                                onIncrement={handleOnIncrement}
                                onDecrement={handleOnDecrement}
                                disabled={confirmed}
                            >
                                {isUserSelectingValue
                                    ? <SliderHandle className="slider-question__handle" />
                                    : <ClickToConfirmSliderHandle className="slider-question__handle" confirmed={confirmed} />
                                }
                            </SliderHandleWrapper>
                        )}
                        startPoint={0}
                        onBeforeChange={handleOnDragStart}
                        onChange={handleOnChange}
                        onAfterChange={handleOnDragEnd}
                        disabled={confirmed}
                    />
                </div>
                <button type="submit" className="slider-question__right-cap" onClick={() => handleOnConfirm(10)} />
            </div>
            <div className="slider-question__labels">
                <div className="slider-question__min-label">
                    {minLabel}
                </div>
                <div className="slider-question__max-label">
                    {maxLabel}
                </div>
            </div>
        </div>
    );
};

export default SliderQuestion;