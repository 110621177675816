import type { TFunction } from "react-i18next";

import type { PulseSurveyActivityResponse } from "@api/PulseSurveyActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

import buildPulseSurveyActivity from "./buildPulseSurveyActivity";
import type { PulseSurveyActivityCustomQuestionOptions } from "./types";

const buildContinuePulseSurveyActivity = (
    activity: PulseSurveyActivityResponse,
    firstQuestionAnswerScore: number,
    firstQuestionAnswerValue: number,
    pulseSurveyActivityCustomQuestionOptions: PulseSurveyActivityCustomQuestionOptions,
    t: TFunction<"activities">
) => {
    if (activity.questionIds.length === 0) {
        return null;
    }

    const builtActivity = buildPulseSurveyActivity(
        activity,
        pulseSurveyActivityCustomQuestionOptions,
        t
    )!;

    return {
        ...builtActivity,
        activityType: ActivityType.ContinuePulseSurvey as const,
        steps: [
            {
                stepType: PulseSurveyStepType.Intro
            },
            ...(builtActivity?.steps ?? []).map((step, index) => {
                if (index === 0) {
                    return {
                        ...step,
                        answer: firstQuestionAnswerValue,
                        score: firstQuestionAnswerScore,
                        isAnswered: true
                    };
                }

                return step;
            })
        ]
    };
};

export default buildContinuePulseSurveyActivity;
