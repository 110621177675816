import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";
import useKeyboard, { Handled } from "@hooks/useKeyboard";

import PrimaryButton from "@components/button/PrimaryButton";

import KeyCode from "@core/enums/KeyCodes";

import SignupSlide from "./SignupSlide";

import { ReactComponent as IllustrationWorkleapBrand } from "@svg/illustrations/illustration-feedback-explanation.svg";
import { ReactComponent as IllustrationOVBrand } from "@svg/illustrations/legacy/illustration-feedback-explanation.svg";

import "./signup-pulse-survey-feedback-explanation-slide.scss";

interface Props {
    className?: string;
    slideIndex: number;
    totalSlides: number;
    onPreviousSlideClick: () => void;
    onSubmit: () => Promise<void>;
}

const SignupPulseSurveyFeedbackExplanationSlide = ({
    className,
    slideIndex,
    totalSlides,
    onPreviousSlideClick,
    onSubmit
}: Props) => {
    const { t } = useTranslation("activities");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );

    const handleOnSubmit = async() => {
        try {
            setIsSubmitting(true);
            await onSubmit();
        } catch (ex) {
            setIsSubmitting(false);

            throw ex;
        }
    };

    useKeyboard({
        [KeyCode.Enter]: () => {
            handleOnSubmit();

            return Handled;
        }
    });

    const classes = classNames(
        "signup-pulse-survey-feedback-explanation-slide",
        className
    );

    return (
        <SignupSlide className={classes}>
            <SignupSlide.ImageHeader
                className="signup-pulse-survey-feedback-explanation-slide__header"
                image={
                    isWorkleapBrandEnabled ? (
                        <IllustrationWorkleapBrand />
                    ) : (
                        <IllustrationOVBrand />
                    )
                }
                theme={SignupSlide.ImageHeader.Theme.DarkCoral}
            />
            <SignupSlide.Explanation
                className="signup-pulse-survey-feedback-explanation-slide__body"
                title={t("onboarding.pulseSurveyFeedbackSlideTitle")}
                description={t(
                    "onboarding.pulseSurveyFeedbackSlideDescription"
                )}
            >
                <PrimaryButton
                    size={PrimaryButton.Size.Small}
                    loading={isSubmitting}
                    onClick={handleOnSubmit}
                >
                    {t("onboarding.startSurveyButtonLabel")}
                </PrimaryButton>
            </SignupSlide.Explanation>
            <SignupSlide.Footer
                className="signup-pulse-survey-feedback-explanation-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showPreviousButton
                onPreviousClick={onPreviousSlideClick}
            />
        </SignupSlide>
    );
};

export default SignupPulseSurveyFeedbackExplanationSlide;
