import React from "react";

import classNames from "classnames";

import "./slider-handle.scss";

interface Props {
    className?: string;
}

const SliderHandle = ({ className }: Props) => {
    const classes = classNames(
        "slider-handle",
        className
    );

    return (
        <div className={classes}>
            <div className="slider-handle__bar-1" />
            <div className="slider-handle__bar-2" />
            <div className="slider-handle__bar-3" />
        </div>
    );
};

export default SliderHandle;