import React from "react";

import Tag from "@components/tag/Tag";

import type RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

interface Props extends React.ComponentProps<typeof Tag> {
    categoryName: string;
    categoryTheme: RecognitionCategoryTheme;
}

const RecognitionCategoryTag = ({
    categoryName,
    categoryTheme,
    ...otherProps
}: Props) => (
    <Tag {...otherProps} theme={categoryTheme}>
        {categoryName}
    </Tag>
);

export default RecognitionCategoryTag;
