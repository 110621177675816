import {
    buildApiClient,
    buildLoginClient
} from "@core/api-client/RetryableBearerApiClient";
import type DeliveryChannel from "@core/enums/DeliveryChannel";
import type SupportedDashboardLanguage from "@core/enums/SupportedDashboardLanguage";
import type SupportedSurveyLanguage from "@core/enums/SupportedSurveyLanguage";

export interface OnboardingContext {
    firstName: string;
    lastName: string;
    surveyLanguage: SupportedSurveyLanguage;
    dashboardLanguage: SupportedDashboardLanguage;
    hasAccessToManagerDashboard: boolean;
    isPasswordLoginDisabledForNetwork: boolean;
    isMemberProfileEditingDisabled: boolean;
    deliveryChannel: DeliveryChannel;
    defaultDeliveryChannel: DeliveryChannel;
    workEmail: string;
    personalEmail: string | null;
    mobilePhone: string | null;
    isPulseSurveyDisabled: boolean;
}

export async function getActivityAsync(): Promise<OnboardingContext> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync(
        "/api/v1/onboarding"
    );
}

interface OnboardingControllerSaveUserProfileRequest {
    surveyLanguage: string;
    dashboardLanguage: string;
    firstName: string;
    lastName: string;
}

export async function saveProfileAsync(
    surveyLanguage: string,
    dashboardLanguage: string,
    firstName: string,
    lastName: string
): Promise<void> {
    const data: OnboardingControllerSaveUserProfileRequest = {
        surveyLanguage,
        dashboardLanguage,
        firstName,
        lastName
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        "/api/v1/onboarding/profile",
        data
    );
}

export type OnboardingControllerSaveUserCommunicationPreferencesRequest =
    | {
        deliveryChannel: DeliveryChannel.PersonalEmail;
        personalEmail: string;
        mobilePhone: string | null;
    }
    | {
        deliveryChannel: DeliveryChannel.Sms;
        mobilePhone: string;
        personalEmail: string | null;
    }
    | {
        deliveryChannel:
        | DeliveryChannel.MsTeams
        | DeliveryChannel.Slack
        | DeliveryChannel.Undefined
        | DeliveryChannel.WorkEmail;
        personalEmail: string | null;
        mobilePhone: string | null;
    };

export async function saveCommunicationPreferencesAsync(
    data: OnboardingControllerSaveUserCommunicationPreferencesRequest
): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        "/api/v1/onboarding/communication-preferences",
        data
    );
}

export type OnboardingControllerSaveMemberOnboardingRequest = {
    surveyLanguage: SupportedSurveyLanguage;
} & (
    | {
        deliveryChannel: DeliveryChannel.PersonalEmail;
        personalEmail: string;
        mobilePhone: string | null;
    }
    | {
        deliveryChannel: DeliveryChannel.Sms;
        mobilePhone: string;
        personalEmail: string | null;
    }
    | {
        deliveryChannel:
        | DeliveryChannel.MsTeams
        | DeliveryChannel.Slack
        | DeliveryChannel.Undefined
        | DeliveryChannel.WorkEmail;
        personalEmail: string | null;
        mobilePhone: string | null;
    }
);

export async function saveMemberOnboardingAsync(data: OnboardingControllerSaveMemberOnboardingRequest): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync("/api/v1/onboarding/member-onboarding", data);
}

interface OnboardingControllerSaveUserPasswordRequest {
    password: string;
}

export async function savePasswordAsync(password: string): Promise<void> {
    const data: OnboardingControllerSaveUserPasswordRequest = {
        password
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        "/api/v1/onboarding/password",
        data
    );
}

export async function activityEndedAsync(): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(
        "/api/v1/onboarding/ended"
    );
}

interface LoginRequest {
    userName: string;
    password: string;
}

export async function loginAsync(
    userName: string,
    password: string
): Promise<void> {
    const data: LoginRequest = {
        userName,
        password
    };

    return await buildLoginClient(window.env.ACTIVITIES_API_URL).postLoginAsync(
        "/api/v1/onboarding/login",
        data
    );
}
