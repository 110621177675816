import React from "react";

import type Feature from "@core/features/feature";

interface Context {
    isInitialized: boolean;
    isLoading: boolean;
    features: Feature[];
    initializeFeaturesAsync: () => Promise<void>;
}

const FeaturesContext = React.createContext<Context | null>(null);

export default FeaturesContext;

export const useFeaturesContext = () => {
    const context = React.useContext(FeaturesContext);

    if (!context) {
        throw new Error("FeaturesContext not found; are you missing FeaturesContextProvider?");
    }

    return context;
};