import type { DeibSurveyQuestionStep } from "@/types/activities/deib-survey";
import type { TFunction } from "react-i18next";

import DisplayType from "@core/enums/DeibSurveyQuestionDisplayType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

import mapQuestionIdToDisplayType from "./mapQuestionIdToDisplayType";
import type { DeibQuestionID } from "./types";

const buildDeibSurveyQuestion = (
    questionId: DeibQuestionID,
    t: TFunction<"activities">
): DeibSurveyQuestionStep => {
    const utils = {
        getQuestion: (id: string) => t(`deibQuestions.${id.toLowerCase()}`),
        getMinLabel: (id: string) =>
            t(`deibQuestions.${id.toLowerCase()}-min-label`),
        getMaxLabel: (id: string) =>
            t(`deibQuestions.${id.toLowerCase()}-max-label`),
        getImageUrls: (id: string, indexes: number[]) =>
            indexes.map(
                index =>
                    `${window.env.CDN_URL}/activities/deib-survey/images/${id}-${index}.webp`
            ),
        getLabels: (id: string, indexes: number[]) =>
            indexes.map(index =>
                t(`deibQuestions.${id.toLowerCase()}-choice-label-${index}`)
            ),
        getLikertLabels: () => [
            t("deibQuestions.likert-scale-strongly-disagree"),
            t("deibQuestions.likert-scale-disagree"),
            t("deibQuestions.likert-scale-neutral"),
            t("deibQuestions.likert-scale-agree"),
            t("deibQuestions.likert-scale-strongly-agree")
        ]
    };

    const displayType = mapQuestionIdToDisplayType(questionId);

    const commonData = {
        questionId,
        question: utils.getQuestion(questionId),
        displayType,
        stepType: PulseSurveyStepType.AskQuestion
    };

    switch (displayType) {
        case DisplayType.Slider:
            return {
                ...commonData,
                displayType: DisplayType.Slider,
                minLabel: utils.getMinLabel(questionId),
                maxLabel: utils.getMaxLabel(questionId)
            };
        case DisplayType.LikertScale:
            return {
                ...commonData,
                displayType: DisplayType.LikertScale,
                labels: utils.getLikertLabels()
            };
        case DisplayType.MultipleChoice:
            return {
                ...commonData,
                displayType: DisplayType.MultipleChoice,
                labels: utils.getLabels(questionId, [1, 2, 3, 4]),
                imageUrls: utils.getImageUrls(questionId, [1, 2, 3, 4]),
                customAnswer: null
            };
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

export default buildDeibSurveyQuestion;
