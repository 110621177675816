import React, { Fragment, useEffect, useRef } from "react";

import { CheckmarkIcon } from "@hopper-ui/icons";
import classNames from "classnames";

import type InputType from "@core/enums/InputType";

import "./multiple-choice-question-choice.scss";

interface Props {
    inputType: InputType;
    value: number;
    label: string;
    isConfirmed: boolean;
    isSelected: boolean;
    isFocused: boolean;
    checked: boolean;
    onFocus: (isFocused: boolean) => void;
    onBlur: () => void;
    onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
    onClick: React.MouseEventHandler<HTMLLabelElement>;
}

const MultipleChoiceQuestionChoice = ({
    inputType,
    value,
    label,
    isConfirmed,
    isSelected,
    isFocused,
    checked,
    onFocus,
    onBlur,
    onKeyDown,
    onClick
}: Props) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isFocused && ref.current) {
            ref.current.focus();
        }
    }, [isFocused]);

    const choiceClasses = classNames(
        "multiple-choice-question-choice__choice-label",
        {
            "multiple-choice-question-choice__choice-label--selected": isSelected,
            "multiple-choice-question-choice__choice-label--highlighted":
        !isConfirmed && isFocused,
            "multiple-choice-question-choice__choice-label--confirmed": isConfirmed
        }
    );

    return (
        <Fragment>
            <input
                ref={ref}
                id="multiple-choice-choice-custom"
                className="multiple-choice-question-choice__radio-input"
                type={inputType}
                name="multiple-choice"
                checked={checked}
                value={value}
                onChange={() => onFocus(true)}
                disabled={isConfirmed}
                onFocus={() => onFocus(true)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <label
                className={choiceClasses}
                htmlFor="multiple-choice-choice-custom"
                aria-label={label}
                onClick={onClick}
            >
                <div className="multiple-choice-question-choice__label-container">
                    <div className="multiple-choice-question-choice__label">{label}</div>
                    <CheckmarkIcon
                        className="multiple-choice-question-choice__selected-icon"
                        size="md"
                        aria-hidden="true"
                    />
                </div>
            </label>
        </Fragment>
    );
};

export default MultipleChoiceQuestionChoice;
