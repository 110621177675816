import React from "react";

import classNames from "classnames";

import CustomPollQuestionTitle from "./CustomPollQuestionTitle";

import "./base-custom-poll-question.scss";

interface Props {
    className?: string;
    question: string;
    children: React.ReactNode;
}

const BaseCustomPollQuestion = ({ className, question, children }: Props) => {
    const classes = classNames(
        "base-custom-poll-question",
        className
    );

    return (
        <div className={classes}>
            <CustomPollQuestionTitle className="base-custom-poll-question__header">
                {question}
            </CustomPollQuestionTitle>
            {children}
        </div>
    );
};

export default BaseCustomPollQuestion;