import { type RefObject, useEffect } from "react";

const isElement = (target: EventTarget) => target instanceof Element;
const isSvgDocument = (target: EventTarget) => isElement(target) && target.localName === "svg";
const isSvgElement = (target: EventTarget) => target instanceof SVGGElement && !!target.ownerSVGElement;
const isSvg = (target: EventTarget | null) => !!target && (isSvgDocument(target) || isSvgElement(target));

export default function useClickOutside(
    refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
    callback: (e: MouseEvent) => void,
    listenToMouseUpEvent = true
) {
    const handleClickOutside = (event: MouseEvent) => {
        if (event.button !== 0) {
            return;
        }

        const refTargets = Array.isArray(refs)
            ? refs
            : [refs];
        const clickTarget = isSvg(event.target)
            ? event.composedPath().find(x => isElement(x) && x.localName === "div")
            : event.target;

        const hasClickedOutsideAllRefs = refTargets.every(ref => !ref.current || !ref.current.contains(clickTarget as HTMLElement));

        if (hasClickedOutsideAllRefs) {
            callback(event);
        }
    };

    useEffect(() => {
        const eventType = listenToMouseUpEvent ? "mouseup" : "mousedown";
        document.addEventListener(eventType, handleClickOutside);

        return () => {
            document.removeEventListener(eventType, handleClickOutside);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
}