import { useLocation } from "react-router-dom";

import useDelayedEffect from "@hooks/useDelayedEffect";

import { useAuthenticationContext } from "@contexts/authentication/AuthenticationContext";

import { trackPageViewed } from "@core/tracking/track";

const PageViewTracking = () => {
    const { isAuthenticated } = useAuthenticationContext();
    const { pathname, search } = useLocation();

    useDelayedEffect(() => {
        if (isAuthenticated) {
            trackPageViewed();
        }
    }, [isAuthenticated, pathname, search], 1000);

    return null;
};

export default PageViewTracking;