import React from "react";

export interface ResponsiveContextState {
    small: boolean | null;
    medium: boolean | null;
    large: boolean | null;
    extraLarge: boolean | null;
    extraExtraLarge: boolean | null;
}

const ResponsiveContext = React.createContext<ResponsiveContextState | null>(null);

export default ResponsiveContext;

export const useResponsiveContext = () => {
    const context = React.useContext(ResponsiveContext);

    if (!context) {
        throw new Error("ResponsiveContext not found; are you missing ResponsiveContextProvider?");
    }

    return context;
};