import React from "react";

import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import PrimaryButton from "@components/button/PrimaryButton";

import { ReactComponent as Illustration } from "@svg/illustrations/illustration-not-found.svg";
import { ReactComponent as IllustrationLegacy } from "@svg/illustrations/legacy/illustration-not-found.svg";
import { ReactComponent as Logo } from "@svg/logo.svg";

import "./preview-custom-poll-forbidden-page.scss";

const PreviewCustomPollForbiddenPage = () => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);

    const handleOnRedirectToDashboard = () => {
        window.location.href = window.env.APP_DASHBOARD_URL;
    };

    return (
        <div className="preview-custom-poll-forbidden-page">
            <div className="preview-custom-poll-forbidden-page__header">
                <Logo className="preview-custom-poll-forbidden-page__logo" />
            </div>
            <div className="preview-custom-poll-forbidden-page__image-container">
                {isWorkleapBrandEnabled
                    ? <Illustration className="preview-custom-poll-forbidden-page__image" />
                    : <IllustrationLegacy className="preview-custom-poll-forbidden-page__image" />
                }
            </div>
            <div className="preview-custom-poll-forbidden-page__title">
                {t<string>("customPollForbidden.title")}
            </div>
            <div className="preview-custom-poll-forbidden-page__subtitle">
                {t<string>("customPollForbidden.preview-forbidden-description")}
            </div>
            <PrimaryButton
                className="preview-custom-poll-forbidden-page__go-to-officevibe-button"
                onClick={handleOnRedirectToDashboard}
            >
                {t<string>("customPollForbidden.go-to-officevibe")}
            </PrimaryButton>
        </div>
    );
};

export default PreviewCustomPollForbiddenPage;