
import { useFeaturesContext } from "@contexts/features/FeaturesContext";

import Feature from "@core/features/feature";

export default function useIsFeatureEnabled(fn: (features: typeof Feature) => Feature): boolean {
    const { features: enabledFeatures } = useFeaturesContext();
    const feature = fn(Feature);

    return enabledFeatures && enabledFeatures.includes(feature);
}