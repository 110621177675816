import Theme from "@core/enums/Theme";

type TagTheme = Theme.Creamsicle | Theme.Seaweed | Theme.Dandelion | Theme.ElectricBlue | Theme.Sky | Theme.Coral | Theme.StrawberryFields;

const TagTheme = {
    [Theme.Creamsicle]: Theme.Creamsicle,
    [Theme.Seaweed]: Theme.Seaweed,
    [Theme.Dandelion]: Theme.Dandelion,
    [Theme.ElectricBlue]: Theme.ElectricBlue,
    [Theme.Sky]: Theme.Sky,
    [Theme.Coral]: Theme.Coral,
    [Theme.StrawberryFields]: Theme.StrawberryFields
};

export default TagTheme;