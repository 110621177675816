import { useSearchParams } from "react-router-dom";

export default function useRemoveQueryParams() {
    const [searchParams, setSearchParams] = useSearchParams();

    return (paramsToRemove: string[]) => {
        const paramsToKeep = [...searchParams].filter(param => !paramsToRemove.includes(param[0]));

        setSearchParams(paramsToKeep, { replace: true });
    };
}