import React from "react";

import ErrorPage from "@pages/error/ErrorPage";

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

class GeneralErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { hasError: false };
    }

    promiseRejectionHandler() {
        this.setState({ hasError: true });
    }

    componentDidMount() {
        window.addEventListener("unhandledrejection", this.promiseRejectionHandler.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("unhandledrejection", this.promiseRejectionHandler.bind(this));
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        return hasError
            ? <ErrorPage />
            : children;
    }
}

export default GeneralErrorBoundary;