import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";
import lowerCaseFirstLetter from "@core/utils/lowerCaseFirstLetter";

const buildSmartQuestionActivity = (metricId: string | undefined, subMetricId: string, t: TFunction<"activities">) => {
    if (!subMetricId || !metricId) {
        return null;
    }

    return {
        activityType: ActivityType.SmartQuestion as const,
        subMetricId,
        question: t(`smartQuestionsActivities.${lowerCaseFirstLetter(subMetricId)}`),
        metricName: t(`metricNames.${lowerCaseFirstLetter(metricId)}`)
    };
};

export default buildSmartQuestionActivity;