import React from "react";

import type {
    OnboardingContext,
    OnboardingControllerSaveMemberOnboardingRequest,
    OnboardingControllerSaveUserCommunicationPreferencesRequest
} from "@api/SignupApiClient";
import type SupportedDashboardLanguage from "@core/enums/SupportedDashboardLanguage";
import type SupportedSurveyLanguage from "@core/enums/SupportedSurveyLanguage";

interface BaseContext {
    isInitialized: boolean;
    isDesklessUser: boolean;
    initializeSignupAsync: () => Promise<void>;
    setSurveyLanguage: (surveyLanguage: SupportedSurveyLanguage) => void;
    saveProfileAsync: (
        surveyLanguage: SupportedSurveyLanguage,
        dashboardLanguage: SupportedDashboardLanguage,
        firstName: string,
        lastName: string
    ) => Promise<void>;
    saveCommunicationPreferencesAsync: (
        data: OnboardingControllerSaveUserCommunicationPreferencesRequest
    ) => Promise<void>;
    saveMemberOnboardingAsync: (
        data: OnboardingControllerSaveMemberOnboardingRequest
    ) => Promise<void>;
    savePasswordAsync: (password: string) => Promise<void>;
    onSignupActivityEnded: () => void;
    loginAsync: (email: string, password: string) => Promise<void>;
}

type Context = BaseContext &
(
    | { isLoading: true; context: null }
    | { isLoading: false; context: OnboardingContext & { isPasswordSet: boolean } }
);

const SignupContext = React.createContext<Context | null>(null);

export default SignupContext;

export const useSignupContext = () => {
    const context = React.useContext(SignupContext);

    if (!context) {
        throw new Error(
            "SignupContext not found; are you missing SignupContextProvider?"
        );
    }

    return context;
};
