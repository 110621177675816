import React, { useEffect } from "react";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";
import { useTipsContext } from "@contexts/tips/TipsContext";

import ActivitiesPage from "./components/ActivitiesPage";

const CompletePulseSurveyPage = () => {
    const { initializeCompleteSurveyAsync } = useActivitiesContext();
    const { initializeTipsContextAsync } = useTipsContext();
    const isPulseSurveyCustomTextualQuestionsEnabled = useIsFeatureEnabled(feature => feature.pulseSurveyCustomTextualQuestions);

    useEffect(() => {
        initializeCompleteSurveyAsync(isPulseSurveyCustomTextualQuestionsEnabled);
        initializeTipsContextAsync();
    }, [isPulseSurveyCustomTextualQuestionsEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default CompletePulseSurveyPage;