import React from "react";

import classNames from "classnames";
import kebabCase from "lodash/kebabCase";

import SecondaryButton from "@components/button/SecondaryButton";
import Tag from "@components/tag/Tag";

import KeyCode from "@core/enums/KeyCodes";
import type RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

import "./recognition-prompt.scss";

interface Props {
    className?: string;
    theme: RecognitionCategoryTheme;
    prompt: string;
    category: string;
    buttonLabel: string;
    onClick?: () => void;
    disabled?: boolean;
}

const RecognitionPrompt = ({ className, theme, prompt, category, buttonLabel, onClick, disabled = false }: Props) => {
    const handleOnClick: React.MouseEventHandler = e => {
        if (!disabled && onClick) {
            e.stopPropagation();
            e.preventDefault();
            onClick();
        }
    };

    const handleOnKeyUp: React.KeyboardEventHandler<HTMLDivElement> = e => {
        if (e.keyCode === KeyCode.Enter || e.keyCode === KeyCode.Space) {
            if (!disabled && onClick) {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }
        }
    };

    const classes = classNames(
        "recognition-prompt",
        `recognition-prompt--${kebabCase(theme)}`,
        className, {
            "recognition-prompt--interactible": !disabled,
            "recognition-prompt--disabled": disabled
        }
    );

    return (
        <div className={classes} onClick={handleOnClick} tabIndex={disabled ? -1 : 0} role="button" aria-label={prompt} onKeyUp={handleOnKeyUp}>
            <div className="recognition-prompt__content">
                <div className="recognition-prompt__prompt" title={prompt}>
                    {prompt}
                </div>
                <div className="recognition-prompt__footer">
                    <SecondaryButton className="recognition-prompt__button" onClick={handleOnClick} tabIndex={-1}>
                        {buttonLabel}
                    </SecondaryButton>
                    <Tag className="recognition-prompt__category" theme={theme} isCompact>
                        {category}
                    </Tag>
                </div>
            </div>
        </div>
    );
};

export default RecognitionPrompt;