export {};

declare global {
    interface Array<T> {
        groupBy: <K>(groupByFunction: (item: T, index: number) => K) => Map<K, T>;
        randomize: () => T[];
    }
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "groupBy", {
    value<K, T>(this: T[], groupByFunction: (item: T, index: number) => K) {
        const result = new Map<K, T[]>();

        this.forEach((item, index) => {
            const key = groupByFunction(item, index);
            const collection = result.get(key);

            if (!collection) {
                result.set(key, [item]);
            } else {
                collection.push(item);
            }
        });

        return result;
    },
    writable: false,
    configurable: false
});

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "randomize", {
    value<T>(this: T[]) {
        const result = [...this];

        for (let i = result.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = result[i];
            result[i] = result[j];
            result[j] = temp;
        }

        return result;
    },
    writable: false,
    configurable: false
});