import type { BuiltDeibSurveyActivity } from "@/types/activities/deib-survey";
import upperFirst from "lodash/upperFirst";
import type { TFunction } from "react-i18next";

import type { DeibSurveyActivityResponse } from "@api/DeibSurveyActivityClient";

import ActivityType from "../../enums/ActivityType";
import DeibSurveyStepType from "../../enums/DeibSurveyStepType";
import buildDeibSurveyQuestionStep from "./buildDeibSurveyQuestionStep";

const buildDeibSurveyQuestions = (activity: DeibSurveyActivityResponse, t: TFunction<"activities">) => {
    const replaceTag = (text: string) => text.replaceAll(`[${activity.customizationWord.sourceWord}]`, activity.customizationWord.replacementWord);

    return activity.questionIds
        .map(questionId => buildDeibSurveyQuestionStep(questionId, t))
        .map(q => ({
            ...q,
            question: upperFirst(replaceTag(q.question))
        }));
};

const buildDeibSurveyActivity = (activity: DeibSurveyActivityResponse, t: TFunction<"activities">): BuiltDeibSurveyActivity => {
    const questions = buildDeibSurveyQuestions(activity, t);

    return {
        ...activity,
        activityType: ActivityType.DeibSurvey as const,
        steps: [
            { stepType: DeibSurveyStepType.Intro },
            ...questions
        ]
    } ;
};

export default buildDeibSurveyActivity;