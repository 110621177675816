import type { TFunction } from "react-i18next";

import type { OnboardingSurveyActivityResponse } from "@api/OnboardingSurveyActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";

import buildOnboardingSurveyActivity from "./buildOnboardingSurveyActivity";

const buildContinueOnboardingSurveyActivity = (
    activity: OnboardingSurveyActivityResponse,
    firstQuestionAnswerScore: number,
    firstQuestionAnswerValue: number,
    t: TFunction<"activities">
) => {
    const builtActivity = buildOnboardingSurveyActivity(
        activity,
        t
    );

    return {
        ...builtActivity,
        activityType: ActivityType.ContinueOnboardingSurvey as const,
        steps: [
            {
                stepType: OnboardingSurveyStepType.Intro
            },
            ...builtActivity.steps.map((step, index) => {
                if (index === 0) {
                    return {
                        ...step,
                        answer: firstQuestionAnswerValue,
                        score: firstQuestionAnswerScore,
                        isAnswered: true
                    };
                }

                return step;
            })
        ]
    };
};

export default buildContinueOnboardingSurveyActivity;
