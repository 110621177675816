import { useEffect } from "react";

const setShowTranslationKeysShortcut = () => {
    window.addEventListener("keydown", e => {
        const macShortcut = e.ctrlKey && e.metaKey && e.key === "t";
        const pcShortcut = e.ctrlKey && e.altKey && e.key === "t";

        if (macShortcut || pcShortcut) {
            const showTranslationKeys = sessionStorage.getItem("showTranslationKeys") === "true";
            sessionStorage.setItem("showTranslationKeys", `${!showTranslationKeys}`);
            window.location.reload();
        }
    });
};

export default function useShortcuts() {
    useEffect(() => {
        if (window.env.TRANSLATION.SHOW_TRANSLATION_KEYS_SHORTCUT) {
            setShowTranslationKeysShortcut();
        }
    }, []);
}