import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import InlineButton from "@components/button/InlineButton";
import InteractionBox from "@components/interaction-box/InteractionBox";
import QuestionAskedBy from "@components/question-asked-by/QuestionAskedBy";

import { ReactComponent as OvAvatar } from "@svg/ov-avatar.svg";

import "./pulse-survey-follow-up-question.scss";

interface Props {
    className?: string;
    question: string;
    initialFeedback: string | null;
    initialIsAnonymous?: boolean;
    isAnswered: boolean;
    onChange: (feedback: string, isAnonymous: boolean) => void;
    onChangeAnonymity?: (isAnonymous: boolean) => void;
    onGoBack: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyFollowUpQuestion = ({
    className,
    question,
    initialFeedback,
    initialIsAnonymous,
    isAnswered,
    onChange,
    onChangeAnonymity,
    onGoBack,
    onAnimationCompleted
}: Props) => {
    const { t } = useTranslation("activities");
    const { imageUrl, fullName } = useAuthenticatedUser();
    const [isAnsweredSnapshot] = useState(isAnswered);

    const [confirmed, setConfirmed] = useState(false);

    const handleOnChangeAnonymity = (isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        if (onChangeAnonymity) {
            onChangeAnonymity(isAnonymous);
        }
    };

    const handleOnConfirm = async(feedback: string, isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        setConfirmed(true);

        onChange(feedback, isAnonymous);
        onAnimationCompleted();
    };

    const classes = classNames("pulse-survey-follow-up-question", className, {
        "pulse-survey-follow-up-question--confirmed": confirmed
    });

    return (
        <div className={classes}>
            <div className="pulse-survey-follow-up-question__previous-step">
                <span className="pulse-survey-follow-up-question__previous-step-text">
                    {t("pulseSurveyFollowUpQuestion.title")}
                </span>
                <InlineButton
                    className="pulse-survey-follow-up-question__previous-step-button"
                    onClick={onGoBack}
                >
                    {t("pulseSurveyFollowUpQuestion.edit-answer-button-label")}
                </InlineButton>
            </div>
            <QuestionAskedBy
                className="pulse-survey-follow-up-question__question-asked-by"
                question={question}
                icon={<OvAvatar />}
            />
            <div className="pulse-survey-follow-up-question__input">
                <InteractionBox
                    initialFeedback={initialFeedback}
                    initialIsAnonymous={initialIsAnonymous}
                    authorImageUrl={imageUrl}
                    authorFullName={fullName}
                    placeholder={t(
                        "pulseSurveyFollowUpQuestion.feedback-input-placeholder"
                    )}
                    submitLabel={
                        isAnsweredSnapshot
                            ? t("pulseSurveyFollowUpQuestion.update-feedback-button-label")
                            : t("pulseSurveyFollowUpQuestion.send-feedback-button-label")
                    }
                    onSubmit={handleOnConfirm}
                    onChangeIsAnonymous={handleOnChangeAnonymity}
                    disabled={confirmed}
                    allowEmptyFeedback={isAnsweredSnapshot}
                />
            </div>
        </div>
    );
};

export default PulseSurveyFollowUpQuestion;
