import { useEffect } from "react";

import { identify, init, getTrackingIdentifier } from "@workleap-tracking/logrocket";

import { useUserContext } from "@contexts/user/UserContext";

import useIsFeatureEnabled from "./useIsFeatureEnabled";

export const useLogRocket = () => {
    const { context } = useUserContext();

    const isLogRocketEnabled = useIsFeatureEnabled(feature => feature.useLogRocket);

    const appId = window.env.LOGROCKET_APP_ID;

    useEffect(() => {
        if (!context) {
            return;
        }

        const { organisationId, organisationName, userId, isCollaborator, isCompanyManager, isNetworkAdmin, isNetworkCreator, isReportingManager, isTeamManager, isMigratedToWorkleap, planCode } = context;

        const trackingIdentifier = getTrackingIdentifier({ generateCookieOnDefault: true, pregeneratedTrackingIdentifier: userId });

        if (appId && isLogRocketEnabled && trackingIdentifier) {
            init(appId);

            identify(trackingIdentifier, {
                userId,
                organizationId: organisationId,
                organizationName: organisationName,
                isMigratedToWorkleap,
                isOrganizationCreator: isNetworkCreator,
                isAdmin: isNetworkAdmin,
                isReportingManager,
                isTeamManager,
                isCollaborator: { wov: isCollaborator },
                isExecutive: { wov: isCompanyManager },
                planCode: { wov: planCode }
            });
        }
    }, [appId, isLogRocketEnabled, context]);
};