import React from "react";

import Select from "@components/select/Select";

import SupportedSurveyLanguage from "@core/enums/SupportedSurveyLanguage";

const options = [
    SupportedSurveyLanguage.German,
    SupportedSurveyLanguage.English,
    SupportedSurveyLanguage.Spanish,
    SupportedSurveyLanguage.French,
    SupportedSurveyLanguage.Portuguese
];

const getLabel = (option: SupportedSurveyLanguage) => {
    switch (option) {
        case SupportedSurveyLanguage.German:
            return "Deutsch (Deutschland)";
        case SupportedSurveyLanguage.English:
            return "English (U.S.)";
        case SupportedSurveyLanguage.Spanish:
            return "Español (Suramérica)";
        case SupportedSurveyLanguage.French:
            return "Français (Canada)";
        case SupportedSurveyLanguage.Portuguese:
            return "Português (Brasil)";
        default:
            throw new Error(`Language (${option}) not supported`);
    }
};

const renderValue = (option: SupportedSurveyLanguage) => {
    return getLabel(option);
};

interface Props {
    className?: string;
    value: SupportedSurveyLanguage;
    onChange: (value: SupportedSurveyLanguage) => void;
}

const SurveyLanguageSelect = ({ className, value, onChange }: Props) => {
    const renderOption = (
        option: SupportedSurveyLanguage,
        isKeyboardFocused: boolean,
        onClick: React.MouseEventHandler<HTMLButtonElement>
    ) => (
        <Select.Option
            key={option}
            onClick={onClick}
            selected={value === option}
            isKeyboardFocused={isKeyboardFocused}
        >
            {getLabel(option)}
        </Select.Option>
    );

    return (
        <Select<SupportedSurveyLanguage>
            className={className}
            value={value}
            renderValue={renderValue}
            options={options}
            renderOption={renderOption}
            onChange={onChange}
        />
    );
};

export default SurveyLanguageSelect;
