enum KeyCodes {
    ArrowDown = 40,
    ArrowLeft = 37,
    ArrowRight = 39,
    ArrowUp = 38,
    Enter = 13,
    Escape = 27,
    Space = 32,
    Tab = 9
}

export default KeyCodes;