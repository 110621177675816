import type { GuidString } from "@/types/utilityTypes";

import type { PulseSurveyQuestionId } from "@core/activities/pulse-survey/types";
import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

export interface TryPulseSurveyActivityResponse {
    correlationId: GuidString;
    questionIds: PulseSurveyQuestionId[];
    askFollowUpForQuestionId: PulseSurveyQuestionId;
}

export async function getActivityAsync(): Promise<TryPulseSurveyActivityResponse> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync("/api/v1/activities/try-pulse-survey");
}