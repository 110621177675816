import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";
import useKeyboard, { Handled } from "@hooks/useKeyboard";

import { useUserContext } from "@contexts/user/UserContext";

import KeyCode from "@core/enums/KeyCodes";

import SignupSlide from "./SignupSlide";

import { ReactComponent as IllustrationWorkleapBrand } from "@svg/illustrations/illustration-survey-explanation.svg";
import { ReactComponent as IllustrationOVBrand } from "@svg/illustrations/legacy/illustration-survey-explanation.svg";

import "./signup-pulse-survey-explanation-slide.scss";

interface Props {
    className?: string;
    slideIndex: number;
    totalSlides: number;
    onPreviousSlideClick: () => void;
    onNextSlideClick: () => void;
}

const SignupPulseSurveyExplanationSlide = ({
    className,
    slideIndex,
    totalSlides,
    onPreviousSlideClick,
    onNextSlideClick
}: Props) => {
    const { t } = useTranslation("activities");
    const { context: userContext } = useUserContext();
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );

    useKeyboard({
        [KeyCode.Enter]: () => {
            onNextSlideClick();

            return Handled;
        }
    });

    const classes = classNames(
        "signup-pulse-survey-explanation-slide",
        className
    );

    return (
        <SignupSlide className={classes}>
            <SignupSlide.ImageHeader
                className="signup-pulse-survey-explanation-slide__header"
                image={
                    isWorkleapBrandEnabled ? (
                        <IllustrationWorkleapBrand />
                    ) : (
                        <IllustrationOVBrand />
                    )
                }
                theme={SignupSlide.ImageHeader.Theme.Coral}
            />
            <SignupSlide.Explanation
                className="signup-pulse-survey-explanation-slide__body"
                title={t("onboarding.pulseSurveySlideTitle")}
                description={t("onboarding.pulseSurveySlideDescription")}
            />
            <SignupSlide.Footer
                className="signup-pulse-survey-explanation-slide__footer"
                currentStepIndex={slideIndex}
                totalSteps={totalSlides}
                showPreviousButton={!userContext?.isMigratedToWorkleap}
                showNextButton
                onPreviousClick={onPreviousSlideClick}
                onNextClick={onNextSlideClick}
            />
        </SignupSlide>
    );
};

export default SignupPulseSurveyExplanationSlide;
