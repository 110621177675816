import queryString from "query-string";

export default function removeParamsFromQueryString(currentQueryString: string, paramsToRemove: string[]) {
    if (paramsToRemove.length < 1) {
        return currentQueryString;
    }

    const parsedQueryString = queryString.parse(currentQueryString);

    Object.keys(parsedQueryString).forEach(key => {
        return paramsToRemove.includes(key) && delete parsedQueryString[key];
    });

    return queryString.stringify(parsedQueryString);
}