import React from "react";

import classNames from "classnames";
import { kebabCase } from "lodash";

import TagTheme from "./TagTheme";

import "./tag.scss";

interface Props {
    className?: string;
    theme?: TagTheme;
    children?: React.ReactNode;
    isCompact?: boolean;
}

const Tag = ({ className, theme = TagTheme.Creamsicle, children, isCompact = false }: Props) => {
    const classes = classNames(
        "tag",
        `tag--${kebabCase(theme)}`,
        className, {
            "tag--compact": isCompact
        }
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

Tag.Theme = TagTheme;

export default Tag;