import { type DependencyList, type EffectCallback, useEffect } from "react";

type TimeoutHandle = ReturnType<typeof setTimeout>;

export default function useDelayedEffect(fn: EffectCallback, updateWhen: DependencyList, delay?: number, condition = true) {
    useEffect(() => {
        let timeout: TimeoutHandle | null = null;

        if (condition) {
            timeout = setTimeout(fn, delay);
        } else {
            fn();
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, updateWhen); // eslint-disable-line react-hooks/exhaustive-deps
}