/* eslint-disable react/no-unused-state -- eslint seems unable to track usages outside of this context */
import React from "react";

import type {
    BuiltActivity
} from "@/types/activities//common";
import type { BuiltCustomPollActivity } from "@/types/activities/custom-polls";
import type { BuiltDeibSurveyActivity } from "@/types/activities/deib-survey";
import type { BuiltOnboardingSurveyActivity } from "@/types/activities/onboarding-survey";
import type { BuiltPulseSurveyActivity } from "@/types/activities/pulse-survey";
import type { BuiltRecognitionActivity } from "@/types/activities/recognition";
import type { BuiltSmartQuestionActivity } from "@/types/activities/smart-question";
import { type TFunction, useTranslation } from "react-i18next";

import {
    activityEndedAsync as customPollActivityEndedAsync,
    type CustomPollDetails,
    deleteFollowUpAnswerAsync as deleteCustomPollFollowUpAnswerAsync,
    deleteTextAnswerAsync as deleteCustomPollTextAnswerAsync,
    listActivitiesAsync as getCustomPollActivitiesAsync,
    getActivityAsync as getCustomPollActivityAsync,
    getPreviewActivityAsync as getPreviewCustomPollActivityAsync,
    saveAnswerAsync as saveCustomPollAnswerAsync,
    saveFollowUpAnswerAsync as saveCustomPollFollowUpAnswerAsync,
    skipAnswerAsync as skipCustomPollAnswerAsync,
    skipFollowUpAnswerAsync as skipCustomPollFollowUpAnswerAsync
} from "@api/CustomPollsActivityApiClient";
import {
    saveCustomTextAnswerAsync,
    skipCustomQuestionAnswerAsync,
    deleteCustomTextAnswerAsync
} from "@api/CustomQuestionActivityApiClient";
import {
    activityEndedAsync as deibSurveyActivityEndedAsync,
    getActivityAsync as getDeibActivityAsync,
    saveAnswerAsync as saveDeibSurveyAnswerAsync,
    skipAnswerAsync as skipDeibSurveyAnswerAsync
} from "@api/DeibSurveyActivityClient";
import {
    deleteFollowUpAnswerAsync as deleteOnboardingSurveyFollowUpAnswerAsync,
    getContinueActivityAndSaveAnswerAsync as getContinueOnboardingSurveyActivityAndSaveAnswerAsync,
    getActivityAsync as getOnboardingSurveyActivityAsync,
    activityEndedAsync as onboardingSurveyActivityEndedAsync,
    saveAnswerAsync as saveOnboardingSurveyAnswerAsync,
    saveFollowUpAnswerAsync as saveOnboardingSurveyFollowUpAnswerAsync,
    skipAnswerAsync as skipOnboardingSurveyAnswerAsync,
    skipFollowUpAnswerAsync as skipOnboardingSurveyFollowUpAnswerAsync
} from "@api/OnboardingSurveyActivityApiClient";
import {
    deleteFollowUpAnswerAsync as deletePulseSurveyFollowUpAnswerAsync,
    getContinueActivityAndSaveAnswerAsync as getContinuePulseSurveyActivityAndSaveAnswerAsync,
    getActivityAsync as getPulseSurveyActivityAsync,
    activityEndedAsync as pulseSurveyActivityEndedAsync,
    type PulseSurveyActivityResponse,
    saveAnswerAsync as savePulseSurveyAnswerAsync,
    saveFollowUpAnswerAsync as savePulseSurveyFollowUpAnswerAsync,
    skipAnswerAsync as skipPulseSurveyAnswerAsync,
    skipFollowUpAnswerAsync as skipPulseSurveyFollowUpAnswerAsync
} from "@api/PulseSurveyActivityApiClient";
import {
    getActivityAsync as getRecognitionActivityAsync,
    activityEndedAsync as recognitionActivityEndedAsync,
    saveCustomPromptAnswerAsync as saveCustomRecognitionAnswerAsync,
    saveMultipleRecipientsCustomPromptAnswerAsync,
    saveMultipleRecipientsOfficevibePromptAnswerAsync,
    saveOfficevibePromptAnswerAsync as saveOfficevibeRecognitionAnswerAsync,
    searchPeersAsync,
    skipAnswerAsync as skipRecognitionAnswerAsync
} from "@api/RecognitionActivityApiClient";
import {
    getActivityAsync as getSmartQuestionActivityAsync,
    saveFeedbackAsync as saveSmartQuestionFeedbackAsync,
    skipFeedbackAsync as skipSmartQuestionFeedbackAsync,
    activityEndedAsync as smartQuestionActivityEndedAsync
} from "@api/SmartQuestionActivityApiClient";
import { getActivityAsync as getTestRecognitionActivityAsync } from "@api/TestRecognitionActivityApiClient";
import { getActivityAsync as getTryPulseSurveyActivityAsync } from "@api/TryPulseSurveyActivityApiClient";
import buildCustomPollActivity from "@core/activities/custom-poll/buildCustomPollActivity";
import buildPreviewCustomPollActivity from "@core/activities/custom-poll/buildPreviewCustomPollActivity";
import buildTestCustomPollActivity from "@core/activities/custom-poll/buildTestCustomPollActivity";
import buildDeibSurveyActivity from "@core/activities/deib-survey/buildDeibSurveyActivity";
import buildTestDeibSurveyQuestionActivity from "@core/activities/deib-survey/buildTestDeibSurveyQuestionActivity";
import type { DeibQuestionID } from "@core/activities/deib-survey/types";
import buildContinueOnboardingSurveyActivity from "@core/activities/onboarding-survey/buildContinueOnboardingSurveyActivity";
import buildOnboardingSurveyActivity from "@core/activities/onboarding-survey/buildOnboardingSurveyActivity";
import buildTestOnboardingSurveyQuestionActivity from "@core/activities/onboarding-survey/buildTestOnboardingSurveyQuestionActivity";
import type { OnboardingQuestionID } from "@core/activities/onboarding-survey/types";
import buildContinuePulseSurveyActivity from "@core/activities/pulse-survey/buildContinuePulseSurveyActivity";
import buildPulseSurveyActivity from "@core/activities/pulse-survey/buildPulseSurveyActivity";
import buildTestPulseSurveyQuestionActivity from "@core/activities/pulse-survey/buildTestPulseSurveyQuestionActivity";
import buildTryPulseSurveyActivity from "@core/activities/pulse-survey/buildTryPulseSurveyActivity";
import type {
    PulseSurveyActivityCustomQuestionOptions,
    PulseSurveyQuestionId
} from "@core/activities/pulse-survey/types";
import buildRecognitionActivity from "@core/activities/recognition/buildRecognitionActivity";
import buildTestRecognitionActivity from "@core/activities/recognition/buildTestRecognitionActivity";
import buildSmartQuestionActivity from "@core/activities/smart-question/buildSmartQuestionActivity";
import buildTestSmartQuestionActivity from "@core/activities/smart-question/buildTestSmartQuestionActivity";
import ApiException from "@core/api-client/ApiException";

import enqueue, {
    initialize as initializeWorker,
    terminate as terminateWorker
} from "./ActivitiesServerSyncCommitChangesWorker";
import ActivitiesServerSyncContext, {
    type useActivitiesServerSyncContext
} from "./ActivitiesServerSyncContext";

interface Props {
    children: React.ReactNode;
    t: TFunction<"activities">;
}

interface State extends ReturnType<typeof useActivitiesServerSyncContext> {
    _buildActivityChain: (
        pulseSurveyActivity: BuiltPulseSurveyActivity | null,
        customPollActivities: BuiltCustomPollActivity[],
        smartQuestionActivity: BuiltSmartQuestionActivity | null,
        recognitionActivity: BuiltRecognitionActivity | null,
        onboardingSurveyActivity: BuiltOnboardingSurveyActivity | null,
        deibSurveyActivity: BuiltDeibSurveyActivity | null
    ) => BuiltActivity[];
    _getPulseSurveyActivityAsync: (
        isPulseSurveyCustomTextualQuestionsEnabled: boolean
    ) => Promise<ReturnType<typeof buildPulseSurveyActivity<PulseSurveyActivityResponse>> | null>;
    _getContinuePulseSurveyActivityAndSaveAnswerAsync: (
        questionId: PulseSurveyQuestionId,
        answerScore: number,
        answerValue: number,
        isPulseSurveyCustomTextualQuestionsEnabled: boolean
    ) => Promise<ReturnType<typeof buildContinuePulseSurveyActivity>>;
    _getTryPulseSurveyActivityAsync: () => Promise<ReturnType<typeof buildTryPulseSurveyActivity> | null>;
    _getTestPulseSurveyQuestionActivity: (
        questionId: PulseSurveyQuestionId
    ) => ReturnType<typeof buildTestPulseSurveyQuestionActivity>;
    _getCustomPollsActivitiesAsync: () => Promise<ReturnType<typeof buildCustomPollActivity<CustomPollDetails>>[]>;
    _getCustomPollActivityAsync: (
        customPollId: string
    ) => Promise<ReturnType<typeof buildCustomPollActivity<CustomPollDetails>>>;
    _getPreviewCustomPollActivityAsync: (
        customPollId: string
    ) => Promise<ReturnType<typeof buildPreviewCustomPollActivity>>;
    _getTestCustomPollActivity: () => ReturnType<typeof buildTestCustomPollActivity>;
    _getSmartQuestionActivityAsync: () => Promise<ReturnType<typeof buildSmartQuestionActivity>>;
    _getTestSmartQuestionActivity: (
        metricId: string,
        subMetricId: string
    ) => ReturnType<typeof buildTestSmartQuestionActivity>;
    _getRecognitionActivityAsync: (
        withIntro: boolean
    ) => Promise<ReturnType<typeof buildRecognitionActivity> | null>;
    _getTestRecognitionActivityAsync: () => Promise<ReturnType<typeof buildTestRecognitionActivity>>;
    _getDeibSurveyActivityAsync: () => Promise<ReturnType<typeof buildDeibSurveyActivity> | null>;
    _getTestDeibSurveyQuestionActivity: (
        questionId: DeibQuestionID
    ) => ReturnType<typeof buildTestDeibSurveyQuestionActivity>;
    _getOnboardingSurveyActivityAsync: () => Promise<ReturnType<typeof buildOnboardingSurveyActivity> | null>;
    _getContinueOnboardingSurveyActivityAndSaveAnswerAsync: (
        questionId: OnboardingQuestionID,
        answerScore: number,
        answerValue: number
    ) => Promise<ReturnType<typeof buildContinueOnboardingSurveyActivity> | null>;
    _getTestOnboardingSurveyQuestionActivity: (
        questionId: OnboardingQuestionID
    ) => ReturnType<typeof buildTestOnboardingSurveyQuestionActivity>;
}

class ActivitiesServerSyncContextProvider extends React.Component<
Props,
State
> {
    constructor(props: Props) {
        super(props);

        const { t } = props;

        this.state = {
            getCompleteSurveyActivitiesAsync:
                async isPulseSurveyCustomTextualQuestionsEnabled => {
                    const {
                        _getPulseSurveyActivityAsync,
                        _getCustomPollsActivitiesAsync,
                        _getSmartQuestionActivityAsync,
                        _getRecognitionActivityAsync,
                        _getOnboardingSurveyActivityAsync,
                        _getDeibSurveyActivityAsync,
                        _buildActivityChain
                    } = this.state;

                    const pulseSurveyActivityPromise =
                        _getPulseSurveyActivityAsync(
                            isPulseSurveyCustomTextualQuestionsEnabled
                        );
                    const customPollActivitiesPromise =
                        _getCustomPollsActivitiesAsync();
                    const smartQuestionActivityPromise =
                        _getSmartQuestionActivityAsync();
                    const recognitionActivityPromise =
                        _getRecognitionActivityAsync(true);
                    const onboardingSurveyActivityPromise =
                        _getOnboardingSurveyActivityAsync();
                    const deibSurveyActivityPromise =
                        _getDeibSurveyActivityAsync();

                    await Promise.all([
                        pulseSurveyActivityPromise,
                        customPollActivitiesPromise,
                        smartQuestionActivityPromise,
                        recognitionActivityPromise,
                        onboardingSurveyActivityPromise,
                        deibSurveyActivityPromise
                    ]);

                    return _buildActivityChain(
                        await pulseSurveyActivityPromise,
                        await customPollActivitiesPromise,
                        await smartQuestionActivityPromise,
                        await recognitionActivityPromise,
                        await onboardingSurveyActivityPromise,
                        await deibSurveyActivityPromise
                    );
                },
            getContinueSurveyActivitiesAsync: async(
                questionId,
                answerScore,
                answerValue,
                isPulseSurveyCustomTextualQuestionsEnabled
            ) => {
                const {
                    _getContinuePulseSurveyActivityAndSaveAnswerAsync,
                    _getCustomPollsActivitiesAsync,
                    _getSmartQuestionActivityAsync,
                    _getRecognitionActivityAsync,
                    _getContinueOnboardingSurveyActivityAndSaveAnswerAsync,
                    _getDeibSurveyActivityAsync,
                    _buildActivityChain
                } = this.state;

                const pulseSurveyActivityPromise =
                    _getContinuePulseSurveyActivityAndSaveAnswerAsync(
                        questionId as PulseSurveyQuestionId,
                        answerScore,
                        answerValue,
                        isPulseSurveyCustomTextualQuestionsEnabled
                    );
                const customPollActivitiesPromise =
                    _getCustomPollsActivitiesAsync();
                const smartQuestionActivityPromise =
                    _getSmartQuestionActivityAsync();
                const recognitionActivityPromise =
                    _getRecognitionActivityAsync(true);
                const onboardingSurveyActivityPromise =
                    _getContinueOnboardingSurveyActivityAndSaveAnswerAsync(
                        questionId as OnboardingQuestionID,
                        answerScore,
                        answerValue
                    );
                const deibSurveyActivityPromise = _getDeibSurveyActivityAsync();

                await Promise.all([
                    pulseSurveyActivityPromise,
                    customPollActivitiesPromise,
                    smartQuestionActivityPromise,
                    recognitionActivityPromise,
                    onboardingSurveyActivityPromise,
                    deibSurveyActivityPromise
                ]);

                return _buildActivityChain(
                    await pulseSurveyActivityPromise,
                    await customPollActivitiesPromise,
                    await smartQuestionActivityPromise,
                    await recognitionActivityPromise,
                    await onboardingSurveyActivityPromise,
                    await deibSurveyActivityPromise
                );
            },
            getTryPulseSurveyActivitiesAsync: async() => {
                const { _getTryPulseSurveyActivityAsync } = this.state;
                const tryPulseSurveyActivity =
                    await _getTryPulseSurveyActivityAsync();

                return [tryPulseSurveyActivity];
            },
            getCustomPollActivitiesAsync: async customPollId => {
                const { _getCustomPollActivityAsync } = this.state;
                const customPollActivity =
                    await _getCustomPollActivityAsync(customPollId);

                return [customPollActivity];
            },
            getPreviewCustomPollActivityAsync: async customPollId => {
                const { _getPreviewCustomPollActivityAsync } = this.state;
                const customPollActivity =
                    await _getPreviewCustomPollActivityAsync(customPollId);

                return [customPollActivity];
            },
            getRecognitionActivityAsync: async() => {
                const { _getRecognitionActivityAsync } = this.state;
                const recognitionActivity =
                    await _getRecognitionActivityAsync(false);

                if (
                    !recognitionActivity ||
                    recognitionActivity.shuffledActivePrompts.length === 0
                ) {
                    return [];
                }

                return [recognitionActivity];
            },
            getOnboardingSurveyActivityAsync: async() => {
                const {
                    _getCustomPollsActivitiesAsync,
                    _getOnboardingSurveyActivityAsync
                } = this.state;
                const customPollActivitiesPromise =
                    _getCustomPollsActivitiesAsync();
                const onboardingSurveyActivityPromise =
                    _getOnboardingSurveyActivityAsync();

                await Promise.all([
                    onboardingSurveyActivityPromise,
                    customPollActivitiesPromise
                ]);
                const activities = [];
                activities.push(await onboardingSurveyActivityPromise);
                const customPollActivities = await customPollActivitiesPromise;

                if (customPollActivities && customPollActivities.length > 0) {
                    for (const customPollActivity of customPollActivities) {
                        activities.push(customPollActivity);
                    }
                }

                return activities;
            },
            getTestPulseSurveyQuestionActivitiesAsync: async questionId => {
                const { _getTestPulseSurveyQuestionActivity } = this.state;
                const testPulseSurveyQuestionActivity =
                    _getTestPulseSurveyQuestionActivity(questionId);

                return [testPulseSurveyQuestionActivity];
            },
            getTestCustomPollActivitiesAsync: async() => {
                const { _getTestCustomPollActivity } = this.state;
                const testCustomPollActivity = _getTestCustomPollActivity();

                return [testCustomPollActivity];
            },
            getTestSmartQuestionActivitiesAsync: async(
                metricId,
                subMetricId
            ) => {
                const { _getTestSmartQuestionActivity } = this.state;
                const testSmartQuestionActivity = _getTestSmartQuestionActivity(
                    metricId,
                    subMetricId
                );

                return [testSmartQuestionActivity];
            },
            getTestRecognitionActivityAsync: async() => {
                const { _getTestRecognitionActivityAsync } = this.state;
                const testRecognitionActivity =
                    await _getTestRecognitionActivityAsync();

                return [testRecognitionActivity];
            },
            getTestOnboardingSurveyActivityAsync: async questionId => {
                const { _getTestOnboardingSurveyQuestionActivity } = this.state;
                const testOnboardingSurveyActivity =
                    await _getTestOnboardingSurveyQuestionActivity(questionId);

                return [testOnboardingSurveyActivity];
            },
            getTestDeibSurveyActivityAsync: async questionId => {
                const { _getTestDeibSurveyQuestionActivity } = this.state;
                const testDeibSurveyActivity =
                    await _getTestDeibSurveyQuestionActivity(questionId);

                return [testDeibSurveyActivity];
            },
            _buildActivityChain: (
                pulseSurveyActivity,
                customPollActivities,
                smartQuestionActivity,
                recognitionActivity,
                onboardingSurveyActivity,
                deibSurveyActivity
            ) => {
                const activities = [];
                if (onboardingSurveyActivity) {
                    activities.push(onboardingSurveyActivity);
                } else if (pulseSurveyActivity) {
                    activities.push(pulseSurveyActivity);

                    if (deibSurveyActivity) {
                        activities.push(deibSurveyActivity);
                    }
                }

                const isRecognitionActivitySoftDisabled =
                    recognitionActivity &&
                    recognitionActivity.shuffledActivePrompts.length === 0;

                if (customPollActivities && customPollActivities.length > 0) {
                    for (const customPollActivity of customPollActivities) {
                        activities.push(customPollActivity);
                    }
                } else if (
                    recognitionActivity &&
                    recognitionActivity.isOverdue &&
                    !isRecognitionActivitySoftDisabled &&
                    !onboardingSurveyActivity
                ) {
                    activities.push(recognitionActivity);
                } else if (smartQuestionActivity && !onboardingSurveyActivity) {
                    activities.push(smartQuestionActivity);
                } else if (
                    recognitionActivity &&
                    !isRecognitionActivitySoftDisabled &&
                    !onboardingSurveyActivity
                ) {
                    activities.push(recognitionActivity);
                }

                return activities.filter(x => x);
            },
            _getContinuePulseSurveyActivityAndSaveAnswerAsync: async(
                questionId,
                answerScore,
                answerValue,
                isPulseSurveyCustomTextualQuestionsEnabled
            ) => {
                try {
                    const continuePulseSurveyActivityResponse = await getContinuePulseSurveyActivityAndSaveAnswerAsync(
                        questionId,
                        answerScore
                    );

                    const pulseSurveyActivityCustomQuestionOptions: PulseSurveyActivityCustomQuestionOptions =
                        {
                            isPulseSurveyCustomTextualQuestionsEnabled,
                            insertCustomQuestionAfterFirst: true,
                            customQuestion: continuePulseSurveyActivityResponse.customQuestion
                        };

                    return buildContinuePulseSurveyActivity(
                        continuePulseSurveyActivityResponse,
                        answerScore,
                        answerValue,
                        pulseSurveyActivityCustomQuestionOptions,
                        t
                    );
                } catch (ex) {
                    if (ex instanceof ApiException && ex.errorCode === 403) {
                        // Survey is most likely disabled
                        return null;
                    }

                    throw ex;
                }
            },
            _getPulseSurveyActivityAsync:
                async isPulseSurveyCustomTextualQuestionsEnabled => {
                    try {
                        const pulseSurveyActivity =
                            await getPulseSurveyActivityAsync();

                        const pulseSurveyActivityCustomQuestionOptions = {
                            isPulseSurveyCustomTextualQuestionsEnabled,
                            insertCustomQuestionAfterFirst: false,
                            customQuestion: pulseSurveyActivity.customQuestion
                        };

                        return buildPulseSurveyActivity(
                            pulseSurveyActivity,
                            pulseSurveyActivityCustomQuestionOptions,
                            t
                        );
                    } catch (ex) {
                        if (
                            ex instanceof ApiException &&
                            ex.errorCode === 403
                        ) {
                            // Survey is most likely disabled
                            return null;
                        }

                        throw ex;
                    }
                },
            _getTryPulseSurveyActivityAsync: async() => {
                const tryPulseSurveyActivity = await getTryPulseSurveyActivityAsync();

                return buildTryPulseSurveyActivity(
                    tryPulseSurveyActivity,
                    t
                );
            },
            _getTestPulseSurveyQuestionActivity: questionId => {
                return buildTestPulseSurveyQuestionActivity(questionId, t);
            },
            _getCustomPollsActivitiesAsync: async() => {
                const { customPolls } = await getCustomPollActivitiesAsync();

                return customPolls.map(buildCustomPollActivity);
            },
            _getCustomPollActivityAsync: async customPollId => {
                const customPoll =
                    await getCustomPollActivityAsync(customPollId);

                return buildCustomPollActivity(customPoll?.customPoll);
            },
            _getPreviewCustomPollActivityAsync: async customPollId => {
                const { customPoll } =
                    await getPreviewCustomPollActivityAsync(customPollId);

                return buildPreviewCustomPollActivity(customPoll);
            },
            _getTestCustomPollActivity: () => {
                return buildTestCustomPollActivity();
            },
            _getSmartQuestionActivityAsync: async() => {
                const { metricId, subMetricId } =
                    await getSmartQuestionActivityAsync();

                return buildSmartQuestionActivity(metricId, subMetricId, t);
            },
            _getTestSmartQuestionActivity: (metricId, subMetricId) => {
                return buildTestSmartQuestionActivity(metricId, subMetricId, t);
            },
            _getRecognitionActivityAsync: async withIntro => {
                try {
                    const recognitionActivity = await getRecognitionActivityAsync();

                    return buildRecognitionActivity(
                        recognitionActivity,
                        withIntro,
                        t
                    );
                } catch (ex) {
                    if (ex instanceof ApiException && ex.errorCode === 403) {
                        // Recognition is most likely disabled
                        return null;
                    }

                    throw ex;
                }
            },
            _getTestRecognitionActivityAsync: async() => {
                const { prompts } = await getTestRecognitionActivityAsync();

                return buildTestRecognitionActivity(prompts, t);
            },
            _getDeibSurveyActivityAsync: async() => {
                try {
                    const deibActivity = await getDeibActivityAsync();

                    return buildDeibSurveyActivity(
                        deibActivity,
                        t
                    );
                } catch (ex) {
                    if (ex instanceof ApiException && ex.errorCode === 403) {
                        // Survey is most likely disabled
                        return null;
                    }

                    throw ex;
                }
            },
            _getOnboardingSurveyActivityAsync: async() => {
                try {
                    const onboardingActivity = await getOnboardingSurveyActivityAsync();

                    return buildOnboardingSurveyActivity(
                        onboardingActivity,
                        t
                    );
                } catch (ex) {
                    if (ex instanceof ApiException && ex.errorCode === 403) {
                        // Survey is most likely disabled
                        return null;
                    }

                    throw ex;
                }
            },
            _getContinueOnboardingSurveyActivityAndSaveAnswerAsync: async(
                questionId,
                answerScore,
                answerValue
            ) => {
                try {
                    const continueOboardingSurveyResponse =
                        await getContinueOnboardingSurveyActivityAndSaveAnswerAsync(
                            questionId,
                            answerScore
                        );

                    return buildContinueOnboardingSurveyActivity(
                        continueOboardingSurveyResponse,
                        answerScore,
                        answerValue,
                        t
                    );
                } catch (ex) {
                    if (ex instanceof ApiException && ex.errorCode === 403) {
                        // Survey is most likely disabled
                        return null;
                    }

                    throw ex;
                }
            },
            _getTestOnboardingSurveyQuestionActivity: questionId => {
                return buildTestOnboardingSurveyQuestionActivity(questionId, t);
            },
            _getTestDeibSurveyQuestionActivity: questionId => {
                return buildTestDeibSurveyQuestionActivity(questionId, t);
            },
            upsertPulseSurveyAnswerAsync: async(
                correlationId,
                questionId,
                score
            ) => {
                enqueue(() =>
                    savePulseSurveyAnswerAsync(correlationId, questionId, score)
                );
            },
            upsertPulseSurveyAnswerSkippedAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    skipPulseSurveyAnswerAsync(correlationId, questionId)
                );
            },
            upsertPulseSurveyCustomTextAnswerAsync: async(correlationId, questionId, answer) => {
                enqueue(() => saveCustomTextAnswerAsync(correlationId, questionId, answer));
            },
            upsertPulseSurveyCustomQuestionAnswerSkippedAsync: async(correlationId, questionId) => {
                enqueue(() => skipCustomQuestionAnswerAsync(correlationId, questionId));
            },
            upsertPulseSurveyFollowUpAnswerSkippedAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    skipPulseSurveyFollowUpAnswerAsync(
                        correlationId,
                        questionId
                    )
                );
            },
            upsertPulseSurveyFollowUpAnswerAsync: async(
                correlationId,
                questionId,
                isConstructive,
                feedback,
                isAnonymous
            ) => {
                enqueue(() =>
                    savePulseSurveyFollowUpAnswerAsync(
                        correlationId,
                        questionId,
                        isConstructive,
                        feedback,
                        isAnonymous
                    )
                );
            },
            deletePulseSurveyCustomTextAnswerAsync: async(correlationId, questionId) => {
                enqueue(() => deleteCustomTextAnswerAsync(correlationId, questionId));
            },
            deletePulseSurveyFollowUpAnswerAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    deletePulseSurveyFollowUpAnswerAsync(
                        correlationId,
                        questionId
                    )
                );
            },
            onPulseSurveyActivityEnded: async correlationId => {
                enqueue(() => pulseSurveyActivityEndedAsync(correlationId));
            },
            upsertCustomPollAnswerAsync: async(
                customPollId,
                questionId,
                answer
            ) => {
                enqueue(() =>
                    saveCustomPollAnswerAsync(customPollId, questionId, answer)
                );
            },
            upsertCustomPollAnswerSkippedAsync: async(
                customPollId,
                questionId
            ) => {
                enqueue(() =>
                    skipCustomPollAnswerAsync(customPollId, questionId)
                );
            },
            deleteCustomPollTextAnswerAsync: async(
                customPollId,
                questionId
            ) => {
                enqueue(() =>
                    deleteCustomPollTextAnswerAsync(customPollId, questionId)
                );
            },
            upsertCustomPollFollowUpAnswerSkippedAsync: async(
                customPollId,
                questionId
            ) => {
                enqueue(() =>
                    skipCustomPollFollowUpAnswerAsync(customPollId, questionId)
                );
            },
            upsertCustomPollFollowUpAnswerAsync: async(
                customPollId,
                questionId,
                feedback,
                isAnonymous
            ) => {
                enqueue(() =>
                    saveCustomPollFollowUpAnswerAsync(
                        customPollId,
                        questionId,
                        feedback,
                        isAnonymous
                    )
                );
            },
            deleteCustomPollFollowUpAnswerAsync: async(
                customPollId,
                questionId
            ) => {
                enqueue(() =>
                    deleteCustomPollFollowUpAnswerAsync(
                        customPollId,
                        questionId
                    )
                );
            },
            onCustomPollActivityEnded: async customPollId => {
                enqueue(() => customPollActivityEndedAsync(customPollId));
            },
            upsertSmartQuestionSkippedAsync: async subMetricId => {
                enqueue(() => skipSmartQuestionFeedbackAsync(subMetricId));
            },
            upsertSmartQuestionFeedbackAsync: async(
                subMetricId,
                feedback,
                isAnonymous
            ) => {
                enqueue(() =>
                    saveSmartQuestionFeedbackAsync(
                        subMetricId,
                        feedback,
                        isAnonymous
                    )
                );
            },
            onSmartQuestionActivityEnded: async subMetricId => {
                enqueue(() => smartQuestionActivityEndedAsync(subMetricId));
            },
            searchPeersAsync: async(query: string, excludedIds?: string[]) => {
                const result = await searchPeersAsync(query, excludedIds);

                return result.identities;
            },
            upsertOfficevibeRecognitionAsync: async(
                correlationId,
                recipientId,
                categoryId,
                promptId,
                message
            ) => {
                enqueue(() =>
                    saveOfficevibeRecognitionAnswerAsync(
                        correlationId,
                        recipientId,
                        categoryId,
                        promptId,
                        message
                    )
                );
            },
            upsertMultipleRecipientsOfficevibeRecognitionAsync: async(
                correlationId,
                recipientIds,
                categoryId,
                promptId,
                message,
                isPublic
            ) => {
                enqueue(() =>
                    saveMultipleRecipientsOfficevibePromptAnswerAsync(
                        correlationId,
                        recipientIds,
                        categoryId,
                        promptId,
                        message,
                        isPublic
                    )
                );
            },
            upsertCustomRecognitionAsync: async(
                correlationId,
                recipientId,
                categoryId,
                promptId,
                message
            ) => {
                enqueue(() =>
                    saveCustomRecognitionAnswerAsync(
                        correlationId,
                        recipientId,
                        categoryId,
                        promptId,
                        message
                    )
                );
            },
            upsertMultipleRecipientsCustomRecognitionAsync: async(
                correlationId,
                recipientIds,
                categoryId,
                promptId,
                message,
                isPublic
            ) => {
                enqueue(() =>
                    saveMultipleRecipientsCustomPromptAnswerAsync(
                        correlationId,
                        recipientIds,
                        categoryId,
                        promptId,
                        message,
                        isPublic
                    )
                );
            },
            upsertRecognitionSkippedAsync: async correlationId => {
                enqueue(() => skipRecognitionAnswerAsync(correlationId));
            },
            onRecognitionActivityEnded: async correlationId => {
                enqueue(() => recognitionActivityEndedAsync(correlationId));
            },
            upsertOnboardingSurveyAnswerAsync: async(
                correlationId,
                questionId,
                score
            ) => {
                enqueue(() =>
                    saveOnboardingSurveyAnswerAsync(
                        correlationId,
                        questionId,
                        score
                    )
                );
            },
            upsertOnboardingSurveyAnswerSkippedAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    skipOnboardingSurveyAnswerAsync(correlationId, questionId)
                );
            },
            upsertOnboardingSurveyFollowUpAnswerSkippedAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    skipOnboardingSurveyFollowUpAnswerAsync(
                        correlationId,
                        questionId
                    )
                );
            },
            upsertOnboardingSurveyFollowUpAnswerAsync: async(
                correlationId,
                questionId,
                feedback
            ) => {
                enqueue(() =>
                    saveOnboardingSurveyFollowUpAnswerAsync(
                        correlationId,
                        questionId,
                        feedback
                    )
                );
            },
            deleteOnboardingSurveyFollowUpAnswerAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    deleteOnboardingSurveyFollowUpAnswerAsync(
                        correlationId,
                        questionId
                    )
                );
            },
            onOnboardingSurveyActivityEndedAsync: async correlationId => {
                enqueue(() =>
                    onboardingSurveyActivityEndedAsync(correlationId)
                );
            },
            upsertDeibSurveyAnswerAsync: async(
                correlationId,
                questionId,
                score
            ) => {
                enqueue(() =>
                    saveDeibSurveyAnswerAsync(correlationId, questionId, score)
                );
            },
            upsertDeibSurveyAnswerSkippedAsync: async(
                correlationId,
                questionId
            ) => {
                enqueue(() =>
                    skipDeibSurveyAnswerAsync(correlationId, questionId)
                );
            },
            onDeibSurveyActivityEndedAsync: async correlationId => {
                enqueue(() => deibSurveyActivityEndedAsync(correlationId));
            }
        };
    }

    componentDidMount() {
        initializeWorker();
    }

    componentWillUnmount() {
        terminateWorker();
    }

    render() {
        const { children } = this.props;

        return (
            <ActivitiesServerSyncContext.Provider value={this.state}>
                {children}
            </ActivitiesServerSyncContext.Provider>
        );
    }
}

interface WrapperProps {
    children: React.ReactNode;
}

const ActivitiesServerSyncContextProviderWrapper = ({
    children
}: WrapperProps) => {
    const { t } = useTranslation("activities");

    return (
        <ActivitiesServerSyncContextProvider t={t}>
            {children}
        </ActivitiesServerSyncContextProvider>
    );
};

export default ActivitiesServerSyncContextProviderWrapper;
