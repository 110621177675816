import type { OrchestratedActivity } from "@/types/activities/common";
import type { OrchestratedCustomPollStep } from "@/types/activities/custom-polls";
import type { OrchestratedDeibSurveyStep } from "@/types/activities/deib-survey";
import type { OrchestratedPulseSurveyStep } from "@/types/activities/pulse-survey";
import type { OrchestratedRecognitionStep } from "@/types/activities/recognition";

import ActivityBannerType from "@core/enums/ActivityBannerType";
import ActivityType from "@core/enums/ActivityType";
import CustomPollQuestionDisplayType from "@core/enums/CustomPollQuestionDisplayType";
import CustomPollStepType from "@core/enums/CustomPollStepType";
import DeibSurveyStepType from "@core/enums/DeibSurveyStepType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";
import RecognitionStepType from "@core/enums/RecognitionStepType";

function getPulseSurveyBanner(step: OrchestratedPulseSurveyStep) {
    const { stepType } = step;

    switch (stepType) {
        case PulseSurveyStepType.Intro:
            return { type: ActivityBannerType.None };

        case PulseSurveyStepType.AskQuestion:
            return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: true };

        case PulseSurveyStepType.AskFollowUpQuestion:
        case PulseSurveyStepType.AskCustomQuestion:
            return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: step.isAnonymous };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

function getCustomPollBanner(step: OrchestratedCustomPollStep) {
    const { stepType } = step;

    switch (stepType) {
        case CustomPollStepType.Intro:
            return { type: ActivityBannerType.None };

        case CustomPollStepType.AskQuestion:
            if (step.displayType === CustomPollQuestionDisplayType.Text) {
                return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: step.isAnonymous };
            }

            return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: true };

        case CustomPollStepType.AskFollowUpQuestion:
            return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: step.isAnonymous };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

function getDeibBanner(step: OrchestratedDeibSurveyStep) {
    const { stepType } = step;

    switch (stepType) {
        case DeibSurveyStepType.Intro:
            return { type: ActivityBannerType.None };

        case DeibSurveyStepType.AskQuestion:
            return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: true };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

function getSmartQuestionBanner(activity: OrchestratedActivity) {
    return { type: ActivityBannerType.GeneralAnonymity, isAnonymous: activity.isAnonymous };
}

function getRecognitionBanner(step: OrchestratedRecognitionStep) {
    const { stepType } = step;

    switch (stepType) {
        case RecognitionStepType.Intro:
        case RecognitionStepType.SelectPrompt:
            return { type: ActivityBannerType.None };

        case RecognitionStepType.CustomizeMessage:
            return { type: ActivityBannerType.Recognition };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

export default function getBannerState(activity: OrchestratedActivity | null) {
    if (!activity) {
        return { type: ActivityBannerType.None };
    }

    const { activityType } = activity;

    switch (activityType) {
        case ActivityType.PulseSurvey:
        case ActivityType.ContinuePulseSurvey:
            return getPulseSurveyBanner(activity.steps[activity.currentStepIndex]);

        case ActivityType.CustomPoll:
            return getCustomPollBanner(activity.steps[activity.currentStepIndex]);

        case ActivityType.SmartQuestion:
            return getSmartQuestionBanner(activity);

        case ActivityType.PreviewCustomPoll:
        case ActivityType.TestCustomPoll:
            return { type: ActivityBannerType.CustomPollPreview };

        case ActivityType.TryPulseSurvey:
        case ActivityType.TestPulseSurveyQuestion:
        case ActivityType.TestSmartQuestion:
            return { type: ActivityBannerType.PulseSurveyPreview };

        case ActivityType.Recognition:
        case ActivityType.TestRecognition:
            return getRecognitionBanner(activity.steps[activity.currentStepIndex]);

        case ActivityType.DeibSurvey:
        case ActivityType.TestDeibSurvey:
            return getDeibBanner(activity.steps[activity.currentStepIndex]);

        case ActivityType.OnboardingSurvey:
        case ActivityType.TestOnboardingSurvey:
        case ActivityType.ContinueOnboardingSurvey:
            return { type: ActivityBannerType.None };
        default:
            throw new Error(`Activity Type (${activityType}) not supported`);
    }
}