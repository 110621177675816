import React from "react";

import type { OrchestratedPulseSurveyQuestionStep } from "@/types/activities/pulse-survey";

import PulseSurveyLikertQuestion from "@components/pulse-survey-question/PulseSurveyLikertQuestion";
import PulseSurveyMultipleChoiceQuestion from "@components/pulse-survey-question/PulseSurveyMultipleChoiceQuestion";
import PulseSurveySliderQuestion from "@components/pulse-survey-question/PulseSurveySliderQuestion";
import PulseSurveyStarRatingQuestion from "@components/pulse-survey-question/PulseSurveyStarRatingQuestion";
import PulseSurveyTwoWaySliderQuestion from "@components/pulse-survey-question/PulseSurveyTwoWaySliderQuestion";

import mapPulseSurveyValueToScore from "@core/activities/pulse-survey/mapPulseSurveyValueToScore";
import DisplayType from "@core/enums/PulseSurveyQuestionDisplayType";

interface Props {
    step: OrchestratedPulseSurveyQuestionStep;
    onSliderQuestionAnswered: (value: number, score: number) => void;
    onTwoWaySliderQuestionAnswered: (value: number, score: number) => void;
    onStarQuestionAnswered: (value: number, score: number) => void;
    onLikertQuestionAnswered: (value: number, score: number) => void;
    onMultipleChoiceQuestionAnswered: (value: number, score: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const PulseSurveyActivityQuestionStep = ({
    step,
    onSliderQuestionAnswered,
    onTwoWaySliderQuestionAnswered,
    onStarQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    const { displayType, questionId, question, answer } = step;

    const handleOnSubmit = (callback: (value: number, score: number) => void) => (value: number) => {
        callback(value, mapPulseSurveyValueToScore(displayType, value));
    };

    switch (displayType) {
        case DisplayType.Slider:
            return (
                <PulseSurveySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={step.minLabel}
                    maxLabel={step.maxLabel}
                    onChange={handleOnSubmit(onSliderQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.TwoWaySlider:
            return (
                <PulseSurveyTwoWaySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={step.minLabel}
                    maxLabel={step.maxLabel}
                    imageUrls={step.imageUrls}
                    onChange={handleOnSubmit(onTwoWaySliderQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.Stars:
            return (
                <PulseSurveyStarRatingQuestion
                    key={questionId}
                    question={question}
                    onChange={handleOnSubmit(onStarQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.LikertScale:
            return (
                <PulseSurveyLikertQuestion
                    key={questionId}
                    question={question}
                    labels={step.labels}
                    onChange={handleOnSubmit(onLikertQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.MultipleChoice:
            return (
                <PulseSurveyMultipleChoiceQuestion
                    key={questionId}
                    question={question}
                    labels={step.labels}
                    imageUrls={step.imageUrls}
                    onChange={handleOnSubmit(onMultipleChoiceQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

export default PulseSurveyActivityQuestionStep;