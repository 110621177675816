enum ActivityType {
    PulseSurvey = "PulseSurvey",
    ContinuePulseSurvey = "ContinuePulseSurvey",
    TryPulseSurvey = "TryPulseSurvey",
    TestPulseSurveyQuestion = "TestPulseSurveyQuestion",
    CustomPoll = "CustomPoll",
    PreviewCustomPoll = "PreviewCustomPoll",
    TestCustomPoll = "TestCustomPoll",
    SmartQuestion = "SmartQuestion",
    TestSmartQuestion = "TestSmartQuestion",
    Recognition = "Recognition",
    TestRecognition = "TestRecognition",
    OnboardingSurvey = "OnboardingSurvey",
    ContinueOnboardingSurvey = "ContinueOnboardingSurvey",
    TestOnboardingSurvey = "TestOnboardingSurvey",
    DeibSurvey = "DeibSurvey",
    TestDeibSurvey = "TestDeibSurvey"
}

export default ActivityType;