enum Theme {
    Creamsicle = "Creamsicle",
    Seaweed = "Seaweed",
    Dandelion = "Dandelion",
    ElectricBlue = "ElectricBlue",
    Sky = "Sky",
    Coral = "Coral",
    StrawberryFields = "StrawberryFields"
}

export default Theme;