import React, { useEffect } from "react";

import type { OrchestratedPulseSurveyCustomQuestionStep, OrchestratedPulseSurveyFollowUpQuestionStep, OrchestratedPulseSurveyQuestionStep, OrchestratedPulseSurveyStep, PulseSurveyActivityType } from "@/types/activities/pulse-survey";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import type { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import ContinuePulseSurveyIntro from "@components/pulse-survey-question/ContinuePulseSurveyIntro";
import PulseSurveyCustomTextQuestion from "@components/pulse-survey-question/PulseSurveyCustomTextQuestion";

import type { PulseSurveyQuestionId } from "@core/activities/pulse-survey/types";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";
import { trackActivityCompleted, trackActivityStarted, trackFeedbackGiven, trackFeedbackShown, trackLinkClicked, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

import PulseSurveyActivityFollowUpQuestionStep from "./PulseSurveyActivityFollowUpQuestionStep";
import PulseSurveyActivityQuestionStep from "./PulseSurveyActivityQuestionStep";

interface Props {
    className?: string;
    activityType: PulseSurveyActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    isIntroAnimated: boolean;
    correlationId: string;
    steps: OrchestratedPulseSurveyStep[];
    currentStepIndex: number;
    onSliderQuestionAnswered: ReturnType<typeof useActivitiesContext>["onPulseSurveyQuestionAnswered"] ;
    onTwoWaySliderQuestionAnswered: ReturnType<typeof useActivitiesContext>["onPulseSurveyQuestionAnswered"];
    onStarQuestionAnswered: ReturnType<typeof useActivitiesContext>["onPulseSurveyQuestionAnswered"];
    onLikertQuestionAnswered: ReturnType<typeof useActivitiesContext>["onPulseSurveyQuestionAnswered"];
    onMultipleChoiceQuestionAnswered: ReturnType<typeof useActivitiesContext>["onPulseSurveyQuestionAnswered"];
    onChangeFeedbackAnonymity: (isAnonymous: boolean) => void;
    onFollowUpFeedbackLeft: ReturnType<typeof useActivitiesContext>["onPulseSurveyFollowUpAnswerLeft"];
    onPulseSurveyCustomTextQuestionAnswered: (correlationId: string, customQuestionId: string, feedback: string, isAnonymous: boolean) => void;
    onGoToPreviousStep: () => void;
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (correlationId: string) => void;
    onIntroAnimationCompleted: () => void;
}

const PulseSurveyActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    correlationId,
    steps,
    currentStepIndex,
    onSliderQuestionAnswered,
    onTwoWaySliderQuestionAnswered,
    onStarQuestionAnswered,
    onLikertQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onChangeFeedbackAnonymity,
    onFollowUpFeedbackLeft,
    onPulseSurveyCustomTextQuestionAnswered,
    onGoToPreviousStep,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    isIntroAnimated,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager, language } = useAuthenticatedUser();

    const previousStep = steps[currentStepIndex - 1];
    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isQuestionStep = (step: OrchestratedPulseSurveyStep): step is OrchestratedPulseSurveyQuestionStep => "questionId" in step;

    const isCustomTextQuestionStep = (step: OrchestratedPulseSurveyStep): step is OrchestratedPulseSurveyCustomQuestionStep => "customQuestionId" in step;

    const handleOnQuestionAnswered = (callback: (correlationId: string, questionId: PulseSurveyQuestionId, answer: number, score: number) => void) => (answer: number, score: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, currentStep.questionId);

        callback(correlationId, currentStep.questionId, answer, score);
    };

    const handleOnFollowUpQuestionAnswered = (isConstructive: boolean, feedback: string, isAnonymous: boolean) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isConstructive ? TrackingInteractionType.Constructive : TrackingInteractionType.Positive, isAnonymous, currentStep.questionId);

        onFollowUpFeedbackLeft(correlationId, currentStep.questionId, isConstructive, feedback, isAnonymous);
    };

    const handleOnFollowUpQuestionShown = () => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackShown(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, currentStep.questionId);
    };

    const handleOnCustomQuestionShown = () => {
        if (!isCustomTextQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackShown(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, currentStep.customQuestionId, undefined, true, language);
    };

    const handleOnGoBack = () => {
        trackLinkClicked(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, "Edit Answer");

        onGoToPreviousStep();
    };

    const handleOnQuestionAnonymityChanged = (isAnonymous: boolean) => {
        onChangeFeedbackAnonymity(isAnonymous);
    };

    const handleOnAnimationCompleted = () => {
        if (currentStep.isLastStep) {
            trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
            onActivityEnded(correlationId);

            if (isLastActivity) {
                onGoToOutro();
            } else {
                onGoToNextActivity();
            }
        } else {
            onGoToNextStep();
        }
    };

    const handleOnCustomTextQuestionAnswered = (feedback: string, isAnonymous: boolean) => {
        if (!isCustomTextQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, isAnonymous, currentStep.customQuestionId, undefined, true);
        onPulseSurveyCustomTextQuestionAnswered(correlationId, currentStep.customQuestionId, feedback, isAnonymous);
    };

    const renderIntroStep = () => {
        const handleOnDisplayTimeoutEnded = () => {
            onIntroAnimationCompleted();
            handleOnAnimationCompleted();
        };

        return (
            <ContinuePulseSurveyIntro
                isIntroAnimated={isIntroAnimated}
                onDisplayTimeoutEnd={handleOnDisplayTimeoutEnded}
            />
        );
    };

    const renderQuestionStep = (step: OrchestratedPulseSurveyQuestionStep) =>
        <PulseSurveyActivityQuestionStep
            step={step}
            onSliderQuestionAnswered={handleOnQuestionAnswered(onSliderQuestionAnswered)}
            onTwoWaySliderQuestionAnswered={handleOnQuestionAnswered(onTwoWaySliderQuestionAnswered)}
            onStarQuestionAnswered={handleOnQuestionAnswered(onStarQuestionAnswered)}
            onLikertQuestionAnswered={handleOnQuestionAnswered(onLikertQuestionAnswered)}
            onMultipleChoiceQuestionAnswered={handleOnQuestionAnswered(onMultipleChoiceQuestionAnswered)}
            isIntroAnimated={isIntroAnimated}
            onIntroAnimationCompleted={onIntroAnimationCompleted}
            onAnimationCompleted={handleOnAnimationCompleted}
        />;

    const renderFollowUpQuestionStep = (step: OrchestratedPulseSurveyFollowUpQuestionStep) =>
        <PulseSurveyActivityFollowUpQuestionStep
            {...step}
            questionScore={previousStep.score!}
            positiveQuestion={step.positiveFollowUpQuestion}
            constructiveQuestion={step.constructiveFollowUpQuestion}
            onChangeAnonymity={handleOnQuestionAnonymityChanged}
            onSubmit={handleOnFollowUpQuestionAnswered}
            onAnimationCompleted={handleOnAnimationCompleted}
            onGoBack={handleOnGoBack}
            onTrackFeedbackShown={handleOnFollowUpQuestionShown}
        />;

    const renderCustomQuestionStep = (step: OrchestratedPulseSurveyCustomQuestionStep) =>
        <PulseSurveyCustomTextQuestion
            question={step.question}
            isAnswered={!!step.isAnswered}
            initialFeedback={step.feedback}
            initialIsAnonymous={step.isAnonymous}
            onChangeAnonymity={handleOnQuestionAnonymityChanged}
            onSubmit={handleOnCustomTextQuestionAnswered}
            isIntroAnimated={isIntroAnimated}
            onIntroAnimationCompleted={onIntroAnimationCompleted}
            onAnimationCompleted={handleOnAnimationCompleted}
            handleOnCustomQuestionShown={handleOnCustomQuestionShown}
        />;

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            case PulseSurveyStepType.Intro:
                return renderIntroStep();
            case PulseSurveyStepType.AskQuestion:
                return renderQuestionStep(currentStep);
            case PulseSurveyStepType.AskFollowUpQuestion:
                return renderFollowUpQuestionStep(currentStep);
            case PulseSurveyStepType.AskCustomQuestion:
                return renderCustomQuestionStep(currentStep);
            default:
                throw new Error(`Pulse Survey Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

export default PulseSurveyActivity;