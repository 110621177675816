import StepType from "./StepType";

type CustomSurveyStepType =
    | StepType.Intro
    | StepType.AskQuestion
    | StepType.AskFollowUpQuestion;

const CustomSurveyStepType = {
    Intro: StepType.Intro,
    AskQuestion: StepType.AskQuestion,
    AskFollowUpQuestion: StepType.AskFollowUpQuestion
} as const;

export default CustomSurveyStepType;
