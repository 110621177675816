import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";
import type TrackingEventName from "@core/tracking/TrackingEventName";
import type { TrackingEventBaseProperties, TrackingEventOptionalProperties } from "@core/tracking/types";

interface ActivitiesTrackingControllerTrackingRequest {
    eventName: string;
    properties: object;
}

export async function trackAsync(eventName: TrackingEventName, properties: TrackingEventBaseProperties & TrackingEventOptionalProperties): Promise<void> {
    const request: ActivitiesTrackingControllerTrackingRequest = {
        eventName,
        properties
    };

    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync("/api/v1/tracking", request);
}