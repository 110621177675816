import React, { useEffect, useRef, useState } from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import KeyCodes from "@core/enums/KeyCodes";
import sleep from "@core/utils/sleep";

import { ReactComponent as IconArrowLeft } from "@svg/icons/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "@svg/icons/icon-arrow-right.svg";
import { ReactComponent as Check } from "@svg/icons/icon-check.svg";

import "./likert-question.scss";

interface Props {
    className?: string;
    initialValue?: number | null;
    labels: string[];
    onChange: (value: number) => void;
    onAnimationCompleted: () => void;
}

const LikertQuestion = ({
    className,
    initialValue,
    labels,
    onChange,
    onAnimationCompleted
}: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );

    const [confirmed, setConfirmed] = useState(false);
    const [value, setValue] = useState(initialValue ?? null);
    const [focusedValue, setFocusedValue] = useState(
        isWorkleapBrandEnabled ? initialValue ?? null : null
    );
    const [hasUserInteracted, setHasUserInteracted] = useState(
        initialValue !== null
    );
    const mobileLikertRef = useRef<HTMLDivElement>(null);
    const mobileInputRefs = labels.map(() => useRef<HTMLInputElement>(null)); // eslint-disable-line react-hooks/rules-of-hooks
    let mobileScrollSnapTimer: null | ReturnType<typeof setTimeout> = null;

    const mobileSliderScrollTo = (index: number) => {
        const item = mobileInputRefs[index]?.current;

        if (item && mobileLikertRef.current) {
            const scrollX = item.offsetWidth * index;
            mobileLikertRef.current.scrollTo(scrollX, 0);
        }
    };

    useEffect(() => {
        mobileSliderScrollTo(initialValue ?? 2);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnConfirm = async(newValue: number) => {
        if (confirmed) {
            return;
        }

        setValue(newValue);
        setConfirmed(true);
        setHasUserInteracted(true);

        onChange(newValue);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleOnConfirmMobile = async(newValue: number) => {
        if (confirmed) {
            return;
        }

        mobileSliderScrollTo(newValue);
        handleOnConfirm(newValue);
    };

    const handleConfirmOnClick = (
        newValue: number,
        e: React.MouseEvent<HTMLLabelElement>
    ) => {
        e.currentTarget.blur();
        handleOnConfirm(newValue);
    };

    const handleConfirmOnEnter = (
        newValue: number,
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e.keyCode === KeyCodes.Enter) {
            e.currentTarget.blur();

            handleOnConfirm(newValue);
        }
    };

    const handleOnFocusMobile = (currentValue: number) => {
        setHasUserInteracted(true);
        setValue(currentValue);
        mobileSliderScrollTo(currentValue);
    };

    const handleMobileSelection = () => {
        if (!isWorkleapBrandEnabled || !mobileLikertRef.current) {
            return;
        }

        if (mobileScrollSnapTimer) {
            clearTimeout(mobileScrollSnapTimer);
        }

        mobileScrollSnapTimer = setTimeout(() => {
            // We must check again since this code does not execute immediately after the previous check and the value could change
            if (!mobileLikertRef.current) {
                return;
            }

            const scrollContainerRect =
        mobileLikertRef.current.getBoundingClientRect();

            const activeNode = Array.from(
                mobileInputRefs.filter(n => n.current).map(n => n.current)
            ).find(choiceEl => {
                const choiceRect = choiceEl!.getBoundingClientRect();

                // Item should fully fit into the view
                return (
                    choiceRect.x > 0 &&
          choiceRect.x < scrollContainerRect.width &&
          choiceRect.x + choiceRect.width < scrollContainerRect.width
                );
            });

            if (!activeNode) {
                return;
            }

            const activeInput = activeNode.querySelector<HTMLInputElement>(
                ".likert-question__mobile-radio-input"
            );
            if (!activeInput) {
                return;
            }

            const activeValue = parseInt(activeInput.value) ?? null;

            setFocusedValue(activeValue);
            setValue(activeValue);
        }, 80);
    };

    const renderChoice = (label: string, i: number) => {
        const choiceClasses = classNames("likert-question__choice-label", {
            "likert-question__choice-label--selected":
        hasUserInteracted && i === value,
            "likert-question__choice-label--highlighted":
        !confirmed && i === focusedValue
        });

        return (
            <div
                key={`likert-choice-${i}`}
                className={classNames(
                    "likert-question__choice",
                    `likert-question__choice--${i}`
                )}
                role="radio"
                aria-checked={value === i}
            >
                <input
                    id={`likert-choice-${i}`}
                    className="likert-question__radio-input"
                    type="radio"
                    name="likert"
                    value={i}
                    checked={focusedValue !== null ? i === focusedValue : i === value}
                    onChange={() => setFocusedValue(i)}
                    disabled={confirmed}
                    onFocus={() => setFocusedValue(i)}
                    onBlur={() => setFocusedValue(null)}
                    onKeyDown={e => handleConfirmOnEnter(i, e)}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions*/}
                <label
                    className={choiceClasses}
                    htmlFor={`likert-choice-${i}`}
                    aria-label={label}
                    onClick={e => handleConfirmOnClick(i, e)}
                >
                    <div className="likert-question__choice-segment-container">
                        <div className="likert-question__segment"></div>
                        <div className="likert-question__choice-bubble-container">
                            <div className="likert-question__choice-bubble">
                                <div className="likert-question__choice-inner-bubble" />
                                {hasUserInteracted && i === value && (
                                    <Check className="likert-question__choice-check" />
                                )}
                            </div>
                        </div>
                        <div className="likert-question__segment"></div>
                    </div>
                    <div className="likert-question__choice-bubble-label">{label}</div>
                </label>
            </div>
        );
    };

    const renderMobileChoice = (label: string, i: number) => {
        const choiceClasses = classNames("likert-question__mobile-label", {
            "likert-question__mobile-label--focused":
        isWorkleapBrandEnabled && i === focusedValue
        });

        return (
            <div
                key={`likert-mobile-choice-${i}`}
                ref={mobileInputRefs[i]}
                className="likert-question__mobile-choice"
                role="radio"
                aria-checked={value === i}
            >
                <input
                    id={`mobile-likert-choice-${i}`}
                    className="likert-question__mobile-radio-input"
                    type="radio"
                    name="mobile-likert"
                    value={i}
                    checked={focusedValue !== null ? i === focusedValue : i === value}
                    onChange={() => handleOnFocusMobile(i)}
                    onFocus={() => handleOnFocusMobile(i)}
                    onKeyDown={e => handleConfirmOnEnter(i, e)}
                    disabled={confirmed}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                <label className={choiceClasses} htmlFor={`likert-choice-${i}`} onClick={() => handleOnConfirmMobile(i)}>
                    {label}
                </label>
                {!isWorkleapBrandEnabled &&
                    i === value &&
                    !confirmed &&
                    hasUserInteracted && (
                    <div className="likert-question__mobile-click-to-confirm-label">
                        {t("pulseSurveyFrame.question-click-to-confirm-label")}
                    </div>
                )}
                {!isWorkleapBrandEnabled && i === value && confirmed && (
                    <div className="likert-question__mobile-confirmed-label">
                        {t("pulseSurveyFrame.question-confirmed-label")}
                    </div>
                )}
            </div>
        );
    };

    const classes = classNames("likert-question", className, {
        "likert-question--interacted": hasUserInteracted,
        "likert-question--confirmed": confirmed,
        [`likert-question--${value}`]: hasUserInteracted
    });

    return (
        <div className={classes}>
            <div className="likert-question__content">
                {!isWorkleapBrandEnabled && (
                    <div className="likert-question__mobile-scroll-label">
                        <IconArrowLeft className="likert-question__drag-arrow-left" />
                        {t("pulseSurveyFrame.scroll-instruction")}
                        <IconArrowRight className="likert-question__drag-arrow-right" />
                    </div>
                )}

                <div
                    ref={mobileLikertRef}
                    className="likert-question__mobile-group"
                    role="radiogroup"
                    onScroll={handleMobileSelection}
                >
                    <div className="likert-question__mobile-choices-container">
                        {labels.map(renderMobileChoice)}
                    </div>
                </div>
                <div className="likert-question__group" role="radiogroup">
                    {labels.map(renderChoice)}
                </div>

                {isWorkleapBrandEnabled && (
                    <div className="likert-question__mobile-scroll-label">
                        <ArrowLeftIcon
                            size="md"
                            className="likert-question__drag-arrow-left"
                        />
                        {t("pulseSurveyFrame.scroll-instruction")}
                        <ArrowRightIcon
                            size="md"
                            className="likert-question__drag-arrow-right"
                        />
                    </div>
                )}
                {isWorkleapBrandEnabled &&
                    value !== null &&
                    !confirmed &&
                    focusedValue !== null && (
                    <div className="likert-question__mobile-click-to-confirm-label">
                        {t("pulseSurveyFrame.question-click-to-confirm-label")}
                    </div>
                )}
                {isWorkleapBrandEnabled && value !== null && confirmed && (
                    <div className="likert-question__mobile-confirmed-label">
                        {t("pulseSurveyFrame.question-confirmed-label")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LikertQuestion;
