import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";

import buildDeibSurveyQuestionStep from "./buildDeibSurveyQuestionStep";
import type { DeibQuestionID } from "./types";

const buildTestDeibSurveyQuestionActivity = (
    questionId: DeibQuestionID,
    t: TFunction<"activities">
) => {
    const question = buildDeibSurveyQuestionStep(questionId, t);

    return {
        correlationId: "test-correlation-id",
        questionIds: [question.questionId],
        customizationWord: { sourceWord: "test", replacementWord: "test" },
        activityType: ActivityType.TestDeibSurvey as const,
        steps: [question]
    };
};

export default buildTestDeibSurveyQuestionActivity;
