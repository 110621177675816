import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

export interface CustomQuestionActivityControllerSaveTextAnswerRequest {
    feedback: string;
    isAnonymous: boolean;
}

export async function saveCustomTextAnswerAsync(customQuestionId: string, correlationId: string, answer: CustomQuestionActivityControllerSaveTextAnswerRequest): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/custom-questions/${customQuestionId}/${correlationId}`, answer);
}

export async function skipCustomQuestionAnswerAsync(customQuestionId: string, correlationId: string): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).postAsync(`/api/v1/activities/custom-questions/${customQuestionId}/${correlationId}/skip`);
}

export async function deleteCustomTextAnswerAsync(customQuestionId: string, correlationId: string): Promise<void> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).deleteAsync(`/api/v1/activities/custom-questions/${customQuestionId}/${correlationId}`);
}