import React from "react";

import classNames from "classnames";

import useDelayedEffect from "@hooks/useDelayedEffect";

import LikertQuestion from "@components/likert-question/LikertQuestion";

import BaseOnboardingSurveyQuestion from "./BaseOnboardingSurveyQuestion";

import "./onboarding-survey-likert-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    labels: string[];
    onChange: (value: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const OnboardingSurveyLikertQuestion = ({
    className,
    question,
    initialValue,
    labels,
    onChange,
    isIntroAnimated,
    onIntroAnimationCompleted,
    onAnimationCompleted
}: Props) => {
    useDelayedEffect(
        () => {
            if (isIntroAnimated) {
                onIntroAnimationCompleted();
            }
        },
        [],
        900
    );

    const classes = classNames("onboarding-survey-likert-question", className, {
        "onboarding-survey-likert-question--animated-intro": isIntroAnimated
    });

    return (
        <BaseOnboardingSurveyQuestion className={classes} question={question}>
            <LikertQuestion
                className="onboarding-survey-likert-question__content"
                initialValue={initialValue}
                labels={labels}
                onChange={onChange}
                onAnimationCompleted={onAnimationCompleted}
            />
        </BaseOnboardingSurveyQuestion>
    );
};

export default OnboardingSurveyLikertQuestion;
