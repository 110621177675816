import React from "react";

import classNames from "classnames";
import kebabCase from "lodash/kebabCase";

import { useResponsiveContext } from "@contexts/responsive/ResponsiveContext";

import type { ActivePrompt } from "@api/RecognitionActivityApiClient";

import RecognitionCategorySvgImage from "./RecognitionCategorySvgImage";
import RecognitionCategoryTag from "./RecognitionCategoryTag";
import RecognitionPrompt from "./RecognitionPrompt";

import "./recognition-card-image.scss";

interface Props {
    className?: string;
    selectedPrompt: ActivePrompt;
}

const RecognitionCardImage = ({ className, selectedPrompt }: Props) => {
    const { small } = useResponsiveContext();

    const classes = classNames(
        "recognition-card-image",
        `recognition-card-image--${kebabCase(selectedPrompt.categoryTheme)}`,
        className
    );

    return (
        <div className={classes}>
            <RecognitionCategoryTag categoryName={selectedPrompt.categoryName} categoryTheme={selectedPrompt.categoryTheme} isCompact={!small} />
            <RecognitionPrompt className="recognition-card-image__prompt" prompt={selectedPrompt.prompt} />
            {selectedPrompt.categoryImage && <RecognitionCategorySvgImage className="recognition-card-image__image" image={selectedPrompt.categoryImage} />}
        </div>
    );
};

export default RecognitionCardImage;