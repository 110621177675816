import React from "react";

import { dismissTipAsync, getTipsAsync } from "@api/TipsApiClient";
import type TipType from "@core/enums/TipType";
import { trackSurveyTipDismissed } from "@core/tracking/track";

import TipsContext from "./TipsContext";

interface Props {
    children: React.ReactNode;
}

const TipsContextProvider = ({ children }: Props) => {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [tips, setTips] = React.useState<TipType[]>([]);

    const initializeTipsContextAsync = async() => {
        if (!isInitialized) {
            setIsInitialized(true);
            setIsLoading(true);

            const result = await getTipsAsync();

            setTips(result);
            setIsLoading(false);
        }
    };

    const dismiss = async(type: TipType) => {
        await dismissTipAsync(type);

        setTips(tips.filter(tip => tip !== type));

        trackSurveyTipDismissed(type);
    };

    return (
        <TipsContext.Provider value={{ isInitialized, isLoading, tips, initializeTipsContextAsync, dismiss }}>
            {children}
        </TipsContext.Provider>
    );
};

export default TipsContextProvider;