import React from "react";

import Ellipsis from "@igloo-ui/ellipsis";
import classNames from "classnames";

import useImageResizeUrl from "@hooks/useImageResizeUrl";

import SelectOption from "@components/select/SelectOption";
import ImageSizes from "@components/user-image/ImageSizes";

import "./peer-select-option.scss";

interface Props extends React.ComponentPropsWithoutRef<typeof SelectOption> {
    className?: string;
    fullName: string;
    imageUrl: string;
    email: string;
    isDuplicate?: boolean;
}

const PeerSelectOption = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            className,
            fullName,
            imageUrl,
            email,
            isDuplicate,
            selected,
            ...props
        },
        ref
    ) => {
        const userResizedImageUrl = useImageResizeUrl(
            imageUrl,
            ImageSizes.Small
        );

        const classes = classNames("peer-select-option", className, {
            "peer-select-option--selected": selected
        });

        return (
            <SelectOption
                ref={ref}
                className={classes}
                selected={selected}
                {...props}
            >
                <img
                    data-private
                    className="peer-select-option__thumbnail"
                    src={userResizedImageUrl ?? undefined}
                    alt=""
                />
                <div className="peer-select-option__infos">
                    <Ellipsis
                        className="peer-select-option__fullname"
                        title={fullName}
                    >
                        {fullName}
                    </Ellipsis>
                    {isDuplicate && (
                        <Ellipsis
                            className="peer-select-option__email"
                            title={email}
                        >
                            {email}
                        </Ellipsis>
                    )}
                </div>
            </SelectOption>
        );
    }
);

export default PeerSelectOption;
