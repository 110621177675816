import React from "react";

import classNames from "classnames";

import "./inline-button.scss";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {}

const InlineButton = ({ className, children, ...props }: Props) => {
    const classes = classNames("inline-button", className);

    return (
        <button type="button" className={classes} {...props}>
            {children}
        </button>
    );
};

export default InlineButton;
