import React from "react";

import classNames from "classnames";

import ProgressBarStep from "./ProgressBarStep";

import "./progress-bar.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const ProgressBar = ({ className, children }: Props) => {
    const classes = classNames("progress-bar", className);

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

ProgressBar.Step = ProgressBarStep;

export default ProgressBar;