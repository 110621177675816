import React, { useEffect } from "react";

import useIsRecognitionDisabled from "@hooks/useIsRecognitionDisabled";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import NotFoundPage from "@pages/not-found/NotFoundPage";

import ActivitiesPage from "./components/ActivitiesPage";

const RecognitionPage = () => {
    const isRecognitionDisabled = useIsRecognitionDisabled();

    const { initializeRecognitionAsync } = useActivitiesContext();

    useEffect(() => {
        if (!isRecognitionDisabled) {
            initializeRecognitionAsync();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isRecognitionDisabled) {
        return <NotFoundPage />;
    }

    return (
        <ActivitiesPage />
    );
};

export default RecognitionPage;