import React from "react";

import { listAsync as listFeaturesAsync } from "@api/FeaturesApiClient";
import type Feature from "@core/features/feature";

import FeaturesContext from "./FeaturesContext";

interface Props {
    children: React.ReactNode;
}

const FeaturesContextProvider = ({ children }: Props) => {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [features, setFeatures] = React.useState<Feature[]>([]);

    const initializeFeaturesAsync = async() => {
        if (!isInitialized) {
            setIsInitialized(true);
            setIsLoading(true);
            const featureList = await listFeaturesAsync();

            setFeatures(featureList);
            setIsLoading(false);
        }
    };

    return (
        <FeaturesContext.Provider value={{ isInitialized, isLoading, features, initializeFeaturesAsync }}>
            {children}
        </FeaturesContext.Provider>
    );
};

export default FeaturesContextProvider;