import React from "react";

import type { OrchestratedOnboardingSurveyQuestionStep } from "@/types/activities/onboarding-survey";

import OnboardingSurveyLikertQuestion from "@components/onboarding-survey-question/OnboardingSurveyLikertQuestion";
import OnboardingSurveyMultipleChoiceQuestion from "@components/onboarding-survey-question/OnboardingSurveyMultipleChoiceQuestion";
import OnboardingSurveySliderQuestion from "@components/onboarding-survey-question/OnboardingSurveySliderQuestion";

import mapOnboardingSurveyValueToScore from "@core/activities/onboarding-survey/mapOnboardingSurveyValueToScore";
import DisplayType from "@core/enums/OnboardingSurveyQuestionDisplayType";

interface Props {
    step: OrchestratedOnboardingSurveyQuestionStep;
    onSliderQuestionAnswered: (value: number, score: number) => void;
    onLikertQuestionAnswered: (value: number, score: number) => void;
    onMultipleChoiceQuestionAnswered: (value: number, score: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const OnboardingSurveyActivityQuestionStep = ({ step, onSliderQuestionAnswered, onLikertQuestionAnswered, onMultipleChoiceQuestionAnswered, isIntroAnimated, onIntroAnimationCompleted, onAnimationCompleted }: Props) => {
    const { displayType, questionId, question, answer } = step;

    const handleOnSubmit = (callback: (value: number, score: number) => void) => (value: number) => {
        callback(value, mapOnboardingSurveyValueToScore(displayType, value));
    };

    switch (displayType) {
        case DisplayType.Slider:
            return (
                <OnboardingSurveySliderQuestion
                    key={questionId}
                    question={question}
                    minLabel={step.minLabel}
                    maxLabel={step.maxLabel}
                    onChange={handleOnSubmit(onSliderQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.LikertScale:
            return (
                <OnboardingSurveyLikertQuestion
                    key={questionId}
                    question={question}
                    labels={step.labels}
                    onChange={handleOnSubmit(onLikertQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        case DisplayType.MultipleChoice:
            return (
                <OnboardingSurveyMultipleChoiceQuestion
                    key={questionId}
                    question={question}
                    labels={step.labels}
                    imageUrls={step.imageUrls}
                    onChange={handleOnSubmit(onMultipleChoiceQuestionAnswered)}
                    initialValue={answer}
                    isIntroAnimated={isIntroAnimated}
                    onIntroAnimationCompleted={onIntroAnimationCompleted}
                    onAnimationCompleted={onAnimationCompleted}
                />
            );
        default:
            throw new Error(`Display Type (${displayType}) not supported`);
    }
};

export default OnboardingSurveyActivityQuestionStep;