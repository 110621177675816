import type { AxiosRequestConfig } from "axios";
import type { StringifiableRecord } from "query-string";

import bearerFactory from "@core/bearer/bearerFactory";

import type { ApiClient, LoginClient } from "./ApiClient";
import ApiException from "./ApiException";

const retryIfAuthenticationFailsAsync = async<T>(apiPromise: () => Promise<T>): Promise<T> => {
    try {
        return await apiPromise();
    } catch (e) {
        if (e instanceof ApiException && e.response.status === 401) {
            await bearerFactory.refreshBearerTokenAsync();

            return await apiPromise();
        }

        throw e;
    }
};

export class AuthenticationRetryApiClientDecorator {
    private _apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this._apiClient = apiClient;
    }

    async getAsync<T>(relativeUrl: string, query?: StringifiableRecord, configuration?: AxiosRequestConfig) {
        const promise = () => this._apiClient.getAsync<T>(relativeUrl, query, configuration);

        return retryIfAuthenticationFailsAsync(promise);
    }

    async postAsync<T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) {
        const promise = () => this._apiClient.postAsync<T, D>(relativeUrl, body, configuration);

        return retryIfAuthenticationFailsAsync(promise);
    }

    async putAsync<T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) {
        const promise = () => this._apiClient.putAsync<T, D>(relativeUrl, body, configuration);

        return retryIfAuthenticationFailsAsync(promise);
    }

    async patchAsync<T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) {
        const promise = () => this._apiClient.patchAsync<T, D>(relativeUrl, body, configuration);

        return retryIfAuthenticationFailsAsync(promise);
    }

    async deleteAsync<T>(relativeUrl: string, configuration?: AxiosRequestConfig<unknown>) {
        const promise = () => this._apiClient.deleteAsync<T>(relativeUrl, configuration);

        return retryIfAuthenticationFailsAsync(promise);
    }
}

export class AuthenticationRetryLoginClientDecorator {
    private _loginClient: LoginClient;

    constructor(loginClient: LoginClient) {
        this._loginClient = loginClient;
    }

    async postLoginAsync<T, D>(relativeUrl: string, body?: D, configuration?: AxiosRequestConfig<D>) {
        const promise = () => this._loginClient.postLoginAsync<T, D>(relativeUrl, body, configuration);

        return retryIfAuthenticationFailsAsync(promise);
    }
}