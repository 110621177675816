import React from "react";

import classNames from "classnames";

import "./progress-bar-step.scss";

interface Props {
    className?: string;
    isCompleted: boolean;
    isInProgress: boolean;
}

const ProgressBarStep = ({ className, isCompleted, isInProgress }: Props) => {
    const classes = classNames(
        "progress-bar-step",
        className, {
            "progress-bar-step--in-progress": isInProgress,
            "progress-bar-step--completed": isCompleted
        }
    );

    return <div className={classes} />;
};

export default ProgressBarStep;