enum Feature {
    useMemberProfileLanguageDuringOnboardingActivity = 1141,
    notificationUrlWithToken = 4096,
    useLogRocket = 4104,
    pulseSurveyCustomTextualQuestions = 6152,
    multipleRecipientsGoodVibes = 6156,
    publicGoodVibes = 6163,
    customSurveyPermissionAlerts = 9232,
    useWorkleapBrand = 12026
}

export default Feature;
