import type { PulseSurveyQuestionStep } from "@/types/activities/pulse-survey";
import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";

import buildPulseSurveyQuestionStep from "./buildPulseSurveyQuestionStep";
import type { PulseSurveyQuestionId } from "./types";

const buildTestPulseSurveyQuestionActivity = (
    questionId: PulseSurveyQuestionId,
    t: TFunction<"activities">
) => {
    const askQuestionStep: PulseSurveyQuestionStep = {
        ...buildPulseSurveyQuestionStep(questionId, t),
        askFollowUpQuestion: true
    };

    const { constructiveFollowUpQuestion, positiveFollowUpQuestion } = askQuestionStep;

    const askFollowUpQuestionStep = {
        questionId: askQuestionStep.questionId,
        constructiveFollowUpQuestion,
        positiveFollowUpQuestion,
        stepType: PulseSurveyStepType.AskFollowUpQuestion,
        feedback: null,
        isAnonymous: true
    };

    return {
        activityType: ActivityType.TestPulseSurveyQuestion as const,
        correlationId: "test-correlation-id",
        steps: [askQuestionStep, askFollowUpQuestionStep],
        questionIds: [askQuestionStep.questionId],
        customizationWord: { sourceWord: "test", replacementWord: "test" },
        isPostSignupSurveyComplete: true,
        microCustomizations: []
    };
};

export default buildTestPulseSurveyQuestionActivity;
