import type { OnboardingSurveyFollowUpQuestionStep } from "@/types/activities/onboarding-survey";
import type { TFunction } from "react-i18next";

import ActivityType from "@core/enums/ActivityType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";

import buildOnboardingSurveyQuestionStep from "./buildOnboardingSurveyQuestionStep";
import type { OnboardingQuestionID } from "./types";

const buildTestOnboardingSurveyQuestionActivity = (
    questionId: OnboardingQuestionID,
    t: TFunction<"activities">
) => {
    const askQuestionStep = {
        ...buildOnboardingSurveyQuestionStep(questionId, t),
        askFollowUpQuestion: true
    };

    const { followUpQuestion } = askQuestionStep;

    const askFollowUpQuestionStep: OnboardingSurveyFollowUpQuestionStep = {
        questionId: askQuestionStep.questionId,
        followUpQuestion,
        stepType: OnboardingSurveyStepType.AskFollowUpQuestion
    };

    return {
        activityType: ActivityType.TestOnboardingSurvey as const,
        correlationId: "test-correlation-id",
        questionIds: [askQuestionStep.questionId],
        questionIdsWithFollowUp: [askQuestionStep.questionId],
        steps: [askQuestionStep, askFollowUpQuestionStep]
    };
};

export default buildTestOnboardingSurveyQuestionActivity;
