import React, { useState } from "react";

import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useDelayedEffect from "@hooks/useDelayedEffect";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import InteractionBox from "@components/interaction-box/InteractionBox";
import QuestionAskedBy from "@components/question-asked-by/QuestionAskedBy";

import { ReactComponent as OvAvatarLegacy } from "@svg/legacy/ov-avatar.svg";
import { ReactComponent as OvAvatar } from "@svg/ov-avatar.svg";

import "./smart-question.scss";

interface Props {
    className?: string;
    metricName: string | null;
    question: string | null;
    isAnswered: boolean;
    onSubmit: (feedback: string, isAnonymous: boolean) => void;
    onChangeIsAnonymous: (isAnonymous: boolean) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const SmartQuestion = ({ className, question, metricName, isAnswered, onSubmit, onChangeIsAnonymous, isIntroAnimated, onIntroAnimationCompleted, onAnimationCompleted }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const { t } = useTranslation("activities");
    const { imageUrl, fullName } = useAuthenticatedUser();
    const [confirmed, setConfirmed] = useState(false);
    const [isAnsweredSnapshot] = useState(isAnswered);

    useDelayedEffect(() => {
        if (isIntroAnimated) {
            onIntroAnimationCompleted();
        }
    }, [], 1300);

    const handleOnConfirm = async(feedback: string, isAnonymous: boolean) => {
        if (confirmed) {
            return;
        }

        setConfirmed(true);

        onSubmit(feedback, isAnonymous);
        onAnimationCompleted();
    };

    const classes = classNames(
        "smart-question",
        className, {
            "smart-question--animated-intro": isIntroAnimated,
            "smart-question--confirmed": confirmed
        }
    );

    return (
        <div className={classes}>
            <div className="smart-question__prompt">
                <Trans ns="activities"
                    i18nKey={t("smartQuestionsFrame.prompt", { metricName })}
                    components={[
                        <a key={0} className="smart-question__link" href={t("smartQuestionsFrame.help-center-how-scores-are-calculated-url")} target="_blank" rel="noreferrer" aria-label={t("smartQuestionsFrame.help-center-how-scores-are-calculated-link-aria-label")}>REPLACED BY I18N</a>
                    ]}
                />
            </div>
            <QuestionAskedBy
                className="smart-question__asked-by"
                question={question}
                icon={isWorkleapBrandEnabled ? <OvAvatar /> : <OvAvatarLegacy />}
            />
            <div className="smart-question__input">
                <InteractionBox
                    authorImageUrl={imageUrl}
                    authorFullName={fullName}
                    placeholder={t("smartQuestionsFrame.reply-input-placeholder")}
                    submitLabel={isAnsweredSnapshot ? t("smartQuestionsFrame.update-feedback-button-label") : t("smartQuestionsFrame.send-feedback-button-label")}
                    onChangeIsAnonymous={onChangeIsAnonymous}
                    onSubmit={handleOnConfirm}
                    disabled={confirmed}
                />
            </div>
        </div>
    );
};

export default SmartQuestion;