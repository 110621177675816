import RecognitionCategory from "@core/enums/RecognitionCategory";
import RecognitionCategoryTheme from "@core/enums/RecognitionCategoryTheme";

export default function getThemeFromCategoryId(categoryId: RecognitionCategory): RecognitionCategoryTheme {
    switch (categoryId) {
        case RecognitionCategory.Leadership:
            return RecognitionCategoryTheme.Creamsicle;
        case RecognitionCategory.TeamPlayer:
            return RecognitionCategoryTheme.Seaweed;
        case RecognitionCategory.Positivity:
            return RecognitionCategoryTheme.Dandelion;
        case RecognitionCategory.Excellence:
            return RecognitionCategoryTheme.ElectricBlue;
        case RecognitionCategory.ShowingUp:
            return RecognitionCategoryTheme.Sky;
        default:
            throw new Error(`Recognition Category (${categoryId}) not supported`);
    }
}