const allGeneratedPeers = [
    {
        userId: "048e6367-97aa-4cef-8cd6-1ec68af66fdd",
        firstName: "Carole",
        lastName: "Stevens",
        fullName: "Carole Stevens",
        jobTitle: "Team Manager",
        imageUrl: "https://randomuser.me/api/portraits/women/63.jpg",
        email: "carole.stevens1@email.com"
    },
    {
        userId: "048e6367-97aa-4cef-8cd6-1ec68af66fdc",
        firstName: "Carole",
        lastName: "Stevens",
        fullName: "Carole Stevens",
        jobTitle: "Team Manager",
        imageUrl: "https://randomuser.me/api/portraits/women/62.jpg",
        email: "carole.stevens2@email.com"
    },
    {
        userId: "c13d3037-064e-4751-8fbc-d2c1f6535c67",
        firstName: "Harold",
        lastName: "Owens",
        fullName: "Harold Owens",
        jobTitle: "Executive Manager",
        imageUrl: "https://randomuser.me/api/portraits/men/44.jpg",
        email: "harold.owens@email.com"
    },
    {
        userId: "cbef629f-a3b8-42c0-beca-dc64b06b85b4",
        firstName: "Jamie",
        lastName: "Patterson",
        fullName: "Jamie Patterson",
        jobTitle: "Admin",
        imageUrl: "https://randomuser.me/api/portraits/women/12.jpg",
        email: "jamie.patterson@email.com"
    },
    {
        userId: "dceaa25b-c339-431a-9622-4dc6ed637e53",
        firstName: "Sebastian",
        lastName: "Arnold",
        fullName: "Sebastian Arnold",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/men/49.jpg",
        email: "sebastian.arnold@email.com"
    },
    {
        userId: "5e63dc7c-1bcd-4e48-9c7f-03d6eed33b68",
        firstName: "Shawn",
        lastName: "Mitchelle",
        fullName: "Shawn Mitchelle",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/men/46.jpg",
        email: "shawn.mitchelle@email.com"
    },
    {
        userId: "534b7017-fa3c-468a-91f5-57e9e375fb64",
        firstName: "Shelly",
        lastName: "Powell",
        fullName: "Shelly Powell",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/women/27.jpg",
        email: "shelly.powell@email.com"
    },
    {
        userId: "1d6acc6e-372c-4578-9ddd-567a840c049d",
        firstName: "Sherri",
        lastName: "Mccoy",
        fullName: "Sherri Mccoy",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/women/5.jpg",
        email: "sherri.mccoy@email.com"
    },
    {
        userId: "36a12073-a989-418c-b91a-0007ccf347ee",
        firstName: "Travis",
        lastName: "Jacobs",
        fullName: "Travis Jacobs",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/men/31.jpg",
        email: "travis.jacobs@email.com"
    },
    {
        userId: "3347489a-feca-4e95-9c80-f96599b1a19e",
        firstName: "Victor",
        lastName: "Carroll",
        fullName: "Victor Carroll",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/men/91.jpg",
        email: "victor.carroll@email.com"
    },
    {
        userId: "8cd207ee-e341-458a-b2c7-858603033f3b",
        firstName: "Zoey",
        lastName: "Dunn",
        fullName: "Zoey Dunn",
        jobTitle: "Member",
        imageUrl: "https://randomuser.me/api/portraits/women/28.jpg",
        email: "zoey.dunn@email.com"
    }
];

export function searchPeers(query: string | null) {
    if (!query) {
        return allGeneratedPeers;
    }

    const toSearchable = (str: string) => str.normalize("NFD").toLowerCase();

    const searchQuery = toSearchable(query);

    return allGeneratedPeers.filter(x =>
        toSearchable(x.fullName).includes(searchQuery)
    );
}
