import type { TFunction } from "react-i18next";

import type { RecognitionActivityResponse } from "@api/RecognitionActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import RecognitionStepType from "@core/enums/RecognitionStepType";
import getCategoryNameFromCategoryId from "@core/utils/getCategoryNameFromCategoryId";
import getImageFromCategoryId from "@core/utils/getImageFromCategoryId";
import getPromptNameFromPromptId from "@core/utils/getPromptNameFromPromptId";
import getThemeFromCategoryId from "@core/utils/getThemeFromCategoryId";

import "@core/utils/linqExtensions";

const buildRecognitionActivity = (activity: RecognitionActivityResponse, withIntro: boolean, t: TFunction<"activities">) => {
    const shuffledActivePrompts = activity.officevibePrompts.map(p => ({
        ...p,
        categoryImage: getImageFromCategoryId(p.categoryId),
        categoryName: getCategoryNameFromCategoryId(t, p.categoryId),
        categoryTheme: getThemeFromCategoryId(p.categoryId),
        prompt: getPromptNameFromPromptId(t, p.id)
    }))
        .concat(activity.prompts)
        .randomize();

    return {
        ...activity,
        activityType: ActivityType.Recognition as const,
        shuffledActivePrompts,
        steps: [
            withIntro && ({
                stepType: RecognitionStepType.Intro
            }),
            {
                stepType: RecognitionStepType.SelectPrompt
            },
            {
                stepType: RecognitionStepType.CustomizeMessage
            }
        ].filter(x => !!x)
    };
};

export default buildRecognitionActivity;