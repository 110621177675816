import React from "react";

import Select from "@components/select/Select";

import SupportedDashboardLanguage from "@core/enums/SupportedDashboardLanguage";

const options = [
    SupportedDashboardLanguage.English,
    SupportedDashboardLanguage.French
];

const getLabel = (option: SupportedDashboardLanguage) => {
    switch (option) {
        case SupportedDashboardLanguage.English:
            return "English (U.S.)";
        case SupportedDashboardLanguage.French:
            return "Français (Canada)";
        default:
            throw new Error(`Language (${option}) not supported`);
    }
};

interface Props {
    className?: string;
    value: SupportedDashboardLanguage;
    onChange: (value: SupportedDashboardLanguage) => void;
}

const DashboardLanguageSelect = ({ className, value, onChange }: Props) => {
    const renderOption = (
        option: SupportedDashboardLanguage,
        isKeyboardFocused: boolean,
        onClick: React.MouseEventHandler<HTMLButtonElement>
    ) =>
        (
            <Select.Option
                key={option}
                onClick={onClick}
                selected={value === option}
                isKeyboardFocused={isKeyboardFocused}
            >
                {getLabel(option)}
            </Select.Option>
        );

    return (
        <Select<SupportedDashboardLanguage>
            className={className}
            value={value}
            renderValue={getLabel}
            options={options}
            renderOption={renderOption}
            onChange={onChange}
        />
    );
};

export default DashboardLanguageSelect;
