import type { BuiltOnboardingSurveyActivity, OnboardingSurveyFollowUpQuestionStep } from "@/types/activities/onboarding-survey";
import upperFirst from "lodash/upperFirst";
import type { TFunction } from "react-i18next";

import type { OnboardingSurveyActivityResponse } from "@api/OnboardingSurveyActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";

import buildOnboardingSurveyQuestionStep from "./buildOnboardingSurveyQuestionStep";

const buildOnboardingSurveyQuestions = (activity: OnboardingSurveyActivityResponse, t: TFunction<"activities">) => {
    return activity.questionIds
        .map(questionId => buildOnboardingSurveyQuestionStep(questionId, t))
        .map(q => ({
            ...q,
            question: upperFirst(q.question),
            followUpQuestion: upperFirst(q.followUpQuestion),
            askFollowUpQuestion: activity.questionIdsWithFollowUp.includes(q.questionId)
        }));
};

const buildOnboardingSurveyActivity = (activity: OnboardingSurveyActivityResponse, t: TFunction<"activities">): BuiltOnboardingSurveyActivity => {
    const questions = buildOnboardingSurveyQuestions(activity, t);

    const questionSteps = questions
        .map(askQuestionStep => {
            const { followUpQuestion } = askQuestionStep;

            if (askQuestionStep.askFollowUpQuestion) {
                const askFollowUpQuestionStep: OnboardingSurveyFollowUpQuestionStep = {
                    questionId: askQuestionStep.questionId,
                    followUpQuestion,
                    stepType: OnboardingSurveyStepType.AskFollowUpQuestion
                };

                return [askQuestionStep, askFollowUpQuestionStep];
            }

            return askQuestionStep;
        })
        .flat();

    return {
        ...activity,
        activityType: ActivityType.OnboardingSurvey as const,
        steps: questionSteps
    } ;
};

export default buildOnboardingSurveyActivity;