import React, { useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { type CountryCallingCode, type CountryCode, parsePhoneNumberFromString } from "libphonenumber-js/mobile";
import { useTranslation } from "react-i18next";

import Input from "@components/input/Input";

import CountrySelect from "./CountrySelect";

import "./phone-input.scss";

interface Props {
    className?: string;
    value: string | null;
    onChange: (value: string) => void;
    preferredCountryCodes?: CountryCode[];
    autoFocus?: boolean;
    error?: boolean;
}

const PhoneInput = ({ className, value, onChange, preferredCountryCodes = ["US", "CA"], autoFocus, error }: Props) => {
    const { t } = useTranslation("activities");
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    const phoneData = useMemo(() => {
        if (!value) {
            return null;
        }

        return parsePhoneNumberFromString(value);
    }, [value]);

    const [country, setCountry] = useState(phoneData?.country || "US");
    const [countryCallingCode, setCountryCallingCode] = useState(phoneData ? `+${phoneData.countryCallingCode}` : "+1");
    const [phone, setPhone] = useState(phoneData ? (phoneData.nationalNumber || "") : ((value || "")?.replace("+", "")));

    const formattedPhoneNumber = phoneData
        ? phoneData.formatNational()
        : null;

    const showPreview = !isFocused && !!phoneData;

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        // Strip all characters that are not numbers or +
        const newValue = e.target.value.replace(/[^\d+]/g, "");

        const result = newValue && newValue.startsWith("+")
            ? newValue
            : `${countryCallingCode}${newValue}`;

        const newPhoneData = parsePhoneNumberFromString(result);

        if (newPhoneData && newPhoneData.country) {
            setCountry(newPhoneData.country);
            setCountryCallingCode(`+${newPhoneData.countryCallingCode}`);
            setPhone(newPhoneData.nationalNumber);
            onChange(`+${newPhoneData.countryCallingCode}${newPhoneData.nationalNumber}`);

            return;
        }

        setPhone(newValue);
        onChange(newValue);
    };

    const handleOnCallingCodeChange = (newCountry: CountryCode, newCountryCallingCode: CountryCallingCode) => {
        setCountry(newCountry);
        setCountryCallingCode(newCountryCallingCode);
        onChange(`${newCountryCallingCode}${phone.replace(newCountryCallingCode, "").replace("+", "")}`);
    };

    const classes = classNames(
        "phone-input",
        className, {
            "phone-input--focused": isFocused,
            "phone-input--previewing": showPreview,
            "phone-input--error": error
        }
    );

    return (
        <div className={classes}>
            <CountrySelect className="phone-input__country-select"
                value={country}
                preferredCountryCodes={preferredCountryCodes}
                onChange={handleOnCallingCodeChange}
                tabIndex={-1}
            />
            <Input className="phone-input__phone-number-input"
                ref={inputRef}
                type="text"
                value={phone}
                placeholder={t("onboarding.mobilePhoneFieldPlacholder")}
                onChange={handleOnChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                autoFocus={autoFocus}
            />
            {showPreview && (
                <button type="button" className="phone-input__readonly-phone-number" onClick={() => inputRef.current?.focus()}>
                    {formattedPhoneNumber}
                </button>
            )}
        </div>
    );
};

export default PhoneInput;