import React from "react";

import { useTranslation } from "react-i18next";

import Select from "@components/select/Select";

import DeliveryChannel from "@core/enums/DeliveryChannel";

interface Props {
    className?: string;
    value: DeliveryChannel;
    defaultDeliveryChannel: DeliveryChannel;
    onChange: (value: DeliveryChannel) => void;
}

const DeliveryChannelSelect = ({ className, value, defaultDeliveryChannel, onChange }: Props) => {
    const { t } = useTranslation("activities");

    const options = [
        defaultDeliveryChannel,
        DeliveryChannel.PersonalEmail,
        DeliveryChannel.Sms
    ];

    const getLabel = (option: DeliveryChannel) => t(`onboarding.deliveryChannel${option}`);

    const renderOption = (option: DeliveryChannel, isKeyboardFocused: boolean, onClick: React.MouseEventHandler<HTMLButtonElement>) => {
        return (
            <Select.Option key={option} onClick={onClick} selected={value === option} isKeyboardFocused={isKeyboardFocused}>
                {getLabel(option)}
            </Select.Option>
        );
    };

    return (
        <Select<DeliveryChannel>
            className={className}
            value={value}
            renderValue={getLabel}
            options={options}
            renderOption={renderOption}
            onChange={onChange}
        />
    );
};

export default DeliveryChannelSelect;