import React from "react";

import classNames from "classnames";

import "./signup-slide-title-header.scss";

interface Props {
    className?: string;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
}

const SignupSlideTitleHeader = ({ className, title, subtitle }: Props) => {
    const classes = classNames(
        "signup-slide-title-header",
        className
    );

    return (
        <div className={classes}>
            <div className="signup-slide-title-header__title">
                {title}
            </div>
            {subtitle && (
                <div className="signup-slide-title-header__subtitle">
                    {subtitle}
                </div>
            )}
        </div>
    );
};

export default SignupSlideTitleHeader;