import React from "react";

import classNames from "classnames";

import OfficevibeLoadingWave from "@components/loading-wave/OfficevibeLoadingWave";

import EmptyLayout from "@layouts/empty/EmptyLayout";

import "./loading-page.scss";

interface Props {
    className?: string;
}

const LoadingPage = ({ className }: Props) => {
    const classes = classNames(
        "loading-page",
        className
    );

    return (
        <EmptyLayout className={classes}>
            <OfficevibeLoadingWave />
        </EmptyLayout>
    );
};

export default LoadingPage;