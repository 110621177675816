import React from "react";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { appInsights, appInsightsReactPlugin } from "@core/applicationInsights/applicationInsights";

interface Props {
    children: React.ReactNode;
}

const ApplicationInsightsContextProvider = ({ children }: Props) => {
    if (!appInsights) {
        return children;
    }

    return (
        <AppInsightsContext.Provider value={appInsightsReactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

export default ApplicationInsightsContextProvider;