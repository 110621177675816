import React from "react";

import type TipType from "@core/enums/TipType";

interface Context {
    isInitialized: boolean;
    isLoading: boolean;
    tips: TipType[];
    initializeTipsContextAsync: () => Promise<void>;
    dismiss: (type: TipType) => void;
}

const TipsContext = React.createContext<Context | null>(null);

export default TipsContext;

export const useTipsContext = () => {
    const context = React.useContext(TipsContext);

    if (!context) {
        throw new Error("TipsContext not found; are you missing TipsContextProvider?");
    }

    return context;
};