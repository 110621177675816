import React from "react";

interface Props {
    className?: string;
    prompt: string;
}

const RecognitionPrompt = ({ className, prompt }: Props) => (
    <span className={className}>{prompt}</span>
);

export default RecognitionPrompt;
