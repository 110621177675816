import QuestionDisplayType from "./QuestionDisplayType";

type CustomPollQuestionDisplayType =
    | QuestionDisplayType.OpinionScale
    | QuestionDisplayType.MultipleChoice
    | QuestionDisplayType.Text
    | QuestionDisplayType.LikertScale;

const CustomPollQuestionDisplayType = {
    [QuestionDisplayType.OpinionScale]: QuestionDisplayType.OpinionScale,
    [QuestionDisplayType.MultipleChoice]: QuestionDisplayType.MultipleChoice,
    [QuestionDisplayType.Text]: QuestionDisplayType.Text,
    [QuestionDisplayType.LikertScale]: QuestionDisplayType.LikertScale
} as const;

export default CustomPollQuestionDisplayType;
