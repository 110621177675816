import React from "react";

import classNames from "classnames";

import "./peer-select-option-loading.scss";

interface Props {
    className?: string;
}

const PeerSelectOptionLoading = ({ className }: Props) => {
    const classes = classNames(
        "peer-select-option-loading",
        className
    );

    return (
        <div className={classes}>
            <div className="peer-select-option-loading__thumbnail" />
            <div className="peer-select-option-loading__fullname" />
        </div>
    );
};

export default PeerSelectOptionLoading;