import type ImageSizes from "@components/user-image/ImageSizes";
import WorkleapImageSizes, { mapOvImageSizeToWorkleap } from "@components/user-image/WorkleapImageSizes";

import { enumValues } from "@core/utils/enum";

const useImageResizeUrl = (imageUrl: string | null | undefined, requestedSize: ImageSizes) => {
    if (!imageUrl) {
        return imageUrl;
    }

    if (imageUrl.includes("/user/")) {
        const resizedUrl = imageUrl.replace("/user/", "/user-resized/");
        const imageExtension = resizedUrl.split(".").pop();

        return resizedUrl.replace(`.${imageExtension}`, `_w${requestedSize}_h${requestedSize}.${imageExtension}`);
    } else if (window.env.WORKLEAP_PLATFORM_CDN_URL && imageUrl.startsWith(window.env.WORKLEAP_PLATFORM_CDN_URL)) {
        // Workleap's member avatar handling for migrated/new Workleap networks (have different avatar sizes)
        let mappedWorkleapSize: ImageSizes | WorkleapImageSizes | null = requestedSize;
        const validSizes = enumValues(WorkleapImageSizes) as string[];
        const hasRequestedSize = validSizes.includes(requestedSize.toString());

        if (!hasRequestedSize) {
            // Map original OV avatar image size to the Workleap's avatar image size
            mappedWorkleapSize = mapOvImageSizeToWorkleap(requestedSize);
        }

        if (mappedWorkleapSize) {
            const imageExt = imageUrl.split(".").pop();

            return imageUrl.replace(`.${imageExt}`, `_${mappedWorkleapSize}x${mappedWorkleapSize}.${imageExt}`);
        }
    }

    return imageUrl;
};

export default useImageResizeUrl;