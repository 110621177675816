import { useState } from "react";

import { useLocation } from "react-router-dom";

import useDelayedEffect from "@hooks/useDelayedEffect";

import { useUserContext } from "@contexts/user/UserContext";

import { trackApplicationStarted } from "@core/tracking/track";

const ApplicationStartedTracking = () => {
    const { isLoading: isUserContextLoading, context: userContext } = useUserContext();
    const { pathname, search } = useLocation();
    const [isApplicationStartedTracked, setIsApplicationStartedTracked] = useState(false);

    useDelayedEffect(() => {
        if (!isApplicationStartedTracked && !isUserContextLoading && userContext) {
            setIsApplicationStartedTracked(true);

            trackApplicationStarted(userContext.isNetworkAdmin, userContext.isCompanyManager, userContext.isTeamManager);
        }
    }, [isUserContextLoading, userContext, pathname, search], 1000);

    return null;
};

export default ApplicationStartedTracking;