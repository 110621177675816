import React, { useEffect, useState } from "react";

import { AngleLeftIcon, AngleRightIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import GhostButton from "@components/button/GhostButton";
import PrimaryButton from "@components/button/PrimaryButton";

import ActivityControlBarStepType from "@core/enums/ActivityControlBarStepType";
import ActivityControlBarTheme from "@core/enums/ActivityControlBarTheme";

import ControlBarTrackedQuestions from "./ControlBarTrackedQuestions";

import "./activities-control-bar.scss";

interface ActivityStep {
    type: ActivityControlBarStepType;
    isInProgress: boolean;
    questions?: React.ComponentPropsWithoutRef<typeof ControlBarTrackedQuestions>["questions"];
}

interface ControlBarSnapshot {
    showPreviousButton: boolean;
    showSkipButton: boolean;
    showNextButton: boolean;
    theme?: ActivityControlBarTheme;
}

interface Props {
    snapshotRefreshKey: number;
    className?: string;
    theme?: ActivityControlBarTheme;
    activities?: ActivityStep[];
    isHidden: boolean;
    showPreviousButton: boolean;
    onPreviousClick: () => void;
    showSkipButton: boolean;
    onSkipClick: () => void;
    showNextButton: boolean;
    onNextClick: () => void;
    disableNextButton?: boolean;
    areInteractionsDisabled?: boolean;
}

const ActivitiesControlBar = ({
    snapshotRefreshKey,
    className,
    theme,
    activities,
    isHidden,
    showPreviousButton,
    onPreviousClick,
    showSkipButton,
    onSkipClick,
    showNextButton,
    onNextClick,
    disableNextButton,
    areInteractionsDisabled
}: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );

    // Do not display the component if it is hidden for the first step displayed.
    // Otherwise, the hidden animation will play even if the user should not see the component.
    const [isDisplayed, setIsDisplayed] = useState(!isHidden);
    const [controlBarSnapshot, setControlBarSnapshot] = useState<ControlBarSnapshot>({
        showPreviousButton,
        showSkipButton,
        showNextButton,
        theme
    });

    useEffect(() => {
        // Keep the control bar state until the transition has completed, then the snapshot key will be updated
        setControlBarSnapshot({
            showPreviousButton,
            showSkipButton,
            showNextButton,
            theme
        });
    }, [snapshotRefreshKey, showSkipButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isHidden && !isDisplayed) {
            setIsDisplayed(true);
        }
    }, [isHidden, isDisplayed]);

    const renderFlowStep = (step: ActivityStep, index: number) => {
        if (!step.isInProgress) {
            return null;
        }

        switch (step.type) {
            case ActivityControlBarStepType.None:
                return null;

            case ActivityControlBarStepType.TrackedQuestionsStep:
                return (
                    <ControlBarTrackedQuestions
                        {...step}
                        key={index}
                        className="activities-control-bar__flow-step"
                    />
                );

            default:
                throw new Error(`Step Type (${step.type}) not supported`);
        }
    };

    if (!isDisplayed) {
        return null;
    }

    const classes = classNames(
        "activities-control-bar",
        `activities-control-bar--${controlBarSnapshot.theme}`,
        className,
        {
            "activities-control-bar--hidden": isHidden
        }
    );

    const skipButton =
        theme === ActivityControlBarTheme.Floating ? (
            <GhostButton
                className="activities-control-bar__skip-button"
                iconTrailing={<AngleRightIcon size="lg" />}
                theme={
                    isWorkleapBrandEnabled
                        ? GhostButton.Theme.Dark
                        : GhostButton.Theme.Light
                }
                onClick={onSkipClick}
                disabled={areInteractionsDisabled}
                showOnlyIconOnMobile={!isWorkleapBrandEnabled}
            >
                {t("pulseSurveyFrame.progress-bar-skip")}
            </GhostButton>
        ) : (
            <PrimaryButton
                iconTrailing={<AngleRightIcon size="md" />}
                onClick={onSkipClick}
                disabled={areInteractionsDisabled}
                showOnlyIconOnMobile
            >
                {t("pulseSurveyFrame.progress-bar-skip")}
            </PrimaryButton>
        );

    return (
        <div className={classes}>
            <div className="activities-control-bar__left-column">
                {controlBarSnapshot.showPreviousButton && (
                    <PrimaryButton
                        iconLeading={<AngleLeftIcon size="md" />}
                        onClick={onPreviousClick}
                        showOnlyIconOnMobile
                    >
                        {t("pulseSurveyFrame.progress-bar-previous")}
                    </PrimaryButton>
                )}
            </div>
            <div className="activities-control-bar__middle-column">
                {!isHidden && (
                    <div className="activities-control-bar__flow-steps">
                        {activities?.map(renderFlowStep)}
                    </div>
                )}
            </div>
            <div className="activities-control-bar__right-column">
                {controlBarSnapshot.showSkipButton && skipButton}
                {controlBarSnapshot.showNextButton && (
                    <PrimaryButton
                        iconTrailing={<AngleRightIcon size="md" />}
                        onClick={onNextClick}
                        disabled={disableNextButton}
                        showOnlyIconOnMobile
                    >
                        {t("pulseSurveyFrame.progress-bar-next")}
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default ActivitiesControlBar;
