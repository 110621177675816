import type { AxiosRequestConfig } from "axios";

import bearerFactory from "@core/bearer/bearerFactory";

import { buildClient, buildLoginClient } from "./ApiClient";

export const buildBearerApiClient = (baseUrl: string, clientConfiguration?: AxiosRequestConfig<unknown>) => {
    return buildClient(baseUrl, clientConfiguration, c => {
        c.interceptors.request.use(bearerFactory.injectBearerToken.bind(bearerFactory));

        return c;
    });
};

export const buildBearerLoginClient = (baseUrl: string, clientConfiguration?: AxiosRequestConfig<unknown>) => {
    return buildLoginClient(baseUrl, clientConfiguration, c => {
        c.interceptors.request.use(bearerFactory.injectBearerToken.bind(bearerFactory));

        return c;
    });
};