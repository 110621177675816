import React from "react";

import classNames from "classnames";

import useDelayedEffect from "@hooks/useDelayedEffect";

import SliderQuestion from "@components/slider-question/SliderQuestion";

import BaseDeibSurveyQuestion from "./BaseDeibSurveyQuestion";

import "./deib-survey-slider-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onChange: (value: number) => void;
    onAnimationCompleted: () => void;
    onIntroAnimationCompleted: () => void;
}

const DeibSurveySliderQuestion = ({
    className,
    question,
    initialValue,
    minLabel,
    maxLabel,
    onChange,
    onAnimationCompleted,
    onIntroAnimationCompleted
}: Props) => {
    useDelayedEffect(() => {
        onIntroAnimationCompleted();
    }, [], 900);

    const classes = classNames(
        "deib-survey-slider-question",
        className,
        "deib-survey-slider-question--animated-intro"
    );

    return (
        <BaseDeibSurveyQuestion className={classes} question={question}>
            <SliderQuestion className="deib-survey-slider-question__slider"
                initialValue={initialValue}
                minLabel={minLabel}
                maxLabel={maxLabel}
                onChange={onChange}
                onAnimationCompleted={onAnimationCompleted}
            />
        </BaseDeibSurveyQuestion>
    );
};

export default DeibSurveySliderQuestion;