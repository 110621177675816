import React from "react";

interface Context {
    timer: number;
    onTick: () => void;
}

const LoadingContext = React.createContext<Context | null>(null);

export default LoadingContext;

export const useLoadingContext = () => {
    const context = React.useContext(LoadingContext);

    if (!context) {
        throw new Error("LoadingContext not found; are you missing LoadingContextProvider?");
    }

    return context;
};