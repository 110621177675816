import type {
    BuiltCustomPollActivity,
    CustomPollStep
} from "@/types/activities/custom-polls";

import CustomPollQuestionDisplayType from "@core/enums/CustomPollQuestionDisplayType";
import CustomPollStepType from "@core/enums/CustomPollStepType";

function insertAnswerFieldFor(step: CustomPollStep) {
    const { stepType } = step;

    switch (stepType) {
        case CustomPollStepType.Intro:
            return {};

        case CustomPollStepType.AskQuestion:
            if (step.displayType === CustomPollQuestionDisplayType.Text) {
                return {
                    feedback: null,
                    isAnonymous: true,
                    isAnswered: false
                };
            }

            return { value: null };

        case CustomPollStepType.AskFollowUpQuestion:
            return { feedback: null, isAnonymous: true, isAnswered: false };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

const buildCustomPollActivityOrchestrator = (
    activity: BuiltCustomPollActivity,
    isFirstActivity: boolean,
    isLastActivity: boolean
) => {
    if (!activity.steps || activity.steps.length === 0) {
        throw new Error("Cannot orchestrate a custom poll with no steps");
    }

    return {
        ...activity,
        isFirstActivity,
        isLastActivity,
        currentStepIndex: 0,
        steps: activity.steps.map((s, i) => ({
            ...s,
            isFirstStep: i === 0,
            isLastStep: i === activity.steps.length - 1,
            ...insertAnswerFieldFor(s)
        }))
    };
};

export default buildCustomPollActivityOrchestrator;
