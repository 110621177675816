/*eslint-disable*/
function intercom() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    }
    else {
        var d = document;
        var i = function() {
            i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
            i.q.push(args);
        };
        w.Intercom = i;

        // This section is slightly adapted from the default snippet from Intercom, as we
        // don't want to tie the loading to the page load event.
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + window.env.INTERCOM_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s,x);
    }
}

export const startIntercom = (name, email, userId, userHash, otherProperties) => {
    window.intercomSettings = {
        ...otherProperties,
        app_id: window.env.INTERCOM_ID,
        name,
        email,
        user_id: userId,
        user_hash: userHash,
        hide_default_launcher: true
      };

    intercom();
};

export const trackIntercomEvent = (event) => {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic('trackEvent', event);
    }
};

/*eslint-enable*/