import React, { useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import MultipleChoiceConstants from "@core/enums/CustomPollMultipleChoiceQuestion";
import type InputType from "@core/enums/InputType";
import KeyCodes from "@core/enums/KeyCodes";

import MultipleChoiceQuestionChoice from "../MultipleChoiceQuestionChoice";
import CustomChoiceInput from "./CustomChoiceInput";

import "./multiple-choice-question-custom-choice.scss";

interface Props {
    inputType: InputType;
    initialCustomAnswer: string | null;
    isInEditMode: boolean;
    onConfirmAnswer: (answer: string) => void;
    onClearAnswer: () => void;
    onToggleEditMode: (isInEditMode: boolean) => void;
    isSelected: boolean;
    isConfirmed: boolean;
}

const MultipleChoiceQuestionCustomChoice = ({ inputType, initialCustomAnswer, isInEditMode, onConfirmAnswer, onClearAnswer, onToggleEditMode, isSelected, isConfirmed }: Props) => {
    const { t } = useTranslation("activities");

    const [isFocused, setIsFocused] = useState(false);

    const handleOnBlur = () => {
        setIsFocused(false);
        onToggleEditMode(false);
    };

    const handleEditOnEnter: React.KeyboardEventHandler = e => {
        if (e.keyCode === KeyCodes.Enter) {
            onToggleEditMode(true);
        }
    };

    const handleEditOnClick: React.MouseEventHandler = e => {
        e.preventDefault();

        onToggleEditMode(true);
    };

    const handleClearInput = () => {
        onClearAnswer();
    };

    const handleSubmitAnswer = (newAnswer: string) => {
        onConfirmAnswer(newAnswer);
    };

    const classes = classNames("multiple-choice-question-custom-choice", {
        "multiple-choice-question-custom-choice--confirmed": isConfirmed
    });

    return (
        <div className={classes} role={inputType} aria-checked={isInEditMode}>
            {!isInEditMode && (
                <MultipleChoiceQuestionChoice
                    inputType={inputType}
                    value={MultipleChoiceConstants.CustomChoiceId}
                    label={initialCustomAnswer || t("customPollFrame.custom-poll-custom-answer-default-label")}
                    isConfirmed={isConfirmed}
                    isSelected={isSelected}
                    isFocused={isFocused}
                    checked={isFocused}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleOnBlur}
                    onKeyDown={handleEditOnEnter}
                    onClick={handleEditOnClick}
                />
            )}
            {isInEditMode && (
                <CustomChoiceInput
                    answer={initialCustomAnswer}
                    onClearInput={handleClearInput}
                    onConfirm={handleSubmitAnswer}
                />
            )}
        </div>
    );
};

export default MultipleChoiceQuestionCustomChoice;