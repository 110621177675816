import type { OrchestratedActivity } from "@/types/activities/common";

import ActivityType from "@core/enums/ActivityType";
import CustomPollQuestionDisplayType from "@core/enums/CustomPollQuestionDisplayType";
import CustomPollStepType from "@core/enums/CustomPollStepType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";
import TipType from "@core/enums/TipType";

export default function getCurrentTip(activity: OrchestratedActivity | null) {
    if (!activity) {
        return TipType.None;
    }

    switch (activity.activityType) {
        case ActivityType.PulseSurvey:
        case ActivityType.ContinuePulseSurvey:
        {
            const pulseSurveyStep = activity.steps[activity.currentStepIndex];

            return pulseSurveyStep.stepType === PulseSurveyStepType.AskFollowUpQuestion
                ? TipType.Anonymity
                : TipType.None;
        }

        case ActivityType.CustomPoll:
        {
            const customPollStep = activity.steps[activity.currentStepIndex];

            return customPollStep.stepType === CustomPollStepType.AskFollowUpQuestion || (customPollStep.stepType === CustomPollStepType.AskQuestion && customPollStep.displayType === CustomPollQuestionDisplayType.Text)
                ? TipType.Anonymity
                : TipType.None;
        }

        case ActivityType.SmartQuestion:
            return TipType.Anonymity;
    }

    return TipType.None;
}