import React, { useEffect } from "react";

import type {
    CustomPollActivityType,
    CustomPollMultipleChoiceAnswer,
    OrchestratedCustomPollFollowUpQuestionStep,
    OrchestratedCustomPollIntroStep,
    OrchestratedCustomPollLikertScaleQuestionStep,
    OrchestratedCustomPollMultipleChoiceQuestionStep,
    OrchestratedCustomPollOpinionScaleQuestionStep,
    OrchestratedCustomPollQuestionStep,
    OrchestratedCustomPollStep,
    OrchestratedCustomPollTextQuestionStep
} from "@/types/activities/custom-polls";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import type { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import CustomPollFollowUpQuestion from "@components/custom-poll-question/CustomPollFollowUpQuestion";
import CustomPollIntro from "@components/custom-poll-question/CustomPollIntro";
import CustomPollLikertScaleQuestion from "@components/custom-poll-question/CustomPollLikertScaleQuestion";
import CustomPollMultipleChoiceQuestion from "@components/custom-poll-question/CustomPollMultipleChoiceQuestion";
import CustomPollMultipleChoiceQuestionWithMultiSelection from "@components/custom-poll-question/CustomPollMultipleChoiceQuestionWithMultiSelection";
import CustomPollOpinionScaleQuestion from "@components/custom-poll-question/CustomPollOpinionScaleQuestion";
import CustomPollTextQuestion from "@components/custom-poll-question/CustomPollTextQuestion";

import type { CustomPollDetails } from "@api/CustomPollsActivityApiClient";
import ActivityType from "@core/enums/ActivityType";
import DisplayType from "@core/enums/CustomPollQuestionDisplayType";
import CustomPollStepType from "@core/enums/CustomPollStepType";
import { trackActivityCompleted, trackActivityStarted, trackFeedbackGiven, trackFeedbackShown, trackLinkClicked, trackQuestionAnswered } from "@core/tracking/track";
import TrackingInteractionType from "@core/tracking/TrackingInteractionType";

interface Props {
    className?: string;
    activityType: CustomPollActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    customPoll: CustomPollDetails;
    customPollId: string;
    steps: OrchestratedCustomPollStep[];
    currentStepIndex: number;
    onTextQuestionAnswered: ReturnType<typeof useActivitiesContext>["onCustomPollTextQuestionAnswered"];
    onOpinionScaleQuestionAnswered: ReturnType<typeof useActivitiesContext>["onCustomPollOpinionScaleQuestionAnswered"];
    onMultipleChoiceQuestionAnswered: ReturnType<typeof useActivitiesContext>["onCustomPollMultipleChoiceQuestionAnswered"];
    onMultipleChoiceQuestionWithMultiSelectionAnswered: ReturnType<typeof useActivitiesContext>["onCustomPollMultipleChoiceQuestionWithMultiSelectionAnswered"];
    onLikertScaleQuestionAnswered: ReturnType<typeof useActivitiesContext>["onCustomPollLikertScaleQuestionAnswered"];
    onChangeFeedbackAnonymity: (isAnonymous: boolean) => void;
    onFollowUpFeedbackLeft: ReturnType<typeof useActivitiesContext>["onCustomPollFollowUpAnswerLeft"];
    onGoToPreviousStep: () => void;
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (customPollId: string) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
}

const CustomPollActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    customPoll,
    customPollId,
    steps,
    currentStepIndex,
    onTextQuestionAnswered,
    onOpinionScaleQuestionAnswered,
    onMultipleChoiceQuestionAnswered,
    onMultipleChoiceQuestionWithMultiSelectionAnswered,
    onLikertScaleQuestionAnswered,
    onChangeFeedbackAnonymity,
    onFollowUpFeedbackLeft,
    onGoToPreviousStep,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    isIntroAnimated,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isQuestionStep = (step: OrchestratedCustomPollStep): step is OrchestratedCustomPollQuestionStep => "questionId" in step;

    const trackCustomPollQuestionAnswered = (step: OrchestratedCustomPollQuestionStep) => {
        trackQuestionAnswered(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, step.questionId);
    };

    const handleOnOpinionScaleQuestionAnswered = (value: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackCustomPollQuestionAnswered(currentStep);

        onOpinionScaleQuestionAnswered(customPollId, currentStep.questionId, value);
    };

    const handleOnMultipleChoiceQuestionAnswered = (value: CustomPollMultipleChoiceAnswer, customAnswer: string | null = null) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackCustomPollQuestionAnswered(currentStep);

        onMultipleChoiceQuestionAnswered(customPollId, currentStep.questionId, value, customAnswer);
    };

    const handleOnMultipleChoiceQuestionWithMultiSelectionAnswered = (values: CustomPollMultipleChoiceAnswer[], customAnswer: string | null = null) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackCustomPollQuestionAnswered(currentStep);

        onMultipleChoiceQuestionWithMultiSelectionAnswered(customPollId, currentStep.questionId, values, customAnswer);
    };

    const handleOnTextQuestionAnswered = (feedback: string, isAnonymous: boolean) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackCustomPollQuestionAnswered(currentStep);

        onTextQuestionAnswered(customPollId, currentStep.questionId, feedback, isAnonymous);
    };

    const handleOnLikertScaleQuestionAnswered = (value: number) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        trackCustomPollQuestionAnswered(currentStep);

        onLikertScaleQuestionAnswered(customPollId, currentStep.questionId, value);
    };

    const handleOnFollowUpQuestionAnswered = (feedback: string, isAnonymous: boolean) => {
        if (!isQuestionStep(currentStep)) {
            return;
        }

        if (activityType === ActivityType.CustomPoll) {
            trackFeedbackGiven(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, TrackingInteractionType.Answered, isAnonymous, currentStep.questionId);
        }

        onFollowUpFeedbackLeft(customPollId, currentStep.questionId, feedback, isAnonymous);
    };

    const handleOnTrackFeedbackShown = () => {
        if (activityType !== ActivityType.CustomPoll || !isQuestionStep(currentStep)) {
            return;
        }

        trackFeedbackShown(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, currentStep.questionId);
    };

    const handleOnGoBack = () => {
        if (activityType === ActivityType.CustomPoll) {
            trackLinkClicked(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, "Edit Answer");
        }

        onGoToPreviousStep();
    };

    const handleOnTextOrFollowQuestionAnonymityChanged = (isAnonymous: boolean) => {
        onChangeFeedbackAnonymity(isAnonymous);
    };

    const handleOnAnimationCompleted = () => {
        if (currentStep.isLastStep) {
            trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
            onActivityEnded(customPollId);

            if (isLastActivity) {
                onGoToOutro();
            } else {
                onGoToNextActivity();
            }
        } else {
            onGoToNextStep();
        }
    };

    const renderIntroStep = (step: OrchestratedCustomPollIntroStep) =>
        <CustomPollIntro
            isIntroAnimated={isIntroAnimated}
            onIntroAnimationCompleted={onIntroAnimationCompleted}
            title={step.title}
            description={step.description}
            isSentByCompany={step.isSentByCompany}
            sentByName={step.sentByName}
            sentByImageUrl={step.sentByImageUrl}
            isSentByAuthorDeleted={step.isSentByAuthorDeleted}
            pollPermissionType={customPoll.pollPermissionType}
            totalQuestions={step.totalQuestions}
            onGoToNextStep={onGoToNextStep}
        />;

    const renderOpinionScaleQuestionStep = (step: OrchestratedCustomPollOpinionScaleQuestionStep) =>
        <CustomPollOpinionScaleQuestion
            {...step}
            key={step.questionId}
            initialValue={step.value}
            onSubmit={handleOnOpinionScaleQuestionAnswered}
            onAnimationCompleted={handleOnAnimationCompleted}
        />;

    const renderMultipleChoiceQuestionStep = (step: OrchestratedCustomPollMultipleChoiceQuestionStep) =>
        step.allowsMultipleChoices ?
            <CustomPollMultipleChoiceQuestionWithMultiSelection
                {...step}
                key={step.questionId}
                initialSelectedChoices={step.selectedChoices}
                initialCustomAnswer={step.customAnswer}
                onSubmit={handleOnMultipleChoiceQuestionWithMultiSelectionAnswered}
                onAnimationCompleted={handleOnAnimationCompleted}
            /> :
            <CustomPollMultipleChoiceQuestion
                {...step}
                key={step.questionId}
                initialValue={step.value}
                initialCustomAnswer={step.customAnswer}
                onSubmit={handleOnMultipleChoiceQuestionAnswered}
                onAnimationCompleted={handleOnAnimationCompleted}
            />;

    const renderTextQuestionStep = (step: OrchestratedCustomPollTextQuestionStep) =>
        <CustomPollTextQuestion
            {...step}
            key={step.questionId}
            initialFeedback={step.feedback}
            initialIsAnonymous={step.isAnonymous}
            onChangeAnonymity={handleOnTextOrFollowQuestionAnonymityChanged}
            onSubmit={handleOnTextQuestionAnswered}
            onAnimationCompleted={handleOnAnimationCompleted}
        />;

    const renderLikertScaleQuestionStep = (step: OrchestratedCustomPollLikertScaleQuestionStep) =>
        <CustomPollLikertScaleQuestion
            {...step}
            key={step.questionId}
            initialValue={step.value}
            onSubmit={handleOnLikertScaleQuestionAnswered}
            onAnimationCompleted={handleOnAnimationCompleted}
        />;

    const renderAskQuestionStep = (step: OrchestratedCustomPollQuestionStep) => {
        const { displayType } = step;

        switch (displayType) {
            case DisplayType.OpinionScale:
                return renderOpinionScaleQuestionStep(step);

            case DisplayType.MultipleChoice:
                return renderMultipleChoiceQuestionStep(step);

            case DisplayType.Text:
                return renderTextQuestionStep(step);

            case DisplayType.LikertScale:
                return renderLikertScaleQuestionStep(step);

            default:
                throw new Error(`Display Type (${displayType}) not supported`);
        }
    };

    const renderAskFollowUpQuestionStep = (step: OrchestratedCustomPollFollowUpQuestionStep) => {
        return (
            <CustomPollFollowUpQuestion
                {...step}
                initialFeedback={step.feedback}
                initialIsAnonymous={step.isAnonymous}
                onChangeAnonymity={handleOnTextOrFollowQuestionAnonymityChanged}
                onSubmit={handleOnFollowUpQuestionAnswered}
                onGoBack={handleOnGoBack}
                onAnimationCompleted={handleOnAnimationCompleted}
                onTrackFeedbackShown={handleOnTrackFeedbackShown}
            />
        );
    };

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            case CustomPollStepType.Intro:
                return renderIntroStep(currentStep);
            case CustomPollStepType.AskQuestion:
                return renderAskQuestionStep(currentStep);
            case CustomPollStepType.AskFollowUpQuestion:
                return renderAskFollowUpQuestionStep(currentStep);
            default:
                throw new Error(`Pulse Survey Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

export default CustomPollActivity;