import React from "react";

import classNames from "classnames";

import "./signup-slide-form.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const SignupSlideForm = ({ className, children }: Props) => {
    const classes = classNames(
        "signup-slide-form",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default SignupSlideForm;