import type { AxiosRequestConfig } from "axios";

const validateForHttpVerbs = ["post", "put", "patch", "delete"];

class CsrfFactory {
    private _csrfToken: string | null;

    constructor() {
        this._csrfToken = null;
    }

    hasToken() {
        return !!this._csrfToken;
    }

    setToken(csrfToken: string) {
        this._csrfToken = csrfToken;
    }

    injectCSRFToken(configuration: AxiosRequestConfig<unknown>) {
        if (this.hasToken() && configuration.method && validateForHttpVerbs.includes(configuration.method)) {
            return {
                ...configuration,
                headers: {
                    ...configuration.headers,
                    "X-Request-Verification-Token": this._csrfToken
                }
            };
        }

        return configuration;
    }
}

const factory = new CsrfFactory();

export default factory;