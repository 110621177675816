import QuestionDisplayType from "./QuestionDisplayType";

type DeibSurveyQuestionDisplayType = QuestionDisplayType.MultipleChoice | QuestionDisplayType.Slider | QuestionDisplayType.LikertScale;

const DeibSurveyQuestionDisplayType = {
    [QuestionDisplayType.MultipleChoice]: QuestionDisplayType.MultipleChoice,
    [QuestionDisplayType.Slider]: QuestionDisplayType.Slider,
    [QuestionDisplayType.LikertScale]: QuestionDisplayType.LikertScale
} as const;

export default DeibSurveyQuestionDisplayType;