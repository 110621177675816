import SupportedLanguage from "./SupportedLanguage";

type SupportedDashboardLanguage = SupportedLanguage.English | SupportedLanguage.French;

const SupportedDashboardLanguage = {
    English: SupportedLanguage.English,
    French: SupportedLanguage.French
} as const;

export default SupportedDashboardLanguage;
