import type { StringCustomizationToken } from "@/types/StringCustomizationToken";
import type { GuidString } from "@/types/utilityTypes";

import type { DeibQuestionID } from "@core/activities/deib-survey/types";
import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

const getClient = () => buildApiClient(window.env.ACTIVITIES_API_URL);

export interface DeibSurveyActivityResponse {
    correlationId: GuidString;
    questionIds: DeibQuestionID[];
    customizationWord: StringCustomizationToken;
}

export const getActivityAsync = async(): Promise<DeibSurveyActivityResponse> =>
    getClient().getAsync("/api/v1/activities/deib-survey");

interface DeibSurveySaveAnswerRequest {
    score?: number | null;
}

export const saveAnswerAsync = async(correlationId: GuidString, questionId: DeibQuestionID, score: number): Promise<void> => {
    const request: DeibSurveySaveAnswerRequest = {
        score
    };

    return getClient().postAsync(`/api/v1/activities/deib-survey/${correlationId}/${questionId}`, request);
};

export const skipAnswerAsync = async(correlationId: GuidString, questionId: DeibQuestionID): Promise<void> => {
    const request: DeibSurveySaveAnswerRequest = {
        score: null
    };

    return getClient().postAsync(`/api/v1/activities/deib-survey/${correlationId}/${questionId}`, request);
};

export const activityEndedAsync = async(correlationId: GuidString): Promise<void> =>
    getClient().postAsync(`/api/v1/activities/deib-survey/${correlationId}/ended`);
