import React from "react";

import classNames from "classnames";

import OnboardingSurveyQuestionTitle from "./OnboardingSurveyQuestionTitle";

import "./base-onboarding-survey-question.scss";

interface Props {
    className?: string;
    question: string;
    children: React.ReactNode;
}

const BaseOnboardingSurveyQuestion = ({ className, question, children }: Props) => {
    const classes = classNames(
        "base-onboarding-survey-question",
        className
    );

    return (
        <div className={classes}>
            <OnboardingSurveyQuestionTitle className="base-onboarding-survey-question__header">
                {question}
            </OnboardingSurveyQuestionTitle>
            <div className="base-onboarding-survey-question__input">
                {children}
            </div>
        </div>
    );
};

export default BaseOnboardingSurveyQuestion;