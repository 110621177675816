import React, { useEffect } from "react";

import { useFeaturesContext } from "@contexts/features/FeaturesContext";

import LoadingPage from "@pages/loading/LoadingPage";

interface Props {
    children: React.ReactNode;
}

const NotAuthenticatedRoute = ({ children }: Props) => {
    const { isLoading: isFeaturesLoading, initializeFeaturesAsync } = useFeaturesContext();

    useEffect(() => {
        initializeFeaturesAsync();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isFeaturesLoading) {
        return <LoadingPage />;
    }

    return children;
};

export default NotAuthenticatedRoute;