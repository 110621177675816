import StepType from "./StepType";

type OnboardingSurveyStepType =
    | StepType.Intro
    | StepType.AskQuestion
    | StepType.AskFollowUpQuestion;

const OnboardingSurveyStepType = {
    Intro: StepType.Intro,
    AskQuestion: StepType.AskQuestion,
    AskFollowUpQuestion: StepType.AskFollowUpQuestion
} as const;

export default OnboardingSurveyStepType;
