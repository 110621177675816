import React from "react";

import { EyeVisibleIcon } from "@hopper-ui/icons";
import classNames from "classnames";

import "./preview-banner.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const PreviewBanner = ({ className, children }: Props) => {
    const classes = classNames(
        "preview-banner",
        className
    );

    return (
        <div className={classes}>
            <EyeVisibleIcon className="preview-banner__icon" size="md" aria-hidden="true" /> {children}
        </div>
    );
};

export default PreviewBanner;