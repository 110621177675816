import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import type { OnboardingQuestionID } from "@core/activities/onboarding-survey/types";

import ActivitiesPage from "./components/ActivitiesPage";

const TestOnboardingSurveyPage = () => {
    const { questionId } = useParams<{ questionId: OnboardingQuestionID }>();
    const { initializeTestOnboardingSurveyAsync } = useActivitiesContext();

    useEffect(() => {
        if (!questionId) {
            throw new Error("Question ID is missing from parameters");
        }

        initializeTestOnboardingSurveyAsync(questionId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default TestOnboardingSurveyPage;