import removeParamsFromQueryString from "./removeParamsFromQueryString";

export default function removeParamsFromUrlQueryString(url: string, paramsToRemove: string[]) {
    const urlSplit = url.split("?");
    const queryStringWithoutParams = removeParamsFromQueryString(urlSplit[1], paramsToRemove);
    const urlWithoutParams = queryStringWithoutParams
        ? `${urlSplit[0]}?${queryStringWithoutParams}`
        : urlSplit[0];

    return { urlWithoutParams, queryStringWithoutParams };
}