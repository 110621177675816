import React, { useMemo } from "react";

import classNames from "classnames";
import countries from "i18n-iso-countries";
import localeDe from "i18n-iso-countries/langs/de.json";
import localeEn from "i18n-iso-countries/langs/en.json";
import localeEs from "i18n-iso-countries/langs/es.json";
import localeFr from "i18n-iso-countries/langs/fr.json";
import localePt from "i18n-iso-countries/langs/pt.json";
import i18n from "i18next";
import type { CountryCallingCode, CountryCode } from "libphonenumber-js";
import countriesMetadata from "libphonenumber-js/metadata.mobile.json";

import Select from "@components/select/Select";

import "./country-select.scss";

countries.registerLocale(localeEn);
countries.registerLocale(localeFr);
countries.registerLocale(localeDe);
countries.registerLocale(localePt);
countries.registerLocale(localeEs);

interface Props {
    className?: string;
    value: CountryCode;
    onChange: (value: CountryCode, countryCallingCode: CountryCallingCode) => void;
    tabIndex?: number;
    preferredCountryCodes?: CountryCode[];
}

const CountrySelect = ({ className, value, onChange, tabIndex, preferredCountryCodes }: Props) => {
    const language = i18n.language;

    const countryNames = useMemo(() => countries.getNames(language?.substring(0, 2) || "en"), [language]);
    const options = useMemo(() => Object.keys(countryNames).filter(o => o in countriesMetadata.countries) as CountryCode[], [countryNames]);
    const localizedOptions = useMemo(() => {
        return options.map(code => ({
            id: code,
            countryName: countryNames[code],
            countryCallingCode: `+${countriesMetadata.countries[code]?.[0]}`
        })).sort((a, b) => a.countryName.localeCompare(b.countryName));
    }, [countryNames, options]);
    const groupedOptions = useMemo(() => {
        return [
            [...(preferredCountryCodes ?? [])],
            ...options
        ];
    }, [options, preferredCountryCodes]);

    const currentValue = useMemo(() => localizedOptions.find(o => o.id === value), [value, localizedOptions]);

    const handleOnChange = (newValue: CountryCode) => {
        const localizedOption = localizedOptions.find(o => o.id === newValue)!;

        onChange(localizedOption.id, localizedOption.countryCallingCode);
    };

    const renderValue = () => {
        return (
            <div className="country-select-value">
                {currentValue?.countryCallingCode}
            </div>
        );
    };

    const renderOption = (option: CountryCode, isKeyboardFocused: boolean, onClick: () => void) => {
        const localizedOption = localizedOptions.find(o => o.id === option)!;

        return (
            <Select.Option key={option} className="country-select-option" onClick={onClick} selected={option === value} isKeyboardFocused={isKeyboardFocused}>
                <div className="country-select-option__label">
                    {localizedOption.countryName}
                </div>
                <div className="country-select-option__country-calling-code">
                    {localizedOption.countryCallingCode}
                </div>
            </Select.Option>
        );
    };

    const classes = classNames(
        "country-select",
        className
    );

    return (
        <Select<CountryCode>
            className={classes}
            value={value}
            renderValue={renderValue}
            options={groupedOptions}
            renderOption={renderOption}
            onChange={handleOnChange}
            tabIndex={tabIndex}
        />
    );
};

export default CountrySelect;