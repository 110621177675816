import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import ActivitiesPage from "./components/ActivitiesPage";

const TestSmartQuestionPage = () => {
    const { metricId, subMetricId } = useParams();
    const { initializeTestSmartQuestionAsync } = useActivitiesContext();

    useEffect(() => {
        if (!metricId || !subMetricId) {
            throw new Error("Metric ID or Sub Metric ID is missing from parameters");
        }

        initializeTestSmartQuestionAsync(metricId, subMetricId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ActivitiesPage />
    );
};

export default TestSmartQuestionPage;