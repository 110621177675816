import React from "react";

import classNames from "classnames";

import "./peer-select-popover-empty-state.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const PeerSelectPopoverEmptyState = ({ className, children }: Props) => {
    const classes = classNames(
        "peer-select-popover-empty-state",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default PeerSelectPopoverEmptyState;