import React from "react";

import SliderQuestion from "@components/slider-question/SliderQuestion";

import BaseCustomPollQuestion from "./BaseCustomPollQuestion";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    minLabel: React.ReactNode;
    maxLabel: React.ReactNode;
    onSubmit: (value: number) => void;
    onAnimationCompleted: () => void;
}

const CustomPollOpinionScaleQuestion = ({
    className,
    question,
    initialValue,
    minLabel,
    maxLabel,
    onSubmit,
    onAnimationCompleted
}: Props) => (
    <BaseCustomPollQuestion className={className} question={question}>
        <SliderQuestion
            initialValue={initialValue}
            minLabel={minLabel}
            maxLabel={maxLabel}
            onChange={onSubmit}
            onAnimationCompleted={onAnimationCompleted}
        />
    </BaseCustomPollQuestion>
);

export default CustomPollOpinionScaleQuestion;
