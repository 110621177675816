/* eslint-disable react/no-unused-state -- eslint seems unable to track usages outside of this context */
import React, { useCallback, type ReactNode } from "react";

import ResponsiveContext, { type ResponsiveContextState } from "./ResponsiveContext";

interface Props {
    children: ReactNode;
}

const ResponsiveContextProvider = ({ children }: Props) => {
    const [matches, setMatches] = React.useState<ResponsiveContextState>({ small: null, medium: null, large: null, extraLarge: null, extraExtraLarge: null });

    const calculateMedia = () => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        setMatches({
            small: vw >= 600,
            medium: vw >= 768,
            large: vw >= 992,
            extraLarge: vw >= 1200,
            extraExtraLarge: vw >= 1440
        });
    };

    const debouncedCalculateMedia = useCallback(() => {
        window.requestAnimationFrame(() => calculateMedia());
    }, []);

    React.useEffect(() => {
        window.addEventListener("resize", debouncedCalculateMedia);
        window.addEventListener("orientationchange", debouncedCalculateMedia);

        calculateMedia();

        return () => {
            window.removeEventListener("resize", debouncedCalculateMedia);
            window.removeEventListener("orientationchange", debouncedCalculateMedia);
        };
    }, [debouncedCalculateMedia]);

    return (
        <ResponsiveContext.Provider value={matches}>
            {children}
        </ResponsiveContext.Provider>
    );
};

export default ResponsiveContextProvider;