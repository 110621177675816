import React from "react";

import PeerSelectOptionLoading from "./PeerSelectOptionLoading";

interface Props {
    className?: string;
}

const PeerSelectPopoverLoading = ({ className }: Props) => {
    return (
        <div className={className}>
            <PeerSelectOptionLoading />
            <PeerSelectOptionLoading />
            <PeerSelectOptionLoading />
            <PeerSelectOptionLoading />
            <PeerSelectOptionLoading />
            <PeerSelectOptionLoading />
        </div>
    );
};

export default PeerSelectPopoverLoading;