import React from "react";

import classNames from "classnames";

import DeibSurveyQuestionTitle from "./DeibSurveyQuestionTitle";

import "./base-deib-survey-question.scss";

interface Props {
    className?: string;
    question: string;
    children: React.ReactNode;
}

const BaseDeibSurveyQuestion = ({ className, question, children }: Props) => {
    const classes = classNames(
        "base-deib-survey-question",
        className
    );

    return (
        <div className={classes}>
            <DeibSurveyQuestionTitle className="base-deib-survey-question__header">
                {question}
            </DeibSurveyQuestionTitle>
            <div className="base-deib-survey-question__input">
                {children}
            </div>
        </div>
    );
};

export default BaseDeibSurveyQuestion;