import type { OrchestratedActivity, Step } from "@/types/activities/common";
import type { OrchestratedCustomPollActivity, OrchestratedCustomPollStep } from "@/types/activities/custom-polls";
import type { OrchestratedDeibSurveyActivity } from "@/types/activities/deib-survey";
import type { OrchestratedOnboardingSurveyActivity } from "@/types/activities/onboarding-survey";
import type { OrchestratedPulseSurveyActivity, OrchestratedTryPulseSurveyActivity } from "@/types/activities/pulse-survey";

import ActivityControlBarStepType from "@core/enums/ActivityControlBarStepType";
import ActivityControlBarTheme from "@core/enums/ActivityControlBarTheme";
import ActivityType from "@core/enums/ActivityType";
import CustomPollStepType from "@core/enums/CustomPollStepType";
import DeibSurveyStepType from "@core/enums/DeibSurveyStepType";
import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";
import PulseSurveyStepType from "@core/enums/PulseSurveyStepType";
import RecognitionStepType from "@core/enums/RecognitionStepType";

function getPulseSurveyStepControlBarState(
    activity: OrchestratedActivity,
    step: Step,
    index: number,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    const getStepSpecificState = () => {
        switch (step.stepType) {
            case PulseSurveyStepType.Intro:
                return { type: ActivityControlBarStepType.None };

            case PulseSurveyStepType.AskQuestion:
            case PulseSurveyStepType.AskCustomQuestion:
                return { type: ActivityControlBarStepType.QuestionSubStep };

            case PulseSurveyStepType.AskFollowUpQuestion:
                return {
                    type: ActivityControlBarStepType.RelatedQuestionSubStep
                };

            default:
                throw new Error(`Step Type (${step.stepType}) not supported`);
        }
    };

    return {
        ...getStepSpecificState(),
        isInProgress: isCurrentActivity && activity.currentStepIndex === index,
        isCompleted: isActivityCompleted || index < activity.currentStepIndex
    };
}

function getPulseSurveyControlBarState(
    activity: OrchestratedTryPulseSurveyActivity | OrchestratedPulseSurveyActivity,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    return {
        type: ActivityControlBarStepType.TrackedQuestionsStep,
        theme: ActivityControlBarTheme.Default,
        questions: activity.steps.map((step: Step, index: number) =>
            getPulseSurveyStepControlBarState(
                activity,
                step,
                index,
                isCurrentActivity,
                isActivityCompleted
            )
        )
    };
}

function getCustomPollStepControlBarState(
    activity: OrchestratedActivity,
    step: OrchestratedCustomPollStep,
    index: number,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    const getStepSpecificState = () => {
        const { stepType } = step;

        switch (stepType) {
            case CustomPollStepType.Intro:
                return { type: ActivityControlBarStepType.None };

            case CustomPollStepType.AskQuestion:
                return { type: ActivityControlBarStepType.QuestionSubStep };

            case CustomPollStepType.AskFollowUpQuestion:
                return {
                    type: ActivityControlBarStepType.RelatedQuestionSubStep
                };

            default:
                throw new Error(`Step Type (${stepType}) not supported`);
        }
    };

    return {
        ...getStepSpecificState(),
        isInProgress: isCurrentActivity && activity.currentStepIndex === index,
        isCompleted: isActivityCompleted || index < activity.currentStepIndex
    };
}

function getCustomPollControlBarState(
    activity: OrchestratedCustomPollActivity,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    return {
        type: ActivityControlBarStepType.TrackedQuestionsStep,
        theme: ActivityControlBarTheme.Default,
        questions: activity.steps.map((step, index) =>
            getCustomPollStepControlBarState(
                activity,
                step,
                index,
                isCurrentActivity,
                isActivityCompleted
            )
        )
    };
}

function getRecognitionControlBarState() {
    return {
        type: ActivityControlBarStepType.None,
        theme: ActivityControlBarTheme.Floating,
        showPreviousButton: false
    };
}

function getOnboardingSurveyStepControlBarState(
    activity: OrchestratedActivity,
    step: Step,
    index: number,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    const getStepSpecificState = () => {
        switch (step.stepType) {
            case OnboardingSurveyStepType.Intro:
                return { type: ActivityControlBarStepType.None };
            case OnboardingSurveyStepType.AskQuestion:
                return { type: ActivityControlBarStepType.QuestionSubStep };

            case OnboardingSurveyStepType.AskFollowUpQuestion:
                return {
                    type: ActivityControlBarStepType.RelatedQuestionSubStep
                };

            default:
                throw new Error(`Step Type (${step.stepType}) not supported`);
        }
    };

    return {
        ...getStepSpecificState(),
        isInProgress: isCurrentActivity && activity.currentStepIndex === index,
        isCompleted: isActivityCompleted || index < activity.currentStepIndex
    };
}

function getOnboardingSurveyControlBarState(
    activity: OrchestratedOnboardingSurveyActivity,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    return {
        type: ActivityControlBarStepType.TrackedQuestionsStep,
        theme: ActivityControlBarTheme.Default,
        questions: activity.steps.map((step, index) =>
            getOnboardingSurveyStepControlBarState(
                activity,
                step,
                index,
                isCurrentActivity,
                isActivityCompleted
            )
        )
    };
}

function getDeibSurveyStepControlBarState(
    activity: OrchestratedDeibSurveyActivity,
    step: Step,
    index: number,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    const getStepSpecificState = () => {
        switch (step.stepType) {
            case DeibSurveyStepType.Intro:
                return { type: ActivityControlBarStepType.None };
            case DeibSurveyStepType.AskQuestion:
                return { type: ActivityControlBarStepType.QuestionSubStep };

            default:
                throw new Error(`Step Type (${step.stepType}) not supported`);
        }
    };

    return {
        ...getStepSpecificState(),
        isInProgress: isCurrentActivity && activity.currentStepIndex === index,
        isCompleted: isActivityCompleted || index < activity.currentStepIndex
    };
}

function getDeibSurveyControlBarState(
    activity: OrchestratedDeibSurveyActivity,
    isCurrentActivity: boolean,
    isActivityCompleted: boolean
) {
    return {
        type: ActivityControlBarStepType.TrackedQuestionsStep,
        theme: ActivityControlBarTheme.Default,
        questions: activity.steps.map((step, index) =>
            getDeibSurveyStepControlBarState(
                activity,
                step,
                index,
                isCurrentActivity,
                isActivityCompleted
            )
        )
    };
}

function getHiddenControlBarState() {
    return {
        type: ActivityControlBarStepType.None,
        theme: ActivityControlBarTheme.Default,
        showPreviousButton: false
    };
}

function getIsControlBarHidden(activity: OrchestratedActivity) {
    if (!("steps" in activity) || activity.steps.length === 0) {
        return false;
    }

    const isDeibIntroStep =
            activity.activityType === ActivityType.DeibSurvey &&
            activity.steps[activity.currentStepIndex].stepType ===
                DeibSurveyStepType.Intro;
    const isCustomPollIntroStep =
            (activity.activityType === ActivityType.CustomPoll ||
                activity.activityType === ActivityType.PreviewCustomPoll ||
                activity.activityType === ActivityType.TestCustomPoll) &&
            activity.steps[activity.currentStepIndex].stepType ===
                CustomPollStepType.Intro;
    const isContinuePulseSurveyIntroStep =
            activity.activityType === ActivityType.ContinuePulseSurvey &&
            activity.steps[activity.currentStepIndex].stepType ===
                PulseSurveyStepType.Intro;
    const isContinueOnboardingSurveyIntroStep =
            activity.activityType === ActivityType.ContinueOnboardingSurvey &&
            activity.steps[activity.currentStepIndex].stepType ===
                OnboardingSurveyStepType.Intro;

    const isInRecognitionActivity =
            activity.activityType === ActivityType.Recognition ||
            activity.activityType === ActivityType.TestRecognition;
    const isRecognitionStep =
            isInRecognitionActivity &&
            (activity.steps[activity.currentStepIndex].stepType ===
                RecognitionStepType.Intro ||
                activity.steps[activity.currentStepIndex].stepType ===
                    RecognitionStepType.CustomizeMessage);

    return (
        isCustomPollIntroStep ||
            isContinuePulseSurveyIntroStep ||
            isRecognitionStep ||
            isContinueOnboardingSurveyIntroStep ||
            isDeibIntroStep
    );
}
function getShowPreviousButton(activity: OrchestratedActivity) {
    switch (activity.activityType) {
        case ActivityType.TryPulseSurvey:
        case ActivityType.PulseSurvey:
        case ActivityType.TestPulseSurveyQuestion:
        case ActivityType.CustomPoll:
        case ActivityType.PreviewCustomPoll:
        case ActivityType.TestCustomPoll:
        case ActivityType.OnboardingSurvey:
        case ActivityType.TestOnboardingSurvey:
        case ActivityType.DeibSurvey:
        case ActivityType.TestDeibSurvey:
            return activity.currentStepIndex > 0;

        case ActivityType.ContinuePulseSurvey:
        case ActivityType.ContinueOnboardingSurvey:
            // Step 0 is the intro
            return activity.currentStepIndex > 1;

        default:
            return false;
    }
}

function getShowSkipButton(activity: OrchestratedActivity) {
    switch (activity.activityType) {
        case ActivityType.TryPulseSurvey:
        case ActivityType.PulseSurvey:
        case ActivityType.ContinuePulseSurvey:
        case ActivityType.TestPulseSurveyQuestion: {
            const pulseSurveyStep = activity.steps[activity.currentStepIndex];

            return (
                pulseSurveyStep?.stepType !== PulseSurveyStepType.Intro &&
                !pulseSurveyStep?.isAnswered
            );
        }

        case ActivityType.CustomPoll:
        case ActivityType.PreviewCustomPoll:
        case ActivityType.TestCustomPoll: {
            const customPollStep = activity.steps[activity.currentStepIndex];

            return (
                customPollStep.stepType !== CustomPollStepType.Intro &&
                !customPollStep.isAnswered
            );
        }

        case ActivityType.SmartQuestion:
        case ActivityType.TestSmartQuestion:
            return !activity.isAnswered;

        case ActivityType.Recognition:
        case ActivityType.TestRecognition:
            return true;

        case ActivityType.DeibSurvey:
        case ActivityType.TestDeibSurvey: {
            const customPollStep = activity.steps[activity.currentStepIndex];

            return !customPollStep.isAnswered;
        }

        case ActivityType.OnboardingSurvey:
        case ActivityType.TestOnboardingSurvey:
        case ActivityType.ContinueOnboardingSurvey: {
            const onboardingSurveyStep =
                activity.steps[activity.currentStepIndex];

            return (
                onboardingSurveyStep.stepType !==
                    OnboardingSurveyStepType.Intro &&
                !onboardingSurveyStep.isAnswered
            );
        }
        default:
            return false;
    }
}

export default function getControlBarState(
    activities: OrchestratedActivity[] | null,
    currentActivityIndex: number | null,
    isFlowCompleted: boolean,
    disableNextButton: boolean
) {
    if (!activities || activities?.length === 0 || currentActivityIndex === null) {
        return {
            steps: [],
            isHidden: true,
            showPreviousButton: false,
            showSkipButton: false,
            showNextButton: false
        };
    }

    const activitiesStates = activities.map((activity, index) => {
        const getActivitySpecificState = () => {
            const { activityType } = activity;

            switch (activityType) {
                case ActivityType.PulseSurvey:
                case ActivityType.ContinuePulseSurvey:
                case ActivityType.TryPulseSurvey:
                case ActivityType.TestPulseSurveyQuestion:
                    return getPulseSurveyControlBarState(
                        activity,
                        !isFlowCompleted && index === currentActivityIndex,
                        isFlowCompleted || index < currentActivityIndex
                    );

                case ActivityType.CustomPoll:
                case ActivityType.PreviewCustomPoll:
                case ActivityType.TestCustomPoll:
                    return getCustomPollControlBarState(
                        activity,
                        !isFlowCompleted && index === currentActivityIndex,
                        isFlowCompleted || index < currentActivityIndex
                    );

                case ActivityType.Recognition:
                case ActivityType.TestRecognition:
                    return getRecognitionControlBarState();

                case ActivityType.SmartQuestion:
                case ActivityType.TestSmartQuestion:
                    return getHiddenControlBarState();

                case ActivityType.TestOnboardingSurvey:
                case ActivityType.OnboardingSurvey:
                case ActivityType.ContinueOnboardingSurvey:
                    return getOnboardingSurveyControlBarState(
                        activity,
                        !isFlowCompleted && index === currentActivityIndex,
                        isFlowCompleted || index < currentActivityIndex
                    );

                case ActivityType.DeibSurvey:
                case ActivityType.TestDeibSurvey:
                    return getDeibSurveyControlBarState(
                        activity,
                        !isFlowCompleted && index === currentActivityIndex,
                        isFlowCompleted || index < currentActivityIndex
                    );
                default:
                    throw new Error(
                        `Activity Type (${activityType}) not supported`
                    );
            }
        };

        return {
            ...getActivitySpecificState(),
            isInProgress: !isFlowCompleted && index === currentActivityIndex,
            isCompleted: isFlowCompleted || index < currentActivityIndex
        };
    });

    const currentActivity = activities[currentActivityIndex];
    const canSkip = getShowSkipButton(currentActivity);

    return {
        activities: activitiesStates,
        theme: activitiesStates[currentActivityIndex].theme,
        isHidden: getIsControlBarHidden(currentActivity),
        showPreviousButton: getShowPreviousButton(currentActivity),
        showSkipButton: canSkip,
        showNextButton: !canSkip,
        disableNextButton
    };
}
