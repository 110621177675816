import React from "react";

import classNames from "classnames";

import "./question-asked-by.scss";

interface Props {
    className?: string;
    question: string | null;
    icon: React.ReactNode;
}

const QuestionAskedBy = ({ className, question, icon }: Props) => {
    const classes = classNames("question-asked-by", className);

    return (
        <div className={classes}>
            <div className="question-asked-by__icon">
                {icon}
            </div>
            <div className="question-asked-by__question">
                {question}
            </div>
        </div>
    );
};

export default QuestionAskedBy;