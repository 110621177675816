/* eslint-disable react/no-unused-state -- eslint seems unable to track usages outside of this context */
import React, { type ReactNode } from "react";

import { type ActivitiesAuthContext, getContextAsync, getContextForAuthenticatedUserAsync } from "@api/UserAuthenticationContextApiClient";
import ApiException from "@core/api-client/ApiException";
import bearerFactory from "@core/bearer/bearerFactory";
import csrfFactory from "@core/csrf/csrfFactory";

import AuthenticationContext, { type AuthenticationContextState } from "./AuthenticationContext";

interface AuthenticationRequiredExceptionResponse {
    isForceAuthenticationEnabled?: boolean;
}

function isAuthenticationRequiredExceptionResponse(responseData: unknown): responseData is AuthenticationRequiredExceptionResponse {
    return typeof responseData === "object" && responseData !== null && "isForceAuthenticationEnabled" in responseData;
}

interface Props {
    children: ReactNode;
}

class AuthenticationContextProvider extends React.Component<Props, AuthenticationContextState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            _initialized: false,
            isAuthenticating: true,
            isAuthenticated: false,
            initializeAsync: async surveyToken => {
                const { _initialized } = this.state;
                if (!_initialized) {
                    this.setState({ _initialized: true, isAuthenticating: true });

                    if (surveyToken) {
                        try {
                            await this.setTokensAndState(getContextAsync, surveyToken);
                        } catch (getContextException) {
                            if (getContextException instanceof ApiException && getContextException.errorCode === 401) {
                                await this.handleAuthenticationRequiredException(getContextException, surveyToken);
                            } else {
                                throw getContextException;
                            }
                        }
                    } else {
                        this.setState({ isAuthenticating: false, isAuthenticated: false });
                    }
                }
            }
        };
    }

    render() {
        const { children } = this.props;

        return (
            <AuthenticationContext.Provider value={this.state}>
                {children}
            </AuthenticationContext.Provider>
        );
    }

    async setTokensAndState(getContextFunction: (surveyToken: string) => Promise<ActivitiesAuthContext>, surveyToken: string) {
        const { requestVerificationToken, jwtToken, jwtExpiry } = await getContextFunction(surveyToken);
        bearerFactory.setToken(jwtToken, jwtExpiry, surveyToken);
        csrfFactory.setToken(requestVerificationToken);
        this.setState({ isAuthenticating: false, isAuthenticated: true });
    }

    async handleAuthenticationRequiredException(getContextException: ApiException, surveyToken: string) {
        if (isAuthenticationRequiredExceptionResponse(getContextException.response.data) && getContextException.response.data.isForceAuthenticationEnabled) {
            try {
                await this.setTokensAndState(getContextForAuthenticatedUserAsync, surveyToken);
            } catch (getContextForAuthenticatedUserException) {
                if (getContextForAuthenticatedUserException instanceof ApiException && getContextForAuthenticatedUserException.errorCode === 401) {
                    const loginUrl = new URL(window.env.LOGIN_WORKLEAP_PATH);
                    const fallbackUrl = new URL(window.env.LOGIN_OFFICEVIBE_PATH);
                    fallbackUrl.searchParams.append("state", btoa(window.location.href));
                    loginUrl.searchParams.append("returnUrl", encodeURIComponent(window.location.href));
                    loginUrl.searchParams.append("fallbackUrl", fallbackUrl.toString());
                    window.location.href = loginUrl.toString();
                } else {
                    throw getContextForAuthenticatedUserException;
                }
            }
        } else {
            window.location.href = `${window.env.MONOLITH_API_URL}/survey`;
        }
    }
}

export default AuthenticationContextProvider;