import React from "react";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";
import { useUserContext } from "@contexts/user/UserContext";

import ActivitiesLayout from "@layouts/activities/ActivitiesLayout";
import LoadingPage from "@pages/loading/LoadingPage";

import ActivitiesBackground from "./ActivitiesBackground";
import ActivitiesFlow from "./ActivitiesFlow";

interface Props {
    className?: string;
}

const ActivitiesPage = ({ className }: Props) => {
    const { isLoading: activitiesContextIsLoading } = useActivitiesContext();

    const { context: userContext } = useUserContext();

    const isNotificationUrlWithTokenEnabled = useIsFeatureEnabled(
        x => x.notificationUrlWithToken
    );

    if (activitiesContextIsLoading) {
        return <LoadingPage />;
    }

    const handleOnRedirectToDashboard = () => {
        window.location.href = isNotificationUrlWithTokenEnabled
            ? `${window.env.APP_DASHBOARD_URL}?token=${userContext?.organisationId}`
            : window.env.APP_DASHBOARD_URL;
    };

    return (
        <ActivitiesLayout className={className}>
            <ActivitiesBackground />
            <ActivitiesFlow
                onRedirectToDashboard={handleOnRedirectToDashboard}
            />
        </ActivitiesLayout>
    );
};

export default ActivitiesPage;
