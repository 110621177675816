import React from "react";

import { useTranslation } from "react-i18next";

import EmptyLayout from "@layouts/empty/EmptyLayout";

import { ReactComponent as Illustration } from "@svg/illustrations/legacy/illustration-not-found.svg";

import "./not-found-page.scss";

const NotFoundPage = () => {
    const { t } = useTranslation("activities");

    return (
        <EmptyLayout className="not-found-page" withFixedHeight>
            <div className="not-found-page__image-container">
                <Illustration className="not-found-page__image" />
            </div>
            <div className="not-found-page__title">
                {t("activityNotFound.title")}
            </div>
            <div className="not-found-page__subtitle">
                {t("activityNotFound.description")}
            </div>
        </EmptyLayout>
    );
};

export default NotFoundPage;