import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import ApiException from "@core/api-client/ApiException";

import PreviewCustomPollForbiddenPage from "../forbidden/PreviewCustomPollForbiddenPage";
import ActivitiesPage from "./components/ActivitiesPage";

const PreviewCustomPollPage = () => {
    const { customPollId } = useParams();
    const { initializePreviewCustomPollAsync } = useActivitiesContext();
    const [isPreviewCustomPollForbidden, setPreviewIsCustomPollForbidden] = useState(false);

    useEffect(() => {
        const initializePreviewCustomPoll = async() => {
            try {
                if (!customPollId) {
                    throw new Error("Custom poll ID is missing from parameters");
                }

                await initializePreviewCustomPollAsync(customPollId);
            } catch (ex) {
                if (ex instanceof ApiException && ex.errorCode === 403) {
                    setPreviewIsCustomPollForbidden(true);
                } else {
                    throw ex;
                }
            }
        };

        initializePreviewCustomPoll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isPreviewCustomPollForbidden) {
        return <PreviewCustomPollForbiddenPage />;
    }

    return (
        <ActivitiesPage />
    );
};

export default PreviewCustomPollPage;