// polyfills
import "core-js/features/string/repeat";
import "core-js/proposals/string-replace-all";
import "core-js/stable";
import "element-scroll-polyfill";
import "fast-text-encoding";
import "intl-pluralrules";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "regenerator-runtime/runtime";

import cssVars from "css-vars-ponyfill";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n";
import "./index.scss";

const container = document.getElementById("root");
if (!container) {
    throw new Error("Element with id=\"root\" missing from public/index.html");
}

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

cssVars({});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
