import React, { useState } from "react";

import type { CustomPollMultipleChoiceAnswer } from "@/types/activities/custom-polls";
import classNames from "classnames";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import type { CustomPollMultipleChoiceLabel } from "@api/CustomPollsActivityApiClient";
import MultipleChoiceConstants from "@core/enums/CustomPollMultipleChoiceQuestion";
import InputType from "@core/enums/InputType";
import KeyCodes from "@core/enums/KeyCodes";
import sleep from "@core/utils/sleep";

import BaseCustomPollQuestion from "./BaseCustomPollQuestion";
import MultipleChoiceQuestionCustomChoice from "./custom-choice/MultipleChoiceQuestionCustomChoice";
import MultipleChoiceQuestionChoice from "./MultipleChoiceQuestionChoice";

import "./custom-poll-multiple-choice-question.scss";

interface Props {
    className?: string;
    question: string;
    initialValue?: CustomPollMultipleChoiceAnswer | null;
    initialCustomAnswer: string | null;
    labels: CustomPollMultipleChoiceLabel[];
    hasCustomChoice?: boolean;
    onSubmit: (value: CustomPollMultipleChoiceAnswer, customAnswer: string | null) => void;
    onAnimationCompleted: () => void;
}

const CustomPollMultipleChoiceQuestion = ({ className, question, initialValue, initialCustomAnswer, labels, hasCustomChoice = false, onSubmit, onAnimationCompleted }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const [confirmed, setConfirmed] = useState(false);
    const [value, setValue] = useState(initialValue ?? null);
    const [customAnswer, setCustomAnswer] = useState(initialCustomAnswer ?? null);
    const [isCustomAnswerInEditMode, setIsCustomAnswerInEditMode] = useState(false);
    const [focusedValue, setFocusedValue] = useState<number | null>(null);
    const [hasUserInteracted, setHasUserInteracted] = useState(initialValue !== null);

    const handleOnConfirm = async(newValue: CustomPollMultipleChoiceAnswer, newCustomAnswer: string | null = null) => {
        if (confirmed) {
            return;
        }

        setValue(newValue);
        setCustomAnswer(newCustomAnswer);
        setConfirmed(true);
        setHasUserInteracted(true);

        onSubmit(newValue, newCustomAnswer);
        await sleep(700);
        onAnimationCompleted();
    };

    const handleConfirmOnClick = (newValue: number, e: React.MouseEvent) => {
        e.preventDefault();
        handleOnConfirm(newValue);
    };

    const handleConfirmOnEnter = (newValue: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === KeyCodes.Enter) {
            e.currentTarget.blur();

            handleOnConfirm(newValue);
        }
    };

    const handleCustomAnswerConfirm = (newCustomAnswer: string) => {
        handleOnConfirm(MultipleChoiceConstants.CustomChoiceId, newCustomAnswer);
        setIsCustomAnswerInEditMode(false);
    };

    const handleCustomAnswerClear = () => {
        setCustomAnswer(null);
        setIsCustomAnswerInEditMode(false);
    };

    const handleOnInputBlur = () => {
        setFocusedValue(null);
    };

    const renderChoice = (label: CustomPollMultipleChoiceLabel, i: number) => {
        const currentValue = i;

        return (
            <div key={`multiple-choice-choice-${currentValue}`}
                className="custom-poll-multiple-choice-question__choice"
                role={InputType.Radio}
                aria-checked={value === currentValue}
            >
                <MultipleChoiceQuestionChoice
                    inputType={InputType.Radio}
                    value={currentValue}
                    label={label.toString()}
                    isConfirmed={confirmed}
                    isSelected={hasUserInteracted && currentValue === value}
                    isFocused={currentValue === focusedValue}
                    checked={focusedValue !== null ? currentValue === focusedValue : currentValue === value}
                    onFocus={() => setFocusedValue(currentValue)}
                    onBlur={handleOnInputBlur}
                    onKeyDown={e => handleConfirmOnEnter(currentValue, e)}
                    onClick={e => handleConfirmOnClick(currentValue, e)}
                />
            </div>
        );
    };

    const classes = classNames("custom-poll-multiple-choice-question", className, {
        "custom-poll-multiple-choice-question--confirmed": confirmed,
        "custom-poll-multiple-choice-question--dense": isWorkleapBrandEnabled && labels !== null && labels.length >= 10
    });

    return (
        <BaseCustomPollQuestion className={classes} question={question}>
            <div className="custom-poll-multiple-choice-question__input" role="radiogroup">
                {labels.map(renderChoice)}
                {hasCustomChoice && (
                    <MultipleChoiceQuestionCustomChoice
                        inputType={InputType.Radio}
                        initialCustomAnswer={customAnswer}
                        isInEditMode={isCustomAnswerInEditMode}
                        onConfirmAnswer={handleCustomAnswerConfirm}
                        onClearAnswer={handleCustomAnswerClear}
                        onToggleEditMode={isEditMode => setIsCustomAnswerInEditMode(isEditMode)}
                        isSelected={value === MultipleChoiceConstants.CustomChoiceId}
                        isConfirmed={confirmed}
                    />
                )}
            </div>
        </BaseCustomPollQuestion>
    );
};

export default CustomPollMultipleChoiceQuestion;