import React from "react";

import classNames from "classnames";

import "./custom-poll-question-title.scss";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const CustomPollQuestionTitle = ({ className, children }: Props) => {
    const classes = classNames(
        "custom-poll-question-title",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default CustomPollQuestionTitle;