import React from "react";

import IglooInput from "@igloo-ui/input";
import classNames from "classnames";

import "./input.scss";

interface Props extends React.ComponentPropsWithoutRef<typeof IglooInput> {
    className?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>(({ className, ...props }, ref) => {
    const classes = classNames(
        "input",
        className
    );

    return (
        <IglooInput {...props} ref={ref} className={classes} />
    );
});

export default Input;