import React from "react";

import classNames from "classnames";

import SignupSlideExplanation from "./SignupSlideExplanation";
import SignupSlideFooter from "./SignupSlideFooter";
import SignupSlideForm from "./SignupSlideForm";
import SignupSlideImageHeader from "./SignupSlideImageHeader";
import SignupSlideTitleHeader from "./SignupSlideTitleHeader";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const SignupSlide = ({ className, children }: Props) => {
    const classes = classNames(
        "signup-slide",
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

SignupSlide.TitleHeader = SignupSlideTitleHeader;
SignupSlide.ImageHeader = SignupSlideImageHeader;
SignupSlide.Form = SignupSlideForm;
SignupSlide.Explanation = SignupSlideExplanation;
SignupSlide.Footer = SignupSlideFooter;

export default SignupSlide;