import { buildApiClient } from "@core/api-client/RetryableBearerApiClient";

import type { OfficevibePrompt } from "./RecognitionActivityApiClient";

export interface TestRecognitionActivity {
    prompts: OfficevibePrompt[];
}

export async function getActivityAsync(): Promise<TestRecognitionActivity> {
    return await buildApiClient(window.env.ACTIVITIES_API_URL).getAsync("/api/v1/activities/try-recognition");
}