import type { ActivitiesUserContext } from "@contexts/user/UserContext";
import { useUserContext } from "@contexts/user/UserContext";

type EmptyActivitiesUserContext = {
    [K in keyof ActivitiesUserContext]: undefined;
};

type AuthenticatedUserLoadingResult = {
    isLoading: true;
} & EmptyActivitiesUserContext;

interface AuthenticatedUserLoadedResult extends ActivitiesUserContext {
    isLoading: false;
}

type AuthenticatedUserResult = AuthenticatedUserLoadingResult | AuthenticatedUserLoadedResult;

export default function useAuthenticatedUser(): AuthenticatedUserResult {
    const { context } = useUserContext();

    return context
        ? { ...context, isLoading: false }
        : { isLoading: true } as AuthenticatedUserLoadingResult;
}