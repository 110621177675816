import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";

interface ValidResult {
    isValid: true;
    message: null;
}

interface InvalidResult {
    isValid: false;
    message: string;
}

export type ValidationResult = ValidResult | InvalidResult;

const validResult: ValidResult = Object.freeze({
    isValid: true,
    message: null
});

export function success() {
    return validResult;
}

function ensure(condition: boolean, message: string): ValidationResult {
    return condition
        ? validResult
        : { isValid: false, message };
}

export function all(...conditions: ValidationResult[]): ValidationResult {
    const failedValidation = conditions.find(x => !x.isValid);

    if (failedValidation) {
        return failedValidation;
    }

    return validResult;
}

export function isNotNullOrEmpty(value: string | null | undefined, message: string) {
    return ensure(value !== null && value !== undefined && value !== "", message);
}

export function isValidEmailFormat(email: string, message: string) {
    const regex = /^[a-zA-Z0-9_'+-]+(\.[a-zA-Z0-9_'+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,10})$/;

    return ensure(regex.test(email), message);
}

export function isValidPhoneNumberFormat(phoneNumber: string | null | undefined, message: string) {
    if (!phoneNumber) {
        return ensure(false, message);
    }

    const phoneNumberValidation = parsePhoneNumberFromString(phoneNumber);

    return ensure(!!phoneNumberValidation && phoneNumberValidation.isValid(), message);
}

export function isTrue(condition: boolean, message: string) {
    return ensure(condition, message);
}