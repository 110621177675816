import React from "react";

import LikertQuestion from "@components/likert-question/LikertQuestion";

import BaseCustomPollQuestion from "./BaseCustomPollQuestion";

interface Props {
    className?: string;
    question: string;
    initialValue?: number | null;
    labels: string[];
    onSubmit: (value: number) => void;
    onAnimationCompleted: () => void;
}

const CustomPollLikertScaleQuestion = ({
    className,
    question,
    initialValue,
    labels,
    onSubmit,
    onAnimationCompleted
}: Props) => (
    <BaseCustomPollQuestion className={className} question={question}>
        <LikertQuestion
            initialValue={initialValue}
            labels={labels}
            onChange={onSubmit}
            onAnimationCompleted={onAnimationCompleted}
        />
    </BaseCustomPollQuestion>
);

export default CustomPollLikertScaleQuestion;
