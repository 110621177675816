import React from "react";

import classNames from "classnames";

import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import ProgressBar from "@components/progress-bar/ProgressBar";

import ActivityControlBarStepType from "@core/enums/ActivityControlBarStepType";

import "./control-bar-tracked-questions.scss";

interface Question {
    type: string;
    isInProgress: boolean;
    isCompleted: boolean;
}

interface Props {
    className?: string;
    questions?: Question[];
}

const ControlBarTrackedQuestions = ({ className, questions }: Props) => {
    const isWorkleapBrandEnabled = useIsFeatureEnabled(
        feature => feature.useWorkleapBrand
    );

    const currentQuestion = questions
        ?.filter(q => q.type === ActivityControlBarStepType.QuestionSubStep)
        .filter(q => q.isInProgress || q.isCompleted).length;

    const totalQuestions = questions?.filter(
        q => q.type === ActivityControlBarStepType.QuestionSubStep
    ).length;

    const renderQuestion = (question: Question, index: number) => {
        switch (question.type) {
            case ActivityControlBarStepType.QuestionSubStep:
                return (
                    <ProgressBar.Step
                        key={index}
                        isInProgress={question.isInProgress}
                        isCompleted={question.isCompleted}
                    />
                );

            case ActivityControlBarStepType.RelatedQuestionSubStep:
            case ActivityControlBarStepType.None:
                return null;

            default:
                throw new Error(
                    `Question Type (${question.type}) not supported`
                );
        }
    };

    const classes = classNames("control-bar-tracked-questions", className);

    return (
        <div className={classes}>
            <div className="control-bar-tracked-questions__progress">
                {isWorkleapBrandEnabled
                    ? `${currentQuestion}/${totalQuestions}`
                    : `${currentQuestion} / ${totalQuestions}`}
            </div>
            <ProgressBar>{questions?.map(renderQuestion)}</ProgressBar>
        </div>
    );
};

export default ControlBarTrackedQuestions;
