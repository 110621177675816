import type { BuiltOnboardingSurveyActivity, OnboardingSurveyStep } from "@/types/activities/onboarding-survey";

import OnboardingSurveyStepType from "@core/enums/OnboardingSurveyStepType";

function insertAnswerFieldFor(step: OnboardingSurveyStep) {
    const { stepType } = step;

    switch (stepType) {
        case OnboardingSurveyStepType.Intro:
            return {};
        case OnboardingSurveyStepType.AskQuestion:
            return { answer: step.answer ?? null, score: step.score ?? null, isAnswered: step.isAnswered ?? false };

        case OnboardingSurveyStepType.AskFollowUpQuestion:
            return { feedback: null, isAnonymous: true, isAnswered: false };

        default:
            throw new Error(`Step Type (${stepType}) not supported`);
    }
}

const buildOnboardingSurveyActivityOrchestrator = (activity: BuiltOnboardingSurveyActivity, isFirstActivity: boolean, isLastActivity: boolean) => {
    if (!activity.steps || activity.steps.length === 0) {
        throw new Error("Cannot orchestrate a onboarding survey with no steps");
    }

    return ({
        ...activity,
        isFirstActivity,
        isLastActivity,
        currentStepIndex: 0,
        steps: activity.steps.map((s, i) => ({
            ...s,
            isFirstStep: i === 0,
            isLastStep: i === activity.steps.length - 1,
            ...(insertAnswerFieldFor(s))
        }))
    });
};

export default buildOnboardingSurveyActivityOrchestrator;