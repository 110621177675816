import React, { useEffect } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useTipsContext } from "@contexts/tips/TipsContext";

import DismissableTip from "@components/dismissable-tip/DismissableTip";

import TipType from "@core/enums/TipType";
import { trackSurveyTipShown } from "@core/tracking/track";

import "./tip.scss";

interface Props {
    className?: string;
    type: TipType;
    isHidden: boolean;
}

const Tip = ({ className, type, isHidden }: Props) => {
    const { t } = useTranslation("activities");
    const { tips, dismiss } = useTipsContext();

    const canDisplayTip = tips.some(tip => tip === type);

    useEffect(() => {
        if (canDisplayTip && type === TipType.Anonymity) {
            trackSurveyTipShown(TipType.Anonymity);
        }
    }, [type, canDisplayTip]); // eslint-disable-line react-hooks/exhaustive-deps

    const classes = classNames(
        "tip",
        className, {
            "tip--hidden": isHidden || type === TipType.None || !canDisplayTip
        }
    );

    return (
        <div className={classes}>
            {(() => {
                switch (type) {
                    case TipType.None:
                        return null;
                    case TipType.Anonymity:
                        return (
                            <DismissableTip
                                title={t("anonymityTip.anonymity-tip-title")}
                                subtitle={t("anonymityTip.anonymity-tip-subtitle")}
                                type={DismissableTip.Type.Info}
                                onClick={() => window.open(t("anonymityTip.anonymity-tip-redirect"), "_blank")}
                                onDismiss={() => dismiss(type)}
                            />
                        );
                    default:
                        throw new Error(`Tip Type (${type}) not supported`);
                }
            })()}
        </div>
    );
};

export default Tip;