import React, { Fragment, Suspense } from "react";

import IglooProvider, { type AvailableLocales } from "@igloo-ui/provider";
import i18n from "i18next";
import { BrowserRouter as Router } from "react-router-dom";

import { useLogRocket } from "@hooks/useLogRocket";
import useShortcuts from "@hooks/useShortcuts";

import ActivitiesContextProvider from "@contexts/activities/ActivitiesContextProvider";
import ActivitiesServerSyncContextProvider from "@contexts/activities/ActivitiesServerSyncContextProvider";
import ApplicationInsightsContextProvider from "@contexts/applicationInsights/ApplicationInsightsContextProvider";
import AuthenticationContextProvider from "@contexts/authentication/AuthenticationContextProvider";
import FeaturesContextProvider from "@contexts/features/FeaturesContextProvider";
import ResponsiveContextProvider from "@contexts/responsive/ResponsiveContextProvider";
import SignupContextProvider from "@contexts/signup/SignupContextProvider";
import TipsContextProvider from "@contexts/tips/TipsContextProvider";
import UserContextProvider from "@contexts/user/UserContextProvider";

import ApplicationStartedTracking from "@core/tracking/ApplicationStartedTracking";
import PageViewTracking from "@core/tracking/PageViewTracking";

import GeneralErrorBoundary from "@errorBoundaries/GeneralErrorBoundary";
import LoadingPage from "@pages/loading/LoadingPage";
import ActivitiesRouting from "@routing/ActivitiesRouting";

import LoadingContextProvider from "./contexts/loading/LoadingContextProvider";
import IntercomBootstrapper from "./core/intercom/IntercomBootstrapper";

const Activities = () => {
    // Add data-set="workleap" to index.html body
    // until we're sure nothing depends on this anymore
    document.querySelector("html")?.setAttribute("data-brand", "workleap");

    return (
        <ActivitiesServerSyncContextProvider>
            <ActivitiesContextProvider>
                <TipsContextProvider>
                    <ActivitiesRouting />
                </TipsContextProvider>
            </ActivitiesContextProvider>
        </ActivitiesServerSyncContextProvider>
    );
};

const App = () => {
    useLogRocket();

    return (
        <Fragment>
            <PageViewTracking />
            <ApplicationStartedTracking />
            <IntercomBootstrapper />
            <Suspense fallback={<LoadingPage />}>
                <Activities />
            </Suspense>
        </Fragment>
    );
};

const AppWrapper = () => {
    const { language } = i18n;
    useShortcuts();

    return (
        <GeneralErrorBoundary>
            <LoadingContextProvider>
                <ApplicationInsightsContextProvider>
                    <IglooProvider locale={language as AvailableLocales ?? "en-US"}>
                        <Router>
                            <ResponsiveContextProvider>
                                <AuthenticationContextProvider>
                                    <UserContextProvider>
                                        <FeaturesContextProvider>
                                            <SignupContextProvider>
                                                <App />
                                            </SignupContextProvider>
                                        </FeaturesContextProvider>
                                    </UserContextProvider>
                                </AuthenticationContextProvider>
                            </ResponsiveContextProvider>
                        </Router>
                    </IglooProvider>
                </ApplicationInsightsContextProvider>
            </LoadingContextProvider>
        </GeneralErrorBoundary>
    );
};

export default AppWrapper;
